import './App.css';
import React from 'react'
import { loadAppConfigs } from './configurations/appConfig';
import { useState, useEffect, useCallback } from 'react';
import { GlobalLoading, showLoading } from 'react-global-loading';
import WebtraceDashboard from './components/WebtraceDashboard'
import WebTraceDemoDashboard from './components/WebTraceDemoDashboard'
import Administration from './components/administration/Administration'
import Home from './components/Home';
import PageSelector from './components/PageSelector';
import Logout from './components/Logout';
import ReactLoading from 'react-loading'
import { AppContext } from "./AppContext";
import { loadLanguage } from './language/lang';

import { BrowserView, MobileView, isMobile, isIOS, isAndroid } from 'react-device-detect';

import { BrowserRouter, Routes, Route } from 'react-router-dom'
import { FullScreen, useFullScreenHandle } from "react-full-screen";
import { hello } from './calls/fetchLogin';
import { fetchAssetTypes } from './calls/fetchAssetTypes';
import fetchGoogleApiKey from './calls/fetchGoogleApiKey';
import { isDevelopment } from './utilities/devHelper';
import assetProvider from './tools/assetTypeProvider';
import AboutBoxComponent from './components/dialogs/AboutBoxComponent';

//import * as rdd from 'react-device-detect';


//rdd.isMobile = true;
//rdd.isBrowser = appConfig.isBrowserTest;

window.onerror = (...args) => {
    if (args[0] === 'ResizeObserver loop limit exceeded') {
        return true;
    } else {
        return false;
    }
};

function App() {
    const [started, setStarted] = useState(false);
    const [styleReady, setStyleReady] = useState(false);
    const [configLoaded, setConfigLoaded] = useState(false);

    const fullScreenHandle = useFullScreenHandle();
    const [isFullScreen, setIsFullScreen] = useState(false);
    const [busy, setBusy] = useState(true);
    const [map, setMap] = useState(null);
    const [online, setOnline] = useState(true);
    const [portrait, setPortrait] = useState(false);
    const [accountBarData, setAccountBarData] = useState({ expanded: true });
    const [gridBarData, setGridBarData] = useState({ expanded: false });
    const [makerSelectedId, setMakerSelectedId] = useState(-1);
    const [gridSelectedRowId, setGridSelectedRowId] = useState(-1);
    const [gridScrollIndex, setGridScrollIndex] = useState(-1);
    const [selectionWarning, setSelectionWarning] = useState(false);
    const [contactsPanel, setContactsPanel] = useState(false);
    const [loginOpen, setLoginOpen] = useState(false);
    const [loggedIn, setLoggedIn] = useState(false);
    const [authorisationToken, setAuthorisationToken] = useState("");
    const [settingActive, setSettingActive] = useState(false);
    const [accountData, setAccountData] = useState({});
    const [googleApiKey, setGoogleApiKey] = useState(null);
    const [settings, setSettings] = useState(null);
    const [assetTypes, setAssetTypes] = useState([]);

    const [activeUser, setActiveUser] = useState(null);
    const [activeRoles, setActiveRoles] = useState(null);
    const [showAbout, setShowAbout] = useState(false);
    const [aboutContactInfo, setAboutContactInfo] = React.useState(null);
    //const [appThemeColor, setAppThemeColor] = React.useState("#761469");
    //const [appThemeColor, setAppThemeColor] = React.useState(" #FF5722");
    //const [appThemeColor, setAppThemeColor] = React.useState(" #4A148C"); //DeepPurple
    //const [appThemeColor, setAppThemeColor] = React.useState(" #006064"); //cyan_shade_900**
    //const [appThemeColor, setAppThemeColor] = React.useState(" #880E4F"); //pink_shade_900
    //const [appThemeColor, setAppThemeColor] = React.useState(" #B71C1C"); //red_shade_900
    //const [appThemeColor, setAppThemeColor] = React.useState(" #1B5E20"); //green_shade_900
    //const [appThemeColor, setAppThemeColor] = React.useState(" #004D40"); //teal_shade_900  **
    //const [appThemeColor, setAppThemeColor] = React.useState(" #01579B"); //light_blue_shade_900  **
    //const [appThemeColor, setAppThemeColor] = React.useState(" #738A28"); //android  **
    const [appThemeColor, setAppThemeColor] = React.useState("#6f7a7e"); //ios  **
    
    const onResize = () => {
        const innerWidth = window.innerWidth;
        const innerHeight = window.innerHeight;
        if (innerHeight > innerWidth) {
            setPortrait(true);
        }
        else {
            setPortrait(false);
        }
    };

    const onOnlineStatus = () => {
        setOnline(navigator.onLine);
    };

    const retrieveGoogleApiKeys = () => {
        fetchGoogleApiKey((apiResult) => {
            if (apiResult.error) {
                return;
            }

            setGoogleApiKey(apiResult.data);
        });
    }

    const loadConfiguations = () => {
        loadAppConfigs((data) => {
            setSettings(data);
            //const obj = json(data);
            setConfigLoaded(true);
            retrieveAssetTypes();
            if (!isDevelopment()) {
                hello((e) => {
                    if (e.error) {
                        return;
                    }

                    console.info("Hello");
                });
            }
        });
    }

    const retrieveAssetTypes = () => {
        fetchAssetTypes((typesResult) => {
            if (typesResult.error) {
                return;
            }

            assetProvider.setAssetTypeNameReference(typesResult.data);
            setAssetTypes(typesResult.data);
            retrieveGoogleApiKeys();
        });
    }

    const ImportStyles = () => {
        //csetStyleReady(true);
        if (!isMobile) {
            import("./styles/widgetStyle.css").then(() => {
                setStyleReady(true);
            });
        }
        else {
            import("./styles/mobile/widgetStyle.css").then(() => {
                setStyleReady(true);
            });
        }
    };

    const ConfigureTheme = () => {
        if (isMobile && isAndroid) {
            setAppThemeColor("#4A148C")
        }

        else if (isMobile && isIOS) {
            setAppThemeColor("#01579B")
        }
        else {
            setAppThemeColor("#004D40")
        }
    };

    const startupTimer = () => setTimeout(() => {
        //if (true) {

        //}
        setStarted(true);
    }, 500);

    const onFullScreenChanged = useCallback((state, handle) => {
        setIsFullScreen(state);
    }, [fullScreenHandle]);

    useEffect(() => {
        showLoading(true);
        onResize();
        onOnlineStatus();
        loadLanguage("french");
        ImportStyles();
        loadConfiguations();
        ConfigureTheme();
        startupTimer();
        window.addEventListener('resize', onResize);
        window.addEventListener("online", onOnlineStatus);
        window.addEventListener("offline", onOnlineStatus);
        return () => {
            window.removeEventListener('resize', onResize);
            window.removeEventListener("online", onOnlineStatus);
            window.removeEventListener("offline", onOnlineStatus);
        }
    }, []);


    const ready = started && styleReady && configLoaded && assetTypes.length > 0 && googleApiKey;
    return (
        <FullScreen handle={fullScreenHandle} onChange={onFullScreenChanged} >
            <BrowserRouter>
                <AppContext.Provider value={
                    {
                        busy: busy, setBusy: setBusy,
                        map: map, setMap: setMap,
                        online: online, setOnline: setOnline,
                        portrait: portrait, setPortrait: setPortrait,
                        accountBarData: accountBarData, setAccountBarData: setAccountBarData,
                        gridBarData: gridBarData, setGridBarData: setGridBarData,
                        fullScreenHandle: fullScreenHandle,
                        selectionWarning, setSelectionWarning,
                        makerSelectedId, setMakerSelectedId,
                        gridSelectedRowId, setGridSelectedRowId,
                        gridScrollIndex, setGridScrollIndex,
                        contactsPanel, setContactsPanel,
                        loginOpen, setLoginOpen,
                        loggedIn, setLoggedIn,
                        authorisationToken, setAuthorisationToken,
                        settingActive, setSettingActive,
                        accountData, setAccountData,
                        googleApiKey, setGoogleApiKey,
                         settings, setSettings,
                        assetTypes, setAssetTypes,
                        activeUser, setActiveUser,
                        activeRoles, setActiveRoles,
                        showAbout, setShowAbout,
                        aboutContactInfo, setAboutContactInfo,
                        isFullScreen, setIsFullScreen,
                        appThemeColor, setAppThemeColor
                    }}>
                    <BrowserView>
                        <div className="App" onContextMenu={(e) => {
                            e.preventDefault(); // prevent the default behaviour when right clicked
                            console.log("Right Click");
                        }}>
                            <ApplicationOverlay/>
                            {ready ? <Routes>
                                <Route path="/" element={<Home ready={ready} />} />
                                <Route path="/dashboard" element={<WebtraceDashboard ready={ready} />} />
                                <Route path="/selector" element={<PageSelector ready={ready} />} />
                                <Route path="/demo" element={<WebTraceDemoDashboard ready={ready} />} />
                                <Route path="/administration" element={<Administration ready={ready} />} />
                                <Route path="/logout" element={<Logout ready={ready} />} />
                                <Route path="*" element={<Home ready={ready} />} />
                            </Routes> : null}
                            <GlobalLoading loadingSize={35} loadingType='spokes'
                                loadingBackgroundColor='rgba(255, 255, 255, 0.5)'
                                loadingColor="#eee"
                                backgroundColor='rgba(0, 0, 0, 0.5)'>
                                {/*<ReactLoading type="spin"/>*/}
                            </GlobalLoading>
                        </div>
                    </BrowserView>
                    <MobileView>
                        <div className="App" onContextMenu={(e) => {
                            e.preventDefault(); // prevent the default behaviour when right clicked
                            console.log("Right Click");
                        }}>
                            <ApplicationOverlay />
                            {ready ? <Routes>
                                <Route path="/" element={<Home ready={ready} />} />
                                <Route path="/dashboard" element={<WebtraceDashboard ready={ready} />} />
                                <Route path="/selector" element={<PageSelector ready={ready} />} />
                                <Route path="/demo" element={<WebTraceDemoDashboard ready={ready} />} />
                                <Route path="/administration" element={<Administration ready={ready} />} />
                                <Route path="/logout" element={<Logout ready={ready} />} />                          
                                <Route path="*" element={<Home ready={ready} />} />
                            </Routes> : null}
                            <GlobalLoading loadingSize={25} loadingType='spokes'
                                loadingBackgroundColor='rgba(255, 255, 255, 0.5)'
                                loadingColor="#eee"
                                backgroundColor='rgba(0, 0, 0, 0.5)'>
                                {/*<ReactLoading type="spin"/>*/}
                            </GlobalLoading>
                        </div>
                    </MobileView>
                </AppContext.Provider>
            </BrowserRouter>
        </FullScreen>);
};

function ApplicationOverlay() {
    const { showAbout, setShowAbout } = React.useContext(AppContext);
    return (
        <>
            {
                showAbout ?
                    <AboutBoxComponent
                        open={showAbout}
                        onClickOutside={() => { setShowAbout(false) }}
                        onClose={() => { setShowAbout(false) }} /> :
                    null
            }
        </>);
}

export default App;
