import React, { useState, useEffect } from "react";
import { forwardRef, useImperativeHandle } from 'react';
import { hexToRgbaWithAlpha } from "../../utilities/convertColors";

function Notificator(props, ref) {
    const [notifications, setNotifications] = useState([]);

    const pushNotification = ({ notification, color, lifetime = 2 } = {}) => {

        setNotifications(prev => {
            const list = [...prev];
            list.push({ notification, color, lifetime });
            return list;
        });
    }

    const decrementLifetime = () => {
        setNotifications(prev => {
            if (prev.length === 0) 
                return prev;
            
            const list = [...prev];

            let allTimeout = true;
            for (var notification of list) {
                notification.lifetime--;
                if (notification.lifetime > 0) {
                    allTimeout = false;
                }
            }

            if (allTimeout) {
                list.length = 0;
            }

            return list;
        });
    }

    useImperativeHandle(ref, () => ({
        pushNotification: pushNotification,
    }));

    useEffect(() => {
        const timer = setInterval(() => {
            decrementLifetime();
        }, 1000);
        return () => {
            clearInterval(timer);
        };
    }, []);

    useEffect(() => {

    }, [notifications]);



    if (notifications.length === 0) {
        return null;
    }

    return  (
        <div style={{ display: "flex", backgroundColor: hexToRgbaWithAlpha("black",0.1), flexDirection: "column", padding:"5px", height:"100%"}}>
            {notifications.map(item => (
                item.lifetime > 0 ? <span key={item.key}>{item.notification}</span> : null
            ))}
        </div>
    );
}

export default forwardRef(Notificator);