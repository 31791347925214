import React from 'react'
import { isMobile } from 'react-device-detect';
import fetchDemoAccount from '../../calls/fetchDemoAccount';
import updateDemoAsset from '../../calls/updateDemoAsset';
import { fetchAssetTypes } from '../../calls/fetchAssetTypes';
import { AppContext } from "../../AppContext";
import { IconContext } from "react-icons";
import Listbox from "react-widgets/Listbox";
import { MdDriveFileRenameOutline } from "react-icons/md";
import DropdownList from "react-widgets/DropdownList";
import { showLoading } from 'react-global-loading';


function SettingsDialog(props) {
    const { assetTypes, setAssetTypes } = React.useContext(AppContext);
    const [closed, setClosed] = React.useState(false);
    const [accountData, setAccountData] = React.useState({});
    const [assetsData, setAssetsData] = React.useState([]);
    const [editData, setEditData] = React.useState(null);
    const mobile = isMobile;
    const [loaded, setLoaded] = React.useState(false);

    const messageFontSize = mobile ? "10px" : "12px";

    React.useEffect(() => {
        if (props.open) {
            updateAccount(true);
        }

    }, [props.open]);
    const updateAccount = (load) => {
        if (load) setLoaded(false);
        showLoading(true);
        fetchDemoAccount((result) => {
            if (result.error) {
                return;
            }

            if (props.onAccountUpdate) {
                props.onAccountUpdate(result.data)
            }
            setAccountData(result.data);
            setAssetsData(result.data?.assets);
            fetchAssetTypes((typesResult) => {
                if (load) setLoaded(true);
                showLoading(false);
                if (typesResult.error) {
                    return;
                }

                setAssetTypes(typesResult.data)
            });
        });
    }

    const renderEdit = (listItem) => {
        const selectedType = editData.type;
        const value = selectedType ?
            assetTypes.find(x => x.typeIndex == selectedType) :
            assetTypes.find(x => x.name == "default");

        return (
                <div style={{ display: "flex", flexDirection: "column" }}>
                <h4>{listItem?.name}</h4>
                <div style={{ margin: "0px 0px 0px 0px", fontSize: messageFontSize, height: "100%" }}>
                    <div
                        style={{ display: "flex", flexDirection:"column" }}
                        
                        onKeyDown={e => e.stopPropagation()}
                        onClick={e => e.stopPropagation()}
                        onFocus={e => e.stopPropagation()}
                        onMouseOver={e => e.stopPropagation()}>

                        <div style={{ display: "flex", margin:"10px 0px" }}>
                            <strong style={{ width: "60px", textAlign:"left" }}>
                            Name
                            </strong>
                            <input
                                style={{ width: "100%" }}
                                type="text"
                                value={editData?.name}
                                onChange={(e) => {
                                    const edit = { ...editData };
                                    edit.name = e.target.value;
                                    setEditData(edit);
                                }} />
                        </div>
                        <div style={{ display: "flex", margin: "10px 0px" }}>
                            <strong style={{ width: "60px", textAlign: "left" }}>
                                Type
                            </strong>
                            <DropdownList
                                style={{ width: "50" }}
                                readOnly={false}
                                //dropUp
                                defaultValue={assetTypes[0]}
                                data={assetTypes}
                                value={value}
                                filter={false}
                                onChange={(e) => {
                                    const edit = { ...editData };
                                    edit.type = e;
                                    setEditData(edit);
                                }}
                                autoFocus={false}
                                renderListItem={({ item }) => {
                                    return (
                                        <span>
                                            {/*<img style={{ height: "15px", width: "15px", margin: "0px 5px" }} src={getAssetTypeIconFromIndex(item?.typeIndex)} alt="Logo" />*/}
                                        </span>
                                    )
                                }}
                                renderValue={({ item }) => (
                                    <span>
                                        {/*<img style={{ height: "15px", width: "15px", margin: "0px 5px" }} src={getAssetTypeIconFromIndex(item?.typeIndex)} alt="Logo" />*/}
                                    </span>
                                )}
                            />
                        </div>

                        <button onClick={() => {
                            //setLoaded(true);
                            showLoading(true);
                            const name = editData.name;
                            const assetId = listItem.item.id;
                            var typeName = editData?.type?.name;

                            const updateData = { name: name, assetId: assetId, typeName: typeName };
                            updateDemoAsset(updateData, (result) => {
                                showLoading(false);
                                if (result.error) {
                                    return;
                                }

                                setEditData(null);
                            });

                        }} style={{ minWidth: "10px" }}>
                            Ok
                        </button>
                        <button onClick={() => {
                            setEditData(null);
                        }} style={{ minWidth: "10px" }}>
                            Cancel
                        </button>
                    </div>
                </div>
            </div>
);
    }

    const renderAccount = () => {
        const accounts = accountData?.accounts;
        const account = (accounts && accounts?.length > 0) ? accounts[0] : undefined;
        const titleFontSize = mobile ? 10 : undefined;
        const awesomeSize = mobile ? 12 : 18;
        return (
            <div style={{ display: "flex", flexDirection: "column" }}>
                <h4>{account?.name}</h4>
                <div style={{ margin: "0px 0px 0px 0px", fontSize: messageFontSize, height: "100%" }}>
                    <Listbox
                        style={{ margin: "0px 0px 0px 0px", height: "100%" }}
                        dataKey="id"
                        //textField="name"
                        data={assetsData}
                        onChange={() => {
                        }}
                        renderItem={
                            ({ item }) => {
                                const assetId = item.id;
                                return (
                                    <span style={{ display: "flex", margin: "0px 0px 0px 0px", padding: "0px 0px 0px 0px" }}>
                                        <input
                                            checked={item?.active}
                                            type="checkbox"
                                            onChange={(e) => {
                                                const state = e.target.checked;
                                                const updateData = { assetId: assetId, active: state };
                                                showLoading(true);
                                                updateDemoAsset(updateData, (result) => {
                                                    showLoading(false);
                                                    if (result.error) {
                                                        return;
                                                    }

                                                    updateAccount(false);
                                                });

                                                //const a = [...assetsData];
                                                //const entry = a.find(x => x.id == item.id);
                                                //if (entry) { 
                                                //    entry.active = !entry.active;
                                                //    setAssetsData(a)
                                                //}
                                            }}
                                            id="checkbox" />
                                        {/*<img style={{ height: "15px", width: "15px", margin: "0px 5px" }} src={getAssetTypeIconFromIndex(item.assetType)} alt="Logo" />*/}
                                        <strong style={{ width: "100%" }}>{item.name}</strong>
                                        <button disabled={!(item?.active)} style={{ backgroundColor: "transparent", height: "100%" }}
                                            onClick={() => {
                                                const edit = {
                                                    item: item,
                                                    name: item.name,
                                                    type :item.assetType
                                                };

                                                setEditData(edit);
                                            }}>
                                            <div style={{ alignItems: "center", display: "flex", justifyContent: "center" }}>
                                                <IconContext.Provider value={{ size: awesomeSize, title: "Connexion" }}>
                                                    <MdDriveFileRenameOutline />
                                                </IconContext.Provider>
                                            </div>
                                        </button>
                                    </span>
                                );
                            }
                        }
                    />
                </div>
                <hr />
                <div style={{ textAlign: "left", display: "flex", flexDirection: "row", margin: "10px 0px 0px 0px" }}>
                    <button style={{
                        width: "100px", backgroundColor: "whitesmoke", color: "black", borderRadius: "2px", height: "100%",
                        borderWidth: "2px", margin: "5px 0px 1px 0px", borderColor: 'lightgray', cursor: "pointer"
                    }} onClick={() => {
                        if (props?.onConfirm) {
                            props.onConfirm();
                        }
                    }}>confirmer</button>
                    <span style={{ flexGrow: 1, display: "flex" }}></span>
                    <button style={{
                        width: "80px", backgroundColor: "whitesmoke", color: "black", height: "100%", borderRadius: "2px",
                        borderWidth: "2px", margin: "5px 0px 1px 0px", borderColor: 'lightgray', cursor: "pointer"
                    }} onClick={() => {
                        if (!props?.onCancel) {
                            setClosed(true)
                            return;
                        }
                        props.onCancel();
                    }}>Annuler</button>
                </div>
            </div>
        );
    }


    const switcher = () => {
        if (editData) {
            return renderEdit(editData)
        }
        else
            return renderAccount()
    }

    if (!loaded) {
        return null;
    }
    // const account2 = (accounts && accounts?.length > 0) ? accounts[0] : undefined;
    return (<div style={{ position: "absolute", left: "50%", top: "50%" }}>
        <dialog open={props.open && !closed} style={{ padding: '3px', color: "black", width:300, position: "relative", borderWidth: "0.3px", zIndex: 25, transform: `translate(-50%, -50%)` }}>
            {switcher()}
        </dialog>
    </div>);
}

export default SettingsDialog;