import React from 'react'
import { checkIfEmptyOrWhiteSpace } from "../../utilities/stringHelper";
import { createAsset, updateAsset } from "../../calls/administration/createAsset";
import DropdownList from "react-widgets/DropdownList";
import { AdministrationContext } from "./AdministrationContext"
import { showLoading } from 'react-global-loading';
import { fetchAccounts } from "../../calls/administration/fetchAccounts";
import { fetchUnits } from "../../calls/administration/fetchUnits";
import { useFilePicker } from 'use-file-picker';
import { FileAmountLimitValidator, FileSizeValidator, ImageDimensionsValidator } from 'use-file-picker/validators';
import { fetchAssetTypes, fetchAssetTypeColors } from '../../calls/fetchAssetTypes';


function AssetCreation(props) {

    const { editAsset, setEditAsset } = React.useContext(AdministrationContext);
    const [assetName, setAssetName] = React.useState("");
    const [error, setError] = React.useState("");

    const [accounts, setAccounts] = React.useState([]);
    const [accountSelection, setAccountSelection] = React.useState(null);
    const [accountSearchTerm, setAccountSearchTerm] = React.useState('');

    const [units, setUnits] = React.useState([]);
    const [unitSelection, setUnitSelection] = React.useState(null);
    const [unitSearchTerm, setUnitSearchTerm] = React.useState('');

    const [assetTypes, setAssetTypes] = React.useState([]);
    const [assetTypeSelection, setAssetTypeSelection] = React.useState(null);

    const [assetTypeColors, setAssetTypeColors] = React.useState([]);
    const [assetTypeColorSelection, setAssetTypeColorSelection] = React.useState(null);
    const [pickerOpen, setPickerOpen] = React.useState(false);
    const [pickerData, setPickerData] = React.useState(null);
    const [ready, setReady] = React.useState(false);

    const updateAccounts = (onResult, filter) => {
        fetchAccounts((e) => {
            if (e.error) {
                if (onResult) onResult(false);
                return;
            }

            setAccounts(e.data.accounts);
            if (onResult) onResult(true, e.data.accounts);
        }, filter);
    }

    const updateUnits = (onResult, filter) => {
        fetchUnits((e) => {
            if (e.error) {
                if (onResult) onResult(false);
                return;
            }

            setUnits(e.data.units);
            if (onResult) onResult(true, e.data.units);
        }, filter);
    }

    const retrieveAssetTypes = (onResult) => {
        fetchAssetTypes((e) => {
            if (e.error) {
                if (onResult) onResult(false);
                return;
            }

            setAssetTypes(e.data);
            if (onResult) onResult(true, e.data)
        });
    }

    const retrieveAssetTypeColors = (onResult) => {
        fetchAssetTypeColors((e) => {
            if (e.error) {
                if (onResult) onResult(false);
                return;
            }

            setAssetTypeColors(e.data);
            if (onResult) onResult(true, e.data)
        });
    }

    const init = () => {
        showLoading(true)
        retrieveAssetTypeColors((_, colors) => {
            retrieveAssetTypes((_, assetTypes) => {
                updateAccounts((_, accounts) => {
                    updateUnits((_, units) => {
                        showLoading(false)
                        setReady(true)
                        if (editAsset) {
                            setAssetName(editAsset.name)
                            if (accounts) {
                                const account = accounts.find(x => x.id === editAsset.assignedAccountId);
                                setAccountSelection(account);
                            }

                            if (assetTypes) {
                                const assetType = assetTypes.find(x => x.typeIndex === editAsset.assetType);
                                setAssetTypeSelection(assetType);
                            }

                            if (colors) {
                                const color = colors.find(x => x.color === editAsset.assetTypeIconColor);
                                setAssetTypeColorSelection(color);
                            }

                            if (units) {
                                const unit = units.find(x => x.id === editAsset.assignedUnitId);
                                setUnitSelection(unit);
                            }

                            if (editAsset.assetType === 255) {
                                setPickerData(editAsset.assetTypeCustomIcon)             
                            }
                        }
                    })
                })
            })
        });
    }

    React.useEffect(() => {
        init();
        return () => {
            setError("")
            setAssetName("")
            setAssetTypeSelection(null)
            setAssetTypeColorSelection(null)
            setUnitSelection(null)
            setEditAsset(null)
        }
    }, []);

    //if (!ready) return <></>;

    return <dialog open={props.open && ready} style={{
        top: "50%",
        bottom: "50%",
        marginRight: "auto",
        marginLeft: "auto",
        padding: "8px",
        borderWidth: "1px",
        borderRadius: "5px",
        minWidth: "450px"
    }}>
        <div>
            <div style={{ textAlign: "right" }}>
                {editAsset ? `Asset edition (${editAsset.id})` : "Asset creation"}
            </div>
            <hr />
            <div style={{ display: "flex", margin: "5px 0px", textAlign: "left" }}>
                <label style={{ display: "block", minWidth: "120px" }} htmlFor="asset_creation_name">Name</label>
                <input style={{ display: "block", width: "100%" }} type="text" id="asset_creation_name" placeholder="Asset name" value={assetName}
                    onChange={(e) => {
                        setAssetName(e.target.value)
                    }} />
            </div>
            <div style={{ display: "flex", margin: "5px 0px", width: "100%" }}>
                <div style={{ display: "flex", margin: "5px 0px", flex: 1 }}>
                    <label style={{ display: "block", minWidth: "120px", textAlign: "left" }} htmlFor="asset_creation_type">Type</label>
                    <DropdownList
                        id="asset_creation_type"
                        style={{ display: "block", width: "100%" }}
                        data={assetTypes}
                        dataKey='typeIndex'
                        textField='name'
                        value={assetTypeSelection}
                        onChange={(value) => {
                            setPickerData(null);
                            setAssetTypeSelection(value)
                        }}
                        renderValue={({ item }) => (
                            <div style={{ display: "flex", minWidth: "60px" }}>
                                <strong style={{ flex: 1 }}>{item?.name}</strong>
                                {/*<div style={{ backgroundColor: item?.color ? item?.color : undefined, width: "20px", height: "20px" }}>*/}
                                {/*</div>*/}
                            </div>
                        )}
                    />
                </div>

                {assetTypeSelection?.typeIndex === 255 ?
                    <div style={{ display: "flex", alignItems: "center", justifyContent:"center", flexDirection:"row" }}>
                        {pickerData && <img alt="logo" src={pickerData} style={{ width: "20px", height: "20px", margin: "0px 0px 0px 5px" }}>
                        </img>}
                        <button style={{ margin: "0px 0px 0px 5px" }} onClick={() => {
                            if (pickerOpen) {
                                setPickerOpen(false)
                                return;
                            }

                            setPickerOpen(true)
                        }}>
                            pick file
                        </button>
                    </div>
                    :
                    <div style={{ display: "flex", margin: "5px 0px 5px 10px" }}>
                        <DropdownList
                            id="asset_creation_type_color"
                            style={{ display: "block", width: "100%" }}
                            data={assetTypeColors}
                            dataKey='typeIndex'
                            textField='name'
                            value={assetTypeColorSelection}
                            onChange={(value) => {
                                setAssetTypeColorSelection(value)
                            }}
                            renderValue={({ item }) => (
                                <div style={{ backgroundColor: item?.color ? item?.color : undefined, width: "20px", height: "20px", display: "flex" }}>
                                </div>
                            )}
                            renderListItem={({ item }) => (
                                <div style={{ display: "flex", minWidth: "60px" }}>
                                    <strong style={{ flex: 1 }}>{item.name}</strong>
                                    <div style={{ backgroundColor: item?.color ? item?.color : undefined, width: "20px", height: "20px" }}>
                                    </div>
                                </div>
                            )}
                        />
                    </div>}
            </div>
            <div style={{ display: "flex", margin: "5px 0px" }}>
                <label style={{ display: "block", minWidth: "120px", textAlign: "left" }} htmlFor="asset_creation_account">Account</label>
                <DropdownList
                    readOnly={editAsset}
                    id="asset_creation_account"
                    style={{ display: "block", width: "100%" }}
                    data={accounts}
                    dataKey='id'
                    textField='name'
                    value={accountSelection}
                    onChange={(value) => {
                        setAccountSelection(value)
                    }}
                    searchTerm={accountSearchTerm}
                    onSearch={(e) => {
                        setAccountSearchTerm(e);
                        const filter = {
                            count: 50,
                            searchTerm: e,
                            additionalIds: editAsset ? [editAsset.assignedAccountId] : null
                        };

                        updateAccounts(() => {
                        }, filter);
                    }}
                />
            </div>
            <div style={{ display: "flex", margin: "5px 0px" }}>
                <label style={{ display: "block", minWidth: "120px", textAlign: "left" }} htmlFor="asset_creation_unit">Unit</label>
                <DropdownList
                    readOnly={editAsset}
                    id="asset_creation_unit"
                    style={{ display: "block", width: "100%" }}
                    data={units}
                    dataKey='id'
                    textField='hwSerial'
                    value={unitSelection}
                    onChange={(value) => {
                        setUnitSelection(value)
                    }}
                    searchTerm={unitSearchTerm}
                    onSearch={(e) => {
                        setUnitSearchTerm(e);

                        const filter = {
                            count: 50,
                            searchTerm: e,
                            additionalIds: editAsset ? [editAsset.assignedUnitId] : null
                        };

                        updateUnits(() => {
                        }, filter);
                    }}
                />
            </div>
            {checkIfEmptyOrWhiteSpace(error) ? null : (<div style={{ display: "flex", color: "red", fontWeight: "bold", fontSize: "8", whiteSpace:"pre-line" }}>
                {error}
            </div>)}
            <div style={{ textAlign: "left", display: "flex", flexDirection: "row", margin: "10px 0px 0px 0px" }}>
                <button style={{
                    width: "100px", backgroundColor: "whitesmoke", color: "black", borderRadius: "2px", height: "100%",
                    borderWidth: "2px", margin: "5px 0px 1px 0px", borderColor: 'lightgray', cursor: "pointer"
                }} onClick={() => {
                    if (checkIfEmptyOrWhiteSpace(assetName)) {
                        setError("Username cannot be empty")
                        return;
                    }

                    const customIcon = assetTypeSelection?.typeIndex === 255 ? pickerData : null;
                    if (assetTypeSelection?.typeIndex === 255 && !customIcon) {
                        setError("Custom icon cannot be empty!")
                        return;
                    }

                    if (editAsset) {
                        const data = {
                            name: assetName,
                            assetType: assetTypeSelection.typeIndex,
                            assetId: editAsset.id,
                            assetTypeIconColor: assetTypeColorSelection?.color ?? null,
                            assetTypeCustomIcon: customIcon
                        };

                        showLoading(true)
                        updateAsset(data, (e) => {
                            showLoading(false)
                            if (e.error) {
                                setError(e.error)
                                return;
                            }

                            if (!props?.onAssetEdited) {
                                return;
                            }

                            props?.onAssetEdited(e.data)
                        });
                    }
                    else {
                        const data = {
                            name: assetName,
                            unitId: unitSelection.id,
                            accountId: accountSelection.id,
                            assetType: assetTypeSelection.typeIndex,
                            assetTypeIconColor: assetTypeColorSelection?.color ?? null,
                            assetTypeCustomIcon: customIcon
                        };

                        showLoading(true)
                        createAsset(data, (e) => {
                            showLoading(false)
                            if (e.error) {
                                setError(e.error)
                                return;
                            }

                            if (!props?.onAssetCreated) {
                                return;
                            }

                            props?.onAssetCreated(e.data)
                        });
                    }
                }}>confirmer</button>
                <span style={{ flexGrow: 1, display: "flex" }}></span>
                <button style={{
                    width: "100px", backgroundColor: "whitesmoke", color: "black", height: "100%", borderRadius: "2px",
                    borderWidth: "2px", margin: "5px 0px 1px 0px", borderColor: 'lightgray', cursor: "pointer"
                }} onClick={() => {
                    if (!props?.onCancel) {
                        return;
                    }
                    props.onCancel();
                }}>Annuler</button>
            </div>
            {
                pickerOpen &&
                <AssetPicker
                    onSubmit={(e) => {
                        setPickerData(e?.data?.content);
                    }}
                    onClose={(errors ) => {
                        if (errors) {
                            let str = "";
                            for (let error of errors) {
                                str += error.reason+"\r\n+"
                            }

                            setError(str);
                        }
                        setPickerOpen(false);
                    }} />
            }
        </div>
    </dialog>
}

function AssetPicker(props) {
    const { openFilePicker, filesContent, loading, errors } = useFilePicker({
        readAs: 'DataURL',
        accept: 'image/*',
        multiple: false,
        validators: [
            new FileAmountLimitValidator({ max: 1 }),
            new FileSizeValidator({ maxFileSize: 50 * 1024 /* 50 MB */ }),
            new ImageDimensionsValidator({
                maxHeight: 1000, // in pixels
                maxWidth: 1000,
                minHeight: 20,
                minWidth: 20,
            }),
        ],
        onFilesSelected: ({ plainFiles, filesContent, errors }) => {
            if (props.onClose) { props.onClose(errors); }
            console.log('onFilesSelected', plainFiles, filesContent, errors);
        },
        onFilesRejected: ({ errors }) => {
            if (props.onClose) {
                props.onClose(errors);
            }
        },
        onFilesSuccessfullySelected: ({  filesContent }) => {
            if (props.onClose) { props.onClose(); }
            if (filesContent.length > 0) {
                if (props.onSubmit) {
                    props.onSubmit({ data: filesContent[0] });
                }
            }
        },
        onClear: () => {
            // this callback is called when the selection is cleared
            console.log('onClear');
        },
    });

    React.useEffect(() => {
        openFilePicker();
    }, []);
    return null;
}

export default AssetCreation;