const period1min = 1000 * 60;
const period1hour = period1min * 60;
const period1day = period1hour * 24;
const period30day = period1day * 30;
const period1year = period1hour * 365;

const getStartDateTime = (datems) => {
    const date = new Date(datems);
    const startToday = new Date(date.getFullYear(), date.getMonth(), date.getDate(), 0, 0, 0, 0);
    return startToday.getTime();
}

const getEndDateTime = (datems) => {
    const endDay = new Date(getStartDateTime(datems) + period1day - 1);
    return endDay;
}

const getTodayStartDate = () => {
    const date = new Date(nowms());
    const startToday = new Date(date.getFullYear(), date.getMonth(), date.getDate(), 0, 0, 0, 0);
    return startToday;
}

const getTodayEndDate = () => {
    const endDay = new Date(getStartDateTime(nowms()) + period1day - 1);
    return endDay;
}

const getYesterdayStartDate = () => {
    const date = new Date(nowms() - period1day);
    const startToday = new Date(date.getFullYear(), date.getMonth(), date.getDate(), 0, 0, 0, 0);
    return startToday;
}

const getYesterdayEndDate = () => {
    const endDay = new Date(getStartDateTime(nowms())  - 1);
    return endDay;
}



const getPeriodElapsed = (start, end = new Date()) => {
    if (!end) {
        end = new Date();
    }

    var endUct = end.getTime() + end.getTimezoneOffset() * period1min;
    var datems = new Date(start);
    return endUct - datems;
}

const timeSpanFromHours = (hours) => {

    return period1hour * hours;
}

const timeSpanFromMinutes = (minutes) => {

    return period1min * minutes;
}

const timeSpanFromDays = (days) => {

    return period1day * days;
}

const now = (days) => {

    return new Date()
}

const nowms = (days) => {

    return (new Date()).getTime();
}

export {
    getStartDateTime,
    getEndDateTime,
    getPeriodElapsed,
    timeSpanFromMinutes,
    timeSpanFromHours,
    timeSpanFromDays,
    now,
    nowms,
    getTodayStartDate,
    getTodayEndDate,
    getYesterdayStartDate,
    getYesterdayEndDate
}