import React from 'react'
import {  useContext } from 'react';
import { AppContext } from "../../AppContext";
import { showLoading } from 'react-global-loading';
import { isMobile } from 'react-device-detect';
import { useNavigate } from "react-router-dom";
import AccountListView from "./AccountListView";
import AssetsListView from "./AssetsListView";
import ContactsListView from "./ContactsListView";
import UsersListView from "./UsersListView";
import UnitsListView from "./UnitsListView";
import ActivitiesListView from './ActivitiesListView';
import { EventsListView } from './EventsListView';
import SimcardOperator from './SimcardOperator';
import TwilioListView from './TwilioListView';
import AdminSettingPage from './AdminSettingPage';

import UserCreation from "./UserCreation";
import AccountCreation from "./AccountCreation";
import UnitCreation from "./UnitCreation";
import AssetCreation from "./AssetCreation";
import ContactCreation from "./ContactCreation";
import UnitConfigsTable from "./UnitConfigsTable";


import { isLogin } from '../../calls/fetchLogin';
import TopBarComponenent from '../TopBarComponenent';
import AdministrationResultDialog from './AdministrationResultDialog';
import { hexToRgbaWithAlpha } from '../../utilities/convertColors'
import { AdministrationContext } from "./AdministrationContext"

import TimeDisplay from "../TimeDisplay"



function Administration(props) {
    const navigate = useNavigate();
    const tabRef = React.useRef(null)
    const { setBusy } = useContext(AppContext);
    const { appThemeColor } = React.useContext(AppContext);
    const [tabIndex, setTabIndex] = React.useState(0);

    //const [allAccounts, setAllAccounts] = React.useState([]);
    //const [assets, setAssets] = React.useState([]);
    const [units, setUnits] = React.useState([]);
    const [contacts, setContacts] = React.useState([]);
    const [users, setUsers] = React.useState([]);
    const [activities, setActivities] = React.useState([]);
    const [assetTypes, setAssetTypes] = React.useState([]);
    const [events, setEvents] = React.useState([]);
    const [unitSmsList, setUnitSmsList] = React.useState([]);

    const { isFullScreen } = React.useContext(AppContext);
    //const [editUser, setEditUser] = React.useState(null);
    //const [editAccount, setEditAccount] = React.useState(null);
    const [editAsset, setEditAsset] = React.useState(null);
    const [editContact, setEditContact] = React.useState(null);
    const [configsUnit, setConfigsUnit] = React.useState(null);
    const [configEditData, setConfigEditData] = React.useState(null);

    const [userCreationOptions, setUserCreationOptions] = React.useState(false);
    const [accountCreationOptions, setAccountCreationOptions] = React.useState(false);
    const [unitCreationOpen, setUnitCreationOpen] = React.useState(false);
    const [assetCreationOpen, setAssetCreationOpen] = React.useState(false);
    const [contactCreationOpen, setContactCreationOpen] = React.useState(false);
    const [unitConfigsTableOpen, setUnitConfigsTableOpen] = React.useState(false);

    const [ready, setReady] = React.useState(false);

    const [resultMessage, setResultMessage] = React.useState("");

    const assetsListViewRef = React.useRef(null);
    const usersListViewRef = React.useRef(null);

    const usersTabIndex = 0;
    const accountsTabIndex = 1;
    const assetsTabIndex = 2;
    const unitsTabIndex = 3;
    const contactsTabIndex = 4;
    const actvitiesTabIndex = 5;
    const eventsTabIndex = 6;
    const simcarOparatorTabIndex = 7;
    const twilioTabIndex = 8;
    const settingTabIndex = 9;

    const mobile = isMobile;
    const barHeight = mobile ? "22px" : "30px";

    const checkIfReady = () => {
        setBusy(true)
        showLoading(true)
        isLogin((e) => {

            if (e.error) {
                navigate("/home", { replace: true });
                setBusy(false)
                showLoading(false)
                return;
            }

            //if (assetTypes.length > 0 && allAccounts.length > 0 && users.length > 0) {
            //    setReady(true);
            //    return;
            //}

            setReady(true);
        })
    }

    const buildActiveStyle = (active) => {
        return {
            minHeight: categoryButtonHeight,
            margin: "1px",
            borderRight: active ? "0px" : undefined,
            backgroundColor: active ? appThemeColor : undefined,
            color: active ? "white" : undefined,
            fontWeight: active ? "bold" : undefined
        };
    }

    React.useEffect(() => {
        checkIfReady();
    }, []);

    if (!ready) {
        return null;
    }
    const categoryButtonHeight = "60px";
    const categoryButtonMargin = "2px 0px";
    const userTabActive = tabIndex === usersTabIndex;
    return (
        <AdministrationContext.Provider value={{
            editAsset, setEditAsset,
            resultMessage, setResultMessage,
            editContact, setEditContact,
            configsUnit, setConfigsUnit,
            configEditData, setConfigEditData,
            events, setEvents,
            unitSmsList, setUnitSmsList,
            activities, setActivities,
        }}>
            <div style={{ display: "flex", height: "100%", width: "100%", flexDirection: "column" }}>
                <div style={{ width: "100%", height: barHeight, display: "flex", backgroundColor: appThemeColor }}>
                    <TopBarComponenent
                        showInfo={true}
                        showScreenControl={true}
                        userMenuControl={true}
                        showUserMenuDashboard={true} />
                </div>
                <div style={{ height: "100%", width: "100%", display: "flex", flex: 1, }}>
                    <div style={{ height: "100%", width: "100%", display: "flex", flexDirection: "row" }}>
                        <div ref={tabRef} style={{ width: "200px", height: "100%", display: "flex" , flexDirection:"column"}}>
                            <div style={{ width: "100%", minHeight: "30px", display: "flex", padding: "10px 5px" }}>
                                {"Categories" }
                            </div>
                            <div ref={tabRef} style={{ width: "100%", height: "100%", display: "flex", position: "relative" }}>
                                <div style={{
                                    width: "100%", height: "100%", display: "flex", flexFlow: "column", overflowY: "auto", position: "absolute", direction: "rtl"
                                }}>

                                    <button
                                        style={buildActiveStyle(tabIndex === usersTabIndex)}
                                        onClick={() => {
                                        setTabIndex(usersTabIndex);
                                    }}>Users</button>

                                    <button
                                        style={buildActiveStyle(tabIndex === accountsTabIndex)}
                                        onClick={() => {
                                        setTabIndex(accountsTabIndex);
                                    }}>Accounts</button>
                                    <button
                                        style={buildActiveStyle(tabIndex === assetsTabIndex)}
                                        onClick={() => {
                                        setTabIndex(assetsTabIndex);
                                    }}>Assets</button>
                                    <button
                                        style={buildActiveStyle(tabIndex === unitsTabIndex)}
                                        onClick={() => {
                                        setTabIndex(unitsTabIndex);
                                    }}>Units</button>
                                    <button
                                        style={buildActiveStyle(tabIndex === contactsTabIndex)}
                                        onClick={() => {
                                        setTabIndex(contactsTabIndex);
                                    }}>Contacts</button>
                                    <button
                                        style={buildActiveStyle(tabIndex === actvitiesTabIndex)}
                                        onClick={() => {
                                        setTabIndex(actvitiesTabIndex);
                                    }}>Activities</button>
                                    <button
                                        style={buildActiveStyle(tabIndex === eventsTabIndex)}
                                            onClick={() => {
                                            setTabIndex(eventsTabIndex);
                                        }}>Events</button>
                                    <button
                                        style={buildActiveStyle(tabIndex === simcarOparatorTabIndex)}
                                        onClick={() => {
                                            setTabIndex(simcarOparatorTabIndex);
                                        }}>Simcard operator</button>
                                    <button
                                        style={buildActiveStyle(tabIndex === twilioTabIndex)}
                                        onClick={() => {
                                            setTabIndex(twilioTabIndex);
                                        }}>Twilio requests</button>
                                    <button
                                        style={buildActiveStyle(tabIndex === settingTabIndex)}
                                        onClick={() => {
                                            setTabIndex(settingTabIndex);
                                        }}>Settings</button>
                                    <div></div>
                                </div>
                            </div>
                        </div>
                        <div style={{
                            backgroundColor: "lightgray",
                            height: "98%", width: "2px",
                            margin: "0px 5px", display: "flex",alignSelf:"center"                          
                        }}></div>
                        <div style={{ width: "100%", height: "100%", display: "flex", flex: 1, flexDirection:"column" }}>
                            <div style={{ width: "100%", minHeight: "30px", display: "flex", padding: "10px 5px" }}>
                                <button style={{ margin: "2px", width: "120px" }} onClick={() => {
                                    setUserCreationOptions({
                                        open:true
                                    })
                                }}>
                                    Create User
                                </button>
                                <button
                                    style={{ margin: "2px", width: "120px" }}
                                    onClick={() => {
                                        setAccountCreationOptions({
                                            open:true
                                        })
                                    }}>
                                    Create Account
                                </button>
                                <button style={{ margin: "2px", width: "120px" }} onClick={() => {
                                    setAssetCreationOpen(true);
                                }}>
                                    Add Asset
                                </button>
                                <button style={{ margin: "2px", width: "120px" }} onClick={() => {
                                    setUnitCreationOpen(true);
                                }}>
                                    Add Unit
                                </button>
                                <button style={{ margin: "2px", width: "120px" }} onClick={() => {
                                    setContactCreationOpen(true);
                                }}>
                                    Add Contact
                                </button>
                            </div>
                            <div style={{ height: "2px", width: "100%", margin: "4px 0px",backgroundColor:"lightgray" }} />
                            <div style={{ width: "100%", height: "100%", display: "flex", flex: 1 , position:"relative"}}>
                                <div style={{
                                    width: "100%", height: "100%", position: "absolute"
                                }}>
                                    {userTabActive && <UsersListView ref={usersListViewRef}
                                        users={users}
                                        onEditUser={(user) => {
                                            setUserCreationOptions({
                                                open: true,
                                                editUser: user
                                            })
                                        }} />}
                                    {tabIndex === accountsTabIndex &&
                                        <AccountListView
                                        onEditAccount={(account) => {
                                            setAccountCreationOptions({
                                                open: true,
                                                editAccount:account
                                            });
                                        }} />}
                                    {tabIndex === assetsTabIndex && <AssetsListView
                                        ref={assetsListViewRef }
                                        onEditAsset={(asset) => {
                                            setEditAsset(asset)
                                            setAssetCreationOpen(true)
                                        }}

                                          assetTypes={assetTypes} />}
                                    {tabIndex === unitsTabIndex && <UnitsListView units={units} 
                                        onUnitConfigs={(unit) => {
                                            setConfigsUnit(unit)
                                            setUnitConfigsTableOpen(true)
                                        }}
                                    />}
                                    {tabIndex === contactsTabIndex && <ContactsListView 
                                        onEditContact={(asset) => {
                                            setEditContact(asset)
                                            setContactCreationOpen(true)
                                        }}
                                        onSMSVeriication={(item) => {
                                            console.log("Yos");
                                        }}
                                        onContactDeleted={(item) => {
                                            console.log("Yos");
                                            //updateContacts()
                                        }}
                                    />}
                                    {tabIndex === actvitiesTabIndex && <ActivitiesListView activities={activities} />}
                                    {tabIndex === eventsTabIndex &&
                                        <EventsListView />}
                                    {tabIndex === simcarOparatorTabIndex
                                        && <SimcardOperator activities={activities} units={units} />}
                                    {tabIndex === twilioTabIndex
                                        && <TwilioListView activities={activities} units={units} />}
                                    {tabIndex === settingTabIndex
                                        && <AdminSettingPage activities={activities} units={units} />}

                                    
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
                <div style={{ height: barHeight, backgroundColor: appThemeColor, display: 'flex', width:"100%", position:"relative"}}>
                    {isFullScreen && <TimeDisplay />}
                </div>
                {
                    (
                        userCreationOptions?.open ||
                        accountCreationOptions?.open ||
                        unitCreationOpen ||
                        assetCreationOpen ||
                        contactCreationOpen ||
                        unitConfigsTableOpen
                    ) &&
                    <div style={{ position: "absolute", zIndex: 1, width: "100%", height: "100%", backgroundColor: hexToRgbaWithAlpha("#000000", 0.4) }}>
                            {userCreationOptions?.open && <UserCreation
                                options={userCreationOptions}
                                onUserCreated={(data) => {
                                    setUserCreationOptions(null)
                                    setResultMessage(`User ${data.user.userName} was successfully created, password: ${data.password}`)
                                    usersListViewRef.current.updateUsers();
                                }}
                                onUserEdited={(data) => {
                                    setUserCreationOptions(null)
                                    setResultMessage(`User ${data.name} was successfully editted`)
                                    usersListViewRef.current.updateUsers();
                                }}
                                onCancel={() => {
                                    setUserCreationOptions(null)
                                }} />}
                            {accountCreationOptions?.open && <AccountCreation
                                options={accountCreationOptions}
                                onAccountCreated={(data) => {
                                    setAccountCreationOptions(null);
                                }}
                                onAccountEdited={(data) => {
                                    setAccountCreationOptions(null);
                                }}
                                onCancel={() => {
                                    setAccountCreationOptions(null);
                                }} />}
                            {unitCreationOpen && <UnitCreation
                                open={unitCreationOpen}
                                onUnitEdited={() => {
                                    setUnitCreationOpen(false)
                                }}
                                onUnitCreated={() => {
                                    setUnitCreationOpen(false)
                                }}
                                onCancel={() => {
                                    setUnitCreationOpen(false)
                                }} />}
                            {assetCreationOpen && <AssetCreation
                                assetTypes={assetTypes}
                                units={units}
                                open={assetCreationOpen}
                                onAssetEdited={() => {
                                    setAssetCreationOpen(false)
                                    assetsListViewRef.current.updateAssets();
                                }}
                                onAssetCreated={() => {
                                    setAssetCreationOpen(false)
                                    assetsListViewRef.current.updateAssets();
                                }}
                                onCancel={() => {
                                    setAssetCreationOpen(false)
                                }} />}
                            {contactCreationOpen && <ContactCreation
                                open={contactCreationOpen}
                                onContactedited={() => {
                                    setContactCreationOpen(false)
                                }}
                                onContactCreated={() => {
                                    setContactCreationOpen(false)
                                }}
                                onCancel={() => {
                                    setContactCreationOpen(false)
                                }} />}
                            {unitConfigsTableOpen && <UnitConfigsTable
                                open={unitConfigsTableOpen}
                                onClose={() => {
                                    setUnitConfigsTableOpen(false)
                                }}
                            />}
                    </div>}
                {resultMessage && <div style={{ position: "absolute", zIndex: 1, width: "100%", height: "100%", backgroundColor: hexToRgbaWithAlpha("#000000", 0.4) }}>
                    <AdministrationResultDialog
                        onClose={() => {
                            setResultMessage(null)
                        }}
                        open={resultMessage} message={resultMessage}>

                    </AdministrationResultDialog>
                </div>}
            </div></AdministrationContext.Provider>);
}

export default Administration;