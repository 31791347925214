import truck from '../images/asset_types/truck/truck.svg';
//import car from '../images/asset_types/car.svg';
//import motorbike from '../images/asset_types/motorbike.svg';
//import bus from '../images/asset_types/bus.svg';
//import boat from '../images/asset_types/boat.svg';
//import plane from '../images/asset_types/plane.svg';
//import train from '../images/asset_types/train.svg';

class AssetTypeProvider {
    constructor(name, year) {
        this.knownAssetTypes = [];
        this.assetTypeCache = {};
        this.getAssetTypeName = this.getAssetTypeName.bind(this);
        this.getPreloadedAssetType = this.getPreloadedAssetType.bind(this);
    }

    setAssetTypeNameReference(data) {
        this.knownAssetTypes = data;
    }

    getAssetTypeName = (assetTypeIndex) => {
        const assetTypes = this.knownAssetTypes;
        const type = assetTypes.find(x => x.typeIndex === assetTypeIndex);
        return type?.name;
    }

    async preloadAssetTypes(onResult, assets) {
        const assetTypeCache = {}
        const getAssetTypeName = this.getAssetTypeName;
        let myPromise = new Promise(async function (resolve, reject) {
            for (var asset of assets) {
                if (asset.assetType === 255) {
                    assetTypeCache[asset.id] = asset.assetTypeCustomIcon
                }
                else {
                    let name = getAssetTypeName(asset.assetType);
                    if (!name) {
                        assetTypeCache[asset.id] = truck;
                        continue;
                    }

                    let assetTypeIconColor = asset.assetTypeIconColor;
                    if (assetTypeIconColor && assetTypeIconColor[0] === '#') {
                        assetTypeIconColor = assetTypeIconColor.replace("#", "");
                    }
                    const key = assetTypeIconColor ? `${name}_${assetTypeIconColor.replace("#", "")}` : name;
                    try {
                        const module = await import(`../images/asset_types/${name}/${key}.svg`);
                        assetTypeCache[asset.id] = module.default;
                        continue;
                    } catch (e) {
                        console.log(e);
                    }

                    try {
                        const module = await import(`../images/asset_types/${name}/${name}.svg`);
                        assetTypeCache[asset.id] = module.default;
                        continue;
                    } catch (e) {
                        console.log(e);
                    }

                    assetTypeCache[asset.id] = truck;
                }
            }

            resolve(true)
        });

        await myPromise;
        this.assetTypeCache = assetTypeCache;
        if (onResult) onResult(true);
    }

    getPreloadedAssetType(id) {
        return this.assetTypeCache[id];
    }
}

    
const assetProvider = new AssetTypeProvider();
export default assetProvider;



//const getAssetTypeIconFromIndex = (index) => {
//    const assetTypes = cache.assetTypes;
//    const type = assetTypes.find(x => x.typeIndex === index);
//    return getAssetTypeIcon(type?.name);
//}

//const getAssetTypeNameFromIndex = (index) => {
//    const assetTypes = cache.assetTypes;
//    const type = assetTypes.find(x => x.typeIndex === index);
//    return type?.name;
//}

//const cacheAssetTypes = (assetTypes) => {
//    cache.assetTypes = assetTypes;
//}


//const rebuildKey = (type, color) => {
//    let name = getAssetTypeNameFromIndex(type);
//    if (!name) return null;
//    return color ? `${name}_${color}` : name;
//}

//const loadDinamicAssetType = async (types, onResult) => {
//    const dynamicAssetTypes = {};
//    let myPromise = new Promise(async function (resolve, reject) {
//        for (var item of types) {
//            let name = getAssetTypeNameFromIndex(item.type);
//            if (!name) continue;
//            const key = item.color ? `${name}_${item.color}` : name;
//            if (!key) continue;
//            if (dynamicAssetTypes[key]) continue;
//            try {
//                const module = await import(`../images/asset_types/${name}/${key}.svg`);
//                dynamicAssetTypes[key] = module.default;
//            } catch (e) {
//                const module = await import(`../images/asset_types/${name}/${name}.svg`);
//                dynamicAssetTypes[name] = module.default;
//            }
//        }

//        resolve(true)
//    });

//    await myPromise;
//    cache.dynamicAssetTypes = dynamicAssetTypes;
//    if (onResult) onResult(true);
//}

//const retrieveDynamicImport = (type, color) => {
//    const dynamicAssetTypes = cache.dynamicAssetTypes
//    let name = getAssetTypeNameFromIndex(type);
//    if (!name) return null;
//    const key = color ? `${name}_${color}` : name;
//    if (!key) return null;
//    return dynamicAssetTypes[key] ?? dynamicAssetTypes[name]
//}