import React from 'react'
import { AppContext } from "../../AppContext";
import { isMobile } from 'react-device-detect';
import {
    fetchDemoContacts,
    addDemoContact,
    removeDemoContact,
    editDemoContact,
    testDemoContact
} from "../../calls/fetchContacts";
import Listbox from "react-widgets/Listbox";
import { IconContext } from "react-icons";
import { MdPersonRemove } from "react-icons/md";
import { FaUserEdit } from "react-icons/fa";
import { BiMailSend } from "react-icons/bi";


import { showLoading } from 'react-global-loading';
import ConfirmBox from "./ConfirmBox"

function ContactsDialog(props) {
    const { contactsPanel, setContactsPanel } = React.useContext(AppContext);
    const [contacts, setContacts] = React.useState([]);
    const [selectedValue, setSelectedValue] = React.useState({});
    const [firstLoad, setFirstLoad] = React.useState(false);
    const [addContactData, setAddContactData] = React.useState({ active: false });
    const [deleteContactData, setDeleteContactData] = React.useState({ active: false });
    const [testContactData, setTestContactData] = React.useState({ active: false });
    
    const [errors, setErrors] = React.useState("");

    const mobile = isMobile;
    const fontSize = mobile ? 15 : 20;
    const awesomeSize = mobile ? 12 : 14;

    const onChange = (e) => {
        setSelectedValue(e);
        setErrors("");
    };

    const addContact = (newContact) => {
        const contact = newContact;
        showLoading(true);
        addDemoContact(contact, (e) => {
            showLoading(false);
            if (e.error) {
                return;
            }

            setAddContactData({ active: false });
            updateContactsList();
        });
    };

    const editContact = (newContact) => {
        const contact = newContact;
        showLoading(true);
        editDemoContact(contact, (e) => {
            showLoading(false);
            if (e.error) {

                return;
            }

            setAddContactData({ active: false });
            updateContactsList();
        });
    };


    

    const deleteContact = (contact) => {
        showLoading(true);
        removeDemoContact({ contactId: contact.id, accountId: 1 }, (e) => {
            showLoading(false);


            setAddContactData({ active: false });
            setDeleteContactData({ ...deleteContactData, active: false });
            if (e.error) {
                setErrors("Une erreur s'est produite lors de la suppression");
                return;
            }

            updateContactsList();
        })
    }

    const testContact = (contact) => {
        showLoading(true);
        testDemoContact({ contactId: contact.id, accountId: 1 }, (e) => {
            showLoading(false);
            setAddContactData({ active: false });
            setTestContactData({ ...testContactData, active: false });
            if (e.error) {
                setErrors("Une erreur s'est produite lors du test");
            }
        })
    }

    React.useEffect(() => {
        setErrors("");
        if (!contactsPanel) {
            setAddContactData({ active: false });
            return;
        }

        updateContactsList();
    }, [contactsPanel]);

    const updateContactsList = () => {
        showLoading(true);
        fetchDemoContacts((e) => {
            showLoading(false);
            if (!firstLoad) {
                setFirstLoad(true);
            }
            if (e.error) {
                return;
            }

            const contactList = e.data;

            if (!contactList) {
                setContacts([]);
                return;
            }

            setContacts([...contactList]);
        })
    }

    return (
        <dialog open={contactsPanel && firstLoad} style={{ padding: '10px', color: "black", alignSelf: "center", whiteSpace: "pre-line", fontSize: fontSize, fontWeight: "bold", borderWidth: "1px", zIndex: 20 }}>
            <div style={{ width: "100%", display: "flex", flexDirection: "column" }}>
                <div style={{ margin: "0px 0px 10px 0px", height: "14px", fontSize: "16px" }}>Demo contacts</div>
                <Listbox
                    style={{ flexGrow: 1, width: "100%", display: "flex" }}
                    dataKey="id"
                    textField="name"
                    data={contacts}
                    onChange={onChange}
                    value={selectedValue}
                    renderItem={(e) => {
                        const contact = e.item;
                        const selected = e?.item?.id === selectedValue?.id;
                        return <span style={{ textAlign: "left", width: "100%", display: "flex" }}>
                            {
                                (<div style={{ flexGrow: 1, width: "100%", display: "flex", flexDirection: "column" }}>
                                    <div>
                                        <span style={{ margin: "0px 5px 0px 0px" }}>{e.item.name}</span>
                                        {
                                            e.item.number ?
                                                <span style={{ margin: "0px 5px 0px 0px" }}>Numero: {e.item.number}</span> :
                                                null
                                        }
                                        {
                                            e.item.email ?
                                                <span style={{ margin: "0px 5px 0px 0px" }}>Email: {e.item.email}</span> :
                                                null
                                        }
                                    </div>
                                    <div>Address: {e.item.adress}</div>
                                </div>)
                            }

                            <div  style={{ minWidth: "50px", display: "flex", alignItems: "center", justifyContent: "center" }}>
                                <button disabled={!props.allowTest} style={{
                                    backgroundColor: "transparent", borderWidth: "0.2px",
                                    display: selected ? "inline" : "none", margin: "3px 1px"
                                }} onClick={() => {
                                    setTestContactData({
                                        testContact: contact,
                                        active: true,
                                        title: `Confirmer Test`,
                                        message: `Vous etes sur le point d'envoyer un SMS test a : ${contact.name}`
                                    });
                                }}>
                                    <div style={{ alignItems: "center", display: "flex", justifyContent: "center" }}>
                                        <IconContext.Provider value={{ size: awesomeSize }}>
                                            <BiMailSend />
                                        </IconContext.Provider>
                                    </div>
                                </button>
                                <button disabled={!props.allowEdit} style={{
                                    backgroundColor: "transparent", borderWidth: "0.2px",
                                    display: selected ? "inline" : "none", margin: "3px 1px"
                                }} onClick={() => {
                                    setAddContactData({
                                        editContact: contact,
                                        active: true,
                                        title: `Edition contact: ${contact.name}`
                                    });
                                    //onremoveDemoContactClick(contact)
                                }}>
                                    <div style={{ alignItems: "center", display: "flex", justifyContent: "center" }}>
                                        <IconContext.Provider value={{ size: awesomeSize }}>
                                            <FaUserEdit />
                                        </IconContext.Provider>
                                    </div>
                                </button>
                                <button disabled={!props.allowRemove } style={{ backgroundColor: "transparent", borderWidth: "0.2px", display: selected ? "inline" : "none", margin: "3px 1px" }}
                                    onClick={() => {
                                        const data = { ...deleteContactData };
                                        data.active = true;
                                        data.title = 'Confirmer operation';
                                        data.message = `Vous etes sur le point de supprimer votre contact: ${contact.name}?`;
                                        data.deleteContact = contact;
                                        setDeleteContactData(data);
                                    }}>
                                    <div style={{ alignItems: "center", display: "flex", justifyContent: "center" }}>
                                        <IconContext.Provider value={{ size: awesomeSize }}>
                                            <MdPersonRemove />
                                        </IconContext.Provider>
                                    </div>
                                </button>
                            </div>
                        </span>
                    }} />
                {errors ? <div style={{ textAlign: "left", fontSize: "11px", color: "red" }}>{errors}</div> : null}
                <div style={{ textAlign: "left", width: "100%", height: "22px", display: "flex", flexDirection: "row", margin: "10px 0px 0px 0px" }}>
                    <button style={{
                        width: "250px", backgroundColor: "whitesmoke", color: "black", borderRadius: "2px", height: "100%",
                        borderWidth: "2px", margin: "5px 0px 1px 0px", borderColor: 'lightgray', cursor: "pointer"
                    }} onClick={() => {
                        setAddContactData({
                            editContact: null,
                            active: true,
                            title:"Ajout contact"
                        });
                    }}>Ajouter contact</button>
                    <span style={{ flexGrow: 1, width: "100%", display: "flex" }}></span>
                    <button style={{
                        width: "250px", backgroundColor: "whitesmoke", color: "black", height: "100%",
                        borderRadius: "2px", borderWidth: "2px", margin: "5px 0px 1px 0px", borderColor: 'lightgray', cursor: "pointer"
                    }} onClick={() => { setContactsPanel(false) }}>Fermer</button>
                </div>
                <AddContactForm
                    open={contactsPanel && addContactData?.active}
                    onCancel={() => { setAddContactData(null) }}
                    onConfirm={(newContact) => {
                        const edit = newContact.edit;
                        if (edit) {
                            editContact(newContact);
                        }
                        else {
                            addContact(newContact);
                        }
                    }}
                    title={addContactData?.title}
                    editData={addContactData?.editContact }
                />
                <ConfirmBox
                    open={testContactData.active}
                    title={testContactData.title}
                    message={testContactData.message}
                    onConfirm={() => {
                        testContact(testContactData.testContact);
                    }}
                    onCancel={() => { setTestContactData({ ...testContactData, active: false }) }} />
                <ConfirmBox
                    open={deleteContactData.active}
                    title={deleteContactData.title}
                    message={deleteContactData.message}
                    onConfirm={() => {
                        deleteContact(deleteContactData.deleteContact);
                    }}
                    onCancel={() => { setDeleteContactData({ ...deleteContactData, active: false }) }} />
            </div>
        </dialog>
    );
};


class AddContactForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = { name: "", email: "", number: "", adress: "", id: "", editData: null };
    }

    componentWillUpdate = (nextProps, state) => {
        if (nextProps.open !== this.props.open) {
            if (nextProps.open === true) {
                const editData = nextProps.editData;
                if (editData)
                    this.setState({ name: editData.name, email: editData.email, number: editData.number, adress: editData.adress, editData: editData });
                else
                    this.setState({ name: "", email: "", number: "", adress: "", editData: null });
            }
        }
    }

    render() {
        return <div style={{ position: "absolute", left: "50%", top: "50%" }}>
            <dialog open={this.props.open} style={{ padding: '3px', color: "black", position: "relative", borderWidth: "0.3px", zIndex: 25, transform: `translate(-50%, -50%)` }}>
                <div style={{ fontSize: "12px", height: "20px" }}>{this.props.title }</div>
                <div style={{ width: "100%" }}>
                    <div style={{ textAlign: "left", fontSize: "12px" }}>
                        <div style={{ display: "flex", }}>
                            <label style={{ display: "block", width: "100px" }} htmlFor="new_contact_name">Nom</label>
                            <input id="new_contact_name" value={this.state.name} disabled={this.state.editData } type="text" placeholder="nom"
                                onChange={(e) => {
                                    const val = { ...this.state };
                                    val.name = e.target.value;
                                    this.setState(val);
                                }} />
                        </div>
                        <div style={{ display: "flex", margin: "5px 0px" }}>
                            <label style={{ display: "block", width: "100px" }} htmlFor="new_contact_number">Numero</label>
                            <input id="new_contact_number" type="text" value={this.state.number} placeholder="numero"
                                onChange={(e) => {
                                    const val = { ...this.state };
                                    val.number = e.target.value;
                                    this.setState(val);
                                }} />
                        </div>
                        <div style={{ display: "flex", margin: "5px 0px" }}>
                            <label style={{ display: "block", width: "100px" }} htmlFor="new_contact_email">Email</label>
                            <input type="text" id="new_contact_email" placeholder="Email" value={this.state.email}
                                onChange={(e) => {
                                    const val = { ...this.state };
                                    val.email = e.target.value;
                                    this.setState(val);
                                }} />
                        </div>
                        <div style={{ display: "flex", margin: "5px 0px" }}>
                            <label style={{ display: "block", width: "100px" }} htmlFor="new_contact_address">Addresse</label>
                            <textarea id="new_contact_address" style={{ resize: "none", verticalAlign: "middle" }} placeholder="Addresse" value={this.state.adress}
                                onChange={(e) => {
                                    const val = { ...this.state };
                                    val.adress = e.target.value;
                                    this.setState(val);
                                }} />
                        </div>
                    </div>
                    {this.state?.error ? <div style={{ textAlign: "left", fontSize: "11px", color:"red" }}>{this.state?.error}</div> : null}
                    <div style={{ display: "flex", }}>
                        <button style={{
                            height: "25px", width: "100px", backgroundColor: "whitesmoke", color: "black",
                            borderRadius: "2px", borderWidth: "2px", margin: "5px 0px 1px 0px",
                            borderColor: 'lightgray', cursor: "pointer"
                        }}
                            onClick={() => {
                                let error = "";
                                const contact = { ...this.state };
                                const name = contact.name;
                                if (!name) {
                                    error = "* Le nom ne peut pas �tre vide";
                                }

                                const email = contact.email;
                                const number = contact.number;
                                if (!email && !number) {
                                    if (error) { error += "\n"; }
                                    error += "* Numero et Email ne peuvent pas �tre tous vide";
                                }

                                if (error) {
                                    const val = { ...this.state };
                                    val.error = error;
                                    this.setState(val);
                                    return;
                                }
                                
                                const confirmedData = {
                                    name: contact.name,
                                    number: contact.number,
                                    email: contact.email,
                                    adress: contact.adress,
                                    accountId: 1,
                                    contactId: contact?.editData?.id ?? undefined
                                }
                                if (contact?.editData) {
                                    confirmedData.edit = true;
                                }

                                if (this.props?.onConfirm) {
                                    this.props.onConfirm(confirmedData);
                                }
                            }}>Confirmer</button>
                        <span style={{ flexGrow: 1 }}></span>
                        <button style={{
                            width: "100px", height: "25px", backgroundColor: "whitesmoke",
                            color: "black", borderRadius: "2px", borderWidth: "2px", margin: "5px 0px 1px 0px",
                            borderColor: 'lightgray', cursor: "pointer"
                        }} onClick={() => {
                            if (this.props?.onCancel) {
                                this.props.onCancel();
                            }
                        }}>Annuler</button>
                    </div>
                </div>
            </dialog>
        </div>;
    }
};

export default ContactsDialog;