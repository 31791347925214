import { appConfig } from '../../configurations/appConfig';
import urlJoin from 'url-join';
const fetchActivities = (onResult = null, start, end) => {
    const method = "admin_retrieve_activities?" + new URLSearchParams({
        start: start ? start.toISOString() : "",
        end: end ? end.toISOString() : ""
    });

    const server = appConfig.data.server;
    const authorisationToken = appConfig.authorisationToken;
    const requestOptions = {
        headers: {
            'Authorization': `Bearer ${authorisationToken}`,
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
        method: 'GET',
    };

    fetch(urlJoin(server, method), requestOptions)
        .then(response => {
            if (response.ok) {
                console.info("Operation succeeded");
                response.json().then(data => {
                    console.info(data);
                    if (!onResult) return;
                    onResult({
                        error: null,
                        data: data
                    });
                })
            }
            else {
                response.text().then(text => {
                    if (!onResult) return;
                    onResult({ error: `${response.statusText} (${response.status}): ${text}`, data: null });
                }).catch(err => {
                    console.info(`${err}`);
                    if (!onResult) return;
                    onResult({ error: `${err}`, data: null });
                });
            }
        })
        .catch(err => {
            console.info(`${err}`);
            if (!onResult) return;
            onResult({ error: `${err}`, data: null })
        })
};


export default fetchActivities;