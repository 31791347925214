const appConfig = {}
function loadAppConfigs(onResult = null) {
    import('./appConfig.json')
        .then(data => {
            appConfig.data = data;
            if (onResult) {
                onResult(data);
            }
        })
        .catch(error => console.log(error));
}

const setConfigProperty = (key, value) => {
    appConfig[key] = value;
}

export { appConfig, loadAppConfigs, setConfigProperty };