import React from 'react'
import Listbox from "react-widgets/Listbox";
import DatePicker from "react-widgets/DatePicker";
import { localTimeFormat, toUctTime, toLocalTime } from '../../utilities/timeHelper'
import { AdministrationContext } from "./AdministrationContext"
import { showLoading } from 'react-global-loading';
import fetchActivities from "../../calls/administration/fetchActivities";
import DropdownList from "react-widgets/DropdownList";
import { isMobile } from 'react-device-detect';
import {
    getTodayStartDate, getTodayEndDate,
    getYesterdayStartDate, getYesterdayEndDate
} from '../../utilities/dateHelper'
import { IconContext } from "react-icons";
import { GrUpdate } from "react-icons/gr";
import DataGrid, { Row } from 'react-data-grid';

const getColumns = () => {
    return [
        {
            key: 'date',
            name: "Date"
        },
        {
            key: 'message',
            name: "Message"
        },
        {
            key: 'user',
            name: "User"
        },
        {
            key: 'machine',
            name: "Machine"
        },
        {
            key: 'ip',
            name: "Ip"
        }
    ]
};

function ActivitiesListView(props) {
    const [intervalData, setIntervalData] = React.useState(null);
    const [selectedActivity, setSelectedActivity] = React.useState(null);
    const [columns] = React.useState(getColumns);
    const [selectedGridRows, setSelectedGridRows] = React.useState(new Set([]));
    const [rows, setRows] = React.useState([]);
    const RowRenderer = (key, props) => <Row {...props} selectedCellIdx={undefined} />
    const divRef = React.useRef(null);
    const updateActivities = (onComplete, silent = true) => {
        if (!silent) {
            //setBusy(true);
            showLoading(true);
        }

        fetchActivities((e) => {
            if (!silent) {
                //setBusy(false);
                showLoading(false);
            }
            if (e.error) {
                if (onComplete) onComplete(false);
                return;
            }

            const result = [];
            if (e.data) {

                for (var activity of e.data) {
                    result.push({
                        id: activity.id,
                        date: localTimeFormat(toLocalTime(activity.date)),
                        message: activity?.message,
                        user: activity?.webTraceUser?.userName,
                        machine: activity.machineName,
                        ip: activity.ipAdress
                    });
                }

                setRows(result)
            }

            if (onComplete) onComplete(true);
        }, intervalData?.start, intervalData?.end);
    }

    function rowKeyGetter(row) { return row.id; }
    React.useEffect(() => {
        updateActivities(null, false)

        const handleClickOutside = event => {
            if (divRef.current && !divRef.current.contains(event.target)) {
                if (event.target.nodeName.toLowerCase() === 'button') return;
                setSelectedGridRows(new Set([]))
                //if (!props.onClickOutside) return;
                //props.onClickOutside()
            }
        };

        document.addEventListener("mouseup", handleClickOutside);
        return () => {
            document.removeEventListener("mouseup", handleClickOutside);
        };
    }, []);

    return (
        <div style={{ display: "flex", flexDirection: "column", height: "100%", width: "100%" }}>
            <div style={{ display: "flex", flexDirection: "row", justifyContent:"end", padding:"0px 5px" }}>
                <ActivityTimeRange onRangeInterval={(e) => {
                    setIntervalData(e)
                } } />
                <button style={{ margin: "0px 0px 0px 10px" }}
                    onClick={() => {
                        updateActivities(null, false)
                    }}>
                    <div style={{ alignItems: "center", display: "flex", justifyContent: "center", height: "100%", width: "100%" }}>
                        <IconContext.Provider value={{ size: 14 }}>
                            <GrUpdate />
                        </IconContext.Provider>
                    </div>
                </button>
            </div>
            <div ref={divRef} style={{ display: "flex", flex:1 }}>
                <div style={{ width: "100%", height: "100%", display: "flex", overflow: "auto", position: "relative", flex: 1 }}>
                    <DataGrid
                        defaultColumnOptions={{ sortable: true, resizable: false }}
                        rowHeight={25}
                        columns={columns}
                        rows={rows}
                        rowKeyGetter={rowKeyGetter}
                        className='rdg-light'
                        onSelectedRowsChange={setSelectedGridRows}
                        selectedRows={selectedGridRows}
                        onRowsChange={setRows}
                        enableVirtualization={true}
                        style={{ width: "100%", height: "calc(100% - 5px)", position: "absolute" }}
                        renderers={{ renderRow: RowRenderer }}
                        onCellClick={(args, event) => {
                            const rowId = args.row.id;
                            setSelectedActivity(args.row.unit)
                            var set = new Set([rowId]);
                            setSelectedGridRows(set);
                        }} />
                </div>
            </div>
        </div>);
};

function ActivityTimeRange(props) {
    //const [startDate, setStartDate] = React.useState(getTodayStartDate());
    //const [endDate, setEndDate] = React.useState(getTodayEndDate());

    const [rangeData, setRangeData] = React.useState({ start: getTodayStartDate(), end: getTodayEndDate() });
    const [startPickerOpen, setStartPickerOpen] = React.useState(undefined);
    const [endPickerOpen, setEndPickerOpen] = React.useState(undefined);

    const [intervalRanges] = React.useState(["Aujourd'hui", "Hier", "Personnalisé"]);
    const [intervalRange, setIntervalRange] = React.useState(intervalRanges[0]);
    const titleFontSize = isMobile ? 10 : 14;

    const startPickerInputProps = {
        editing: false,
        readOnly: true,
        onClick: (e) => {
            setStartPickerOpen(true);
        }
    };

    const endPickerInputProps = {
        editing: false,
        readOnly: true,
        onClick: (e) => {
            setEndPickerOpen(true);
        }
    };

    React.useEffect(() => {
        if (props.onRangeInterval) {
            const data = { ...rangeData }
            props.onRangeInterval(data)
        }
    }, [rangeData]);

    React.useEffect(() => {
        if (intervalRange === intervalRanges[2]) {
            const data = { ...rangeData }
            data.option = 2;
            setRangeData(data)
        }
        else if (intervalRange === intervalRanges[1]) {
            const data = { ...rangeData }
            data.start = getYesterdayStartDate();
            data.end = getYesterdayEndDate();
            data.option = 1;

            setRangeData(data)
        }
        else {
            const data = { ...rangeData }
            data.start = getTodayStartDate();
            data.end = getTodayEndDate();
            data.option = 0;
            setRangeData(data)
        }
    }, [intervalRange]);

    const isCustom = intervalRange === intervalRanges[2];
    return (
        <div style={{ display: "flex", flexDirection: "row" }}>
            {isCustom && <div style={{ display: "flex", flexDirection: "row", margin: "0px 5px" }}>
                <div style={{ display: "flex", margin: "2px 0px", width: "150px", alignItems: "baseline" }}>
                    <label htmlFor="fromDate" style={{ textAlign: "left", fontSize: titleFontSize, margin: "0px 4px" }}>
                        F
                    </label>
                    <DatePicker
                        onChange={(e) => {
                            const data = { ...rangeData }
                            data.option = 2;
                            data.start = e;
                            setRangeData(data)
                        }}
                        id="fromDate"
                        dropUp={props.dropUp}
                        value={rangeData?.start}
                        open={startPickerOpen}
                        filter={false}
                        inputProps={startPickerInputProps}
                        defaultValue={rangeData?.start}
                        max={rangeData?.end}
                        valueFormat={{ dateStyle: "medium", hourCycle: 'h23' }}
                        //readOnly={disabled}
                        onBlur={() => { setStartPickerOpen(undefined) }}
                        onSelect={() => { setStartPickerOpen(undefined) }} />
                </div>
                <div style={{ display: "flex", margin: "2px 0px", width: "150px", alignItems: "baseline" }}>
                    <label htmlFor="toDate" style={{ textAlign: "left", fontSize: titleFontSize, margin: "0px 4px" }}>T</label>
                    <DatePicker
                        onChange={(e) => {
                            const data = { ...rangeData }
                            data.option = 2;
                            data.end = e;
                            setRangeData(data)
                        }}
                        id="toDate"
                        open={endPickerOpen}
                        dropUp={props.dropUp}
                        value={rangeData?.end}
                        min={rangeData?.start}
                        inputProps={endPickerInputProps}
                        filter={false}
                        defaultValue={rangeData?.end}
                        valueFormat={{ dateStyle: "medium", hourCycle: 'h23' }}
                        onBlur={() => { setEndPickerOpen(undefined) }}
                        onSelect={() => { setEndPickerOpen(undefined) }} />
                </div>
            </div>}
            <DropdownList
                style={{ display: "flex", width: "120px" }}
                defaultValue={intervalRanges[0]}
                data={intervalRanges}
                autoFocus={false}
                filter={false}
                onChange={(e) => {
                    setIntervalRange(e)
                }}
                value={intervalRange}
            />
        </div>);
}

export default ActivitiesListView;