import { localTimeFormat } from '../../utilities/timeHelper'
import React from 'react'
import DataGrid, { Row } from 'react-data-grid';
import { showLoading } from 'react-global-loading';
import { fetchUnits } from "../../calls/administration/fetchUnits";

const getColumns = () => {
    return [
        {
            key: 'hwSerial',
            name: "Hardware Serial"
        },
        {
            key: 'name',
            name: "name"
        },
        {
            key: 'updated',
            name: "Updated"
        },
        {
            key: 'added',
            name: "Added"
        }]
};

function rowKeyGetter(row) {return row.id;}
function UnitsListView(props) {
    const [selectedUnit, setSelectedUnit] = React.useState(null);
    const [columns] = React.useState(getColumns);
    const [selectedGridRows, setSelectedGridRows] = React.useState(new Set([]));
    const [rows, setRows] = React.useState([]);
    const [busy, setBusy] = React.useState(true);


    const updateUnits = (silent = true) => {
        if (!silent) {
            setBusy(true);
            showLoading(true);
        }

        fetchUnits((e) => {
            if (!silent) {
                setBusy(false);
                showLoading(false);
            }
            if (e.error) {
                //if (onComplete) onComplete(false);
                return;
            }

            const units = e?.data?.units;
            const assets = e?.data?.relatedAssets;
            //setUnits(e.data);
            const result = [];
            if (units) {
                for (var unit of units) {
                    const asset = assets?.find(x => x.id === unit.assignedAssetId);
                    result.push({
                        id: unit.id,
                        hwSerial: unit.hwSerial,
                        name: asset?.name,
                        updated: localTimeFormat(unit.updated),
                        added: localTimeFormat(unit.added),
                        unit: unit
                    });
                }
            }

            setRows(result)
            //if (onComplete) onComplete(true);
        });
    }

    React.useEffect(() => {
        updateUnits(false);
        return () => {
        };
    }, []);

    if (busy) return null;
    const RowRenderer = (key, props) => <Row {...props} selectedCellIdx={undefined} />
    return (
        <div style={{
            width: "100%", height: "100%", display: "flex", overflow: "auto", flexDirection: "column"
        }} >

            <div style={{ width: "100%", height: "100%", display: "flex", overflow: "auto", position: "relative", flex: 1 }}>
                <DataGrid
                    defaultColumnOptions={{ sortable: true, resizable: false }}
                    rowHeight={30}
                    columns={columns}
                    rows={rows}
                    rowKeyGetter={rowKeyGetter}
                    className='rdg-light'
                    onSelectedRowsChange={setSelectedGridRows}
                    selectedRows={selectedGridRows}
                    onRowsChange={setRows}
                    enableVirtualization={true}
                    style={{ width: "100%", height: "calc(100% - 5px)", position: "absolute" }}
                    renderers={{ renderRow: RowRenderer }}
                    onCellClick={(args, event) => {
                        const rowId = args.row.id;
                        setSelectedUnit(args.row.unit)
                        var set = new Set([rowId]);
                        setSelectedGridRows(set);

                        //const selection = rows.find(x => x.id === args.row.id);
                        //setSelectedEntry(selection)
                    }} />
            </div>
            <div style={{ height: "30px", width: "100%", display: "flex", padding: "10px 0px" }}>
                <button style={{ padding: "2px", width: "80px", margin: "0px 5px" }}
                    disabled={!selectedUnit}
                    onClick={() => {
                        if (selectedUnit && props.onUnitConfigs) {
                            props.onUnitConfigs(selectedUnit)
                        }
                    }}>
                    Configs
                </button>
                <button style={{ padding: "2px", width: "80px", margin: "0px 5px" }}
                    disabled={!selectedUnit}
                    onClick={() => {
                        if (selectedUnit && props.onEditUnit) {
                            props.onEditUnit(selectedUnit)
                        }
                    }}>
                    Edit
                </button>
                <button style={{ padding: "2px", width: "80px", margin: "0px 5px" }}
                    disabled={!selectedUnit}
                    onClick={() => {
                        if (selectedUnit && props.onDeleteEdit) {
                            props.onDeleteEdit(selectedUnit)
                        }
                    }}>
                    Delete
                </button>
            </div>
        </div>);
}

export default UnitsListView;