import React, { useState, useEffect } from "react";
export default function ContextMenu(props) {
    //const [clicked, setClicked] = useState(false);
    //const [points, setPoints] = useState({
    //    x: 100,
    //    y: 100,
    //});
    useEffect(() => {
        const timer = setTimeout(() => {
            props.onClickOutside();
        }, 10000);
        const handleClick = () => {
            if (props.onClickOutside) {
                props.onClickOutside();
            }
        }
        window.addEventListener("click", handleClick);
        return () => {
            window.removeEventListener("click", handleClick);
            clearTimeout(timer);
        };
    }, []);
    return (
        <div style={{ left:props.left, top:props.top, position:"fixed", display:"flex"} }>
            {props.children}
        </div>
    );
}