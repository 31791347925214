import Listbox from "react-widgets/Listbox";
import { localTimeFormat, convertTimeSent, toLocalTime } from '../../utilities/timeHelper'
import React from 'react'
import { fetchUnitSms } from "../../calls/administration/fetchUnitSms";
import {
    fetchUssd,
    forwardUssdRequest,
    fetchUssdStoredCodes,
    fetchUAddUssdStoredCode,
    fetchDeleteUssdStoredCode,
    fetchUpdateUssdStoredCode
} from "../../calls/administration/fetchUssd";

import { IconContext } from "react-icons";
import { isMobile } from 'react-device-detect';
import { RiEditLine, RiDeleteBin6Line } from "react-icons/ri";
import { GiCancel, GiConfirmed } from "react-icons/gi";
import { showLoading } from 'react-global-loading';
import { checkIfEmptyOrWhiteSpace } from "../../utilities/stringHelper";

import { fetchUnitsWithSimcard } from "../../calls/administration/fetchUnits";
import DataGrid, { Row } from 'react-data-grid';

import { hexToRgbaWithAlpha } from "../../utilities/convertColors";
import { Combobox } from "react-widgets";
import { timeSpanFromMinutes, timeSpanFromHours } from "../../utilities/dateHelper";
import ConfirmBox from "./ConfirmBox"
import { GrClose } from "react-icons/gr";


function LastRecordFormatter({ row }) {

    return <span style={{
        alignItems: "center", width: "100%", height: "100%", justifyContent: "left", display: "flex"
    }}  >
        {row.addded }
        {row.asset}
    </span>
}


const getColumns = () => {
    return [
        {
            key: 'imsi', name: "IMSI"
        },
        {
            key: 'name', name: "Asset"
        },
        {
            key: 'iccid', name: "ICCID"
        },

        {
            key: 'net', name: "Operator"
        },
        {
            key: 'added', name: "Added"
        },
        {
            //key: 'lastRecord',
            name: 'lastRecord',
            renderCell: LastRecordFormatter,
        },
    ]
};

function rowKeyGetter(row) {
    return row.id;
}


function SimcardOperator(props) {
    const [columns] = React.useState(getColumns);
    const [selectedGridRows, setSelectedGridRows] = React.useState(new Set([]));
    const [rows, setRows] = React.useState([]);


    const [selectedEntry, setSelectedEntry] = React.useState(null);
    const [filterText, setFilterText] = React.useState("");
    //const [initialCode, setInitialCode] = React.useState(null);
    //const [operationMode, setOperationMode] = React.useState("SMS");
    const [smsOpenData, setSmsOpenData] = React.useState(null);
    const [ussdOpenData, setUssdOpenData] = React.useState(null);
    const [busy, setBusy] = React.useState(false);

    const RowRenderer = (key, props) => <Row {...props} selectedCellIdx={undefined} />
    const updateUnitWithSimcardList = (onResult) => {
        setBusy(true);
        showLoading(true)
        fetchUnitsWithSimcard((e) => {
            setBusy(false);
            showLoading(false)
            if (e.error) {
                return;
            }

            const unitSimcards = e?.data?.unitSimcards;
            const relatedAssets = e?.data?.relatedAssets;

            var result = [];
            if (unitSimcards) {
                for (var item of unitSimcards) {
                    var latestData = item.lastestData;
                    const unitId = latestData?.unitId;
                    const asset = unitId ? relatedAssets?.find(x => x.assignedUnitId === unitId) : "";
                    const iccid = item.iccid ?? "null";
                    const imsi = item.imsi ?? "null";
                    const net = item.network ?? "null";
                    const added = item?.added ? localTimeFormat(item.added) : "null";
                    result.push({
                        id: item?.id,
                        name: asset?.name,
                        iccid: iccid,
                        imsi: imsi,
                        net: net,
                        added: added,
                        assetId: asset?.id,
                        linkedRecord: latestData
                    });
                }
            }

            //result = Array(20).fill(result[0]);
            setRows(result)
        });
    }


    React.useEffect(() => {
        updateUnitWithSimcardList();
    }, []);

    //const isSmsMode = operationMode === "SMS";
    //const isUSSD = operationMode === "USSD";
    //const isKnownUSSD = operationMode === "USSD_KNOWN";
    if (busy) {
        return null;
    }

    return (
        <div style={{ display: "flex", flexDirection: "column", height: "100%", width: "100%" }}>
            <div style={{ display: "flex", height: "100%", width: "100%", flexDirection: "row" }}>
                <div style={{ display: "flex", flexDirection: "column", height: "100%", width: "100%" }}>
                    <div style={{ height: "40px", width: "100%" }}>
                        <span>
                            <input type="text" value={filterText} onChange={(e) => {
                                setFilterText(e.target.value)
                            }}>
                            </input>
                            <button>filter</button>
                        </span>
                    </div>
                    <div style={{ width: "100%", height: "100%", display: "flex", overflow: "auto", flexDirection: "column", position: "relative" }}>
                        <DataGrid
                            //ref={gridRef}
                            defaultColumnOptions={{
                                sortable: true,
                                resizable: false
                            }}

                            rowHeight={25}
                            columns={columns}
                            rows={rows}
                            rowKeyGetter={rowKeyGetter}
                            className='rdg-light'
                            onSelectedRowsChange={setSelectedGridRows}
                            selectedRows={selectedGridRows}
                            onRowsChange={setRows}
                            enableVirtualization={true}
                            renderers={{ renderRow: RowRenderer }}
                            style={{ width: "100%", height: "calc(100% - 5px)", position: "absolute" }}
                            onCellClick={(args, event) => {
                                const rowId = args.row.id;
                                var set = new Set([rowId]);
                                setSelectedGridRows(set);
                                const selection = rows.find(x => x.id === args.row.id);
                                setSelectedEntry(selection)
                            }} />
                    </div>
                    <div style={{ width: "100%", display: "flex" }}>
                        <button onClick={() => {
                            setSmsOpenData({
                                open: true,
                            })
                        }}>
                            SMS
                        </button>
                        <button onClick={() => {
                            setUssdOpenData({
                                open: true,
                            })
                        } }>
                            USSD
                        </button>
                    </div>
                </div>

                {/*<div style={{ backgroundColor: "lightgray", minWidth: "2px", height: "100%" }} />*/}
                {/*<div style={{ display: "flex", flexDirection: "column", height: "100%", width: "100%" }}>*/}
                {/*    <div style={{ height: "40px", width: "100%", display: "flex", padding: "2px" }}>*/}
                {/*        <button*/}
                {/*            onClick={() => {*/}
                {/*                setOperationMode("SMS")*/}
                {/*            }}*/}
                {/*            style={{ width: "100px", borderColor: "lightgray", borderBottom: isSmsMode ? "0px" : undefined }}>*/}
                {/*            SMS*/}
                {/*        </button>*/}
                {/*        <button*/}
                {/*            onClick={() => {*/}
                {/*                setInitialCode(null)*/}
                {/*                setOperationMode("USSD")*/}
                {/*            }}*/}
                {/*            style={{ width: "100px", margin: "0px 4px", borderColor: "lightgray", borderBottom: isUSSD ? "0px" : undefined }}>*/}
                {/*            USSD*/}
                {/*        </button>*/}
                {/*        <button*/}
                {/*            onClick={() => {*/}
                {/*                setOperationMode("USSD_KNOWN")*/}
                {/*            }}*/}
                {/*            style={{ width: "100px", margin: "0px 4px", borderColor: "lightgray", borderBottom: isKnownUSSD ? "0px" : undefined }}>*/}
                {/*            USSD CODES*/}
                {/*        </button>*/}
                {/*    </div>*/}
                {/*    {isSmsMode ? <div style={{ width: "100%", height: "100%" }}>*/}
                {/*        <SimcardSmsList selectedEntry={selectedEntry} assets={props?.assets} />*/}
                {/*    </div> : null}*/}
                {/*    {isUSSD ? <div style={{ width: "100%", height: "100%" }}>*/}
                {/*        <UssdMessenger*/}
                {/*            initialCode={initialCode}*/}
                {/*            selectedEntry={selectedEntry} />*/}
                {/*    </div> : null}*/}
                {/*    {isKnownUSSD ? <div style={{ width: "100%", height: "100%", position: "relative" }}>*/}
                {/*        <UssdKnownCodes*/}
                {/*            onUseCode={(e) => {*/}
                {/*                setInitialCode(e)*/}
                {/*                setOperationMode("USSD")*/}
                {/*            }}*/}
                {/*            selectedEntry={selectedEntry} />*/}
                {/*    </div> : null}*/}
                {/*</div>*/}
            </div>
            {smsOpenData?.open && <div style={{
                width: "100%", height: "100%", display: "flex",
                position: "fixed", backgroundColor: hexToRgbaWithAlpha("#000000", 0.4),
                right: 0, left: 0, top: 0, bottom: 0
            }}>
                <SimcardSmsList
                    onClose={() => {
                        setSmsOpenData({
                            open: false,
                        })
                    }}
                    selectedEntry={selectedEntry} />
            </div>}
            {ussdOpenData?.open && <div style={{
                width: "100%", height: "100%", display: "flex",
                position: "fixed", backgroundColor: hexToRgbaWithAlpha("#000000", 0.4),
                right: 0, left: 0, top: 0, bottom: 0
            }}>
                <UssdMessenger
                    onClose={() => {
                        setUssdOpenData({
                            open: false,
                        })
                    }}
                    selectedEntry={selectedEntry} />
            </div>}
        </div>)
}

function SimcardSmsList(props) {
    const [unitSmsList, setUnitSmsList] = React.useState([]);
    const [shieldError, setShieldError] = React.useState(null);
    const updateUnitSmsList = (onResult) => {
        const assetId = props?.selectedEntry?.assetId;
        if (!assetId) {
            return;
        }

        const interval = timeSpanFromHours(24 * 90);
        fetchUnitSms(assetId, interval, (e) => {
            if (e.error) {
                if (onResult) onResult(false)
                return;
            }

            setUnitSmsList(e.data)
            if (onResult) onResult(true)
        });
    }

    React.useEffect(() => {
        setShieldError(!props.selectedEntry ? "Please select an entry" : null);
        updateUnitSmsList();
    }, [props.selectedEntry]);
    const awesomeSize = isMobile ? 18 : 14;
    return (
        <dialog open={true}
            style={{
                top: "50%",
                bottom: "50%",
                marginRight: "auto",
                marginLeft: "auto",
                padding: "8px", borderWidth: "1px", borderRadius: "5px", minWidth: "450px"
            }}>
            <div style={{ width: "100%", height: "100%", display: "flex", flexDirection: "column" }}>
                <div style={{ width: "100%", height: "100%", display: "flex", flexDirection: "row", padding: "5px 0px" }}>
                    <div style={{ width: "100%", display: "flex", flex: 1 }}>
                        Account Notification Settings for {props?.account?.name}
                    </div>
                    <button style={{ backgroundColor: "transparent", borderWidth: "0px", height: "100%" }}
                        onClick={() => {
                            if (props.onClose) {
                                props.onClose();
                            }
                        }}>
                        <div style={{ alignItems: "center", display: "flex", justifyContent: "center" }}>
                            <IconContext.Provider value={{ size: awesomeSize }}>
                                <GrClose />
                            </IconContext.Provider>
                        </div>
                    </button>
                </div>
                <div style={{ width: "100%", height: "100%", display: "flex", overflow: "auto", flexDirection: "column", position: "relative" }}>
                    <Listbox
                        style={{ position: "absolute" }}
                        dataKey="id"
                        data={unitSmsList}
                        renderItem={({ item }) => {
                            const asset = props?.assets?.find(x => x.id === item.assetId);
                            return (
                                <span style={{ display: "flex", width: "100%" }}>
                                    <span style={{ width: "20%%" }}>{localTimeFormat(item.added)}</span>
                                    <strong style={{ width: "20%" }}>{asset?.name}</strong>
                                    <span style={{ width: "20%" }}>From {item?.header}</span>
                                    {<span style={{ width: "40%" }}>{item.body}</span>}

                                </span>);
                        }} />
                    {shieldError &&
                        <div
                            style={{
                                color: "orangered",
                                alignItems: "center", justifyContent: "center",
                                display: "flex", width: "100%", height: "100%", position: "absolute",
                                backgroundColor: hexToRgbaWithAlpha("black", 0.25)
                            }}>
                            {shieldError}
                        </div>}
                </div>
            </div>
        </dialog>
    );
}

function UssdMessenger(props) {
    const [ussdSend, setUssdSend] = React.useState("");
    const [error, setError] = React.useState("");
    const timerTick = React.useRef(); // <-- React ref

    const [ussdList, setUssdList] = React.useState([]);
    const [busy, setBusy] = React.useState(false);
    const [ranges] = React.useState(["Dernières 20 minutes", "Dernières 24 heures"]);
    const [range, setRange] = React.useState(ranges[0]);
    const [shieldError, setShieldError] = React.useState(null);

    const updateUssds = () => {
        if (!props.selectedEntry) {
            return;
        }

        const assetId = props?.selectedEntry?.assignedAssetId;
        const interval = range === ranges[1] ? timeSpanFromHours(24) : timeSpanFromMinutes(20);

        fetchUssd(assetId, interval, (e) => {
            if (e.error) {
                console.log(e.error);
                return;
            }

            setUssdList(prev => {
                //var changed = false;
                const currentList = [...prev];
                const incommingList = e.data;

                for (const item of incommingList) {
                    const entry = currentList.find(x => x.id === item.id);
                    if (!entry) {
                        //changed = true;
                        currentList.push(item)
                    }
                }

                //if (!changed) return currentList;
                return currentList.sort((a, b) => new Date(a.added) - new Date(b.added)).reverse();
            });
        });
    }

    const onTimer5s = () => {
        updateUssds();
        console.log("Interval Tick ...")
    }



    React.useEffect(() => {
        setShieldError(!props.selectedEntry ? "Please select an entry" : null);
        if (timerTick.current)
            clearInterval(timerTick.current);
        timerTick.current = setInterval(onTimer5s, 5000);

        return () => {
            clearInterval(timerTick.current);
            console.log("Clear Interval")
        }
    }, [props.selectedEntry, range]);

    React.useEffect(() => {
        if (props.initialCode) {
            setUssdSend(props.initialCode.code)
        }

        return () => {
            clearInterval(timerTick.current);
            setError("")
            setUssdSend("")
        }
    }, []);

    React.useEffect(() => {
        updateUssds();
    }, [range]);
    const awesomeSize = isMobile ? 18 : 14;
    return (
        <dialog open={true}
            style={{
                top: "50%",
                bottom: "50%",
                marginRight: "auto",
                marginLeft: "auto",
                padding: "2px", borderWidth: "1px", borderRadius: "5px", minWidth: "450px"
            }}>
            <div style={{ width: "100%", height: "100%", display: "flex", flexDirection: "row", padding: "5px 0px" }}>
                <div style={{ width: "100%", display: "flex", flex: 1 }}>
                    Account Notification Settings for {props?.account?.name}
                </div>
                <button style={{ backgroundColor: "transparent", borderWidth: "0px", height: "100%" }}
                    onClick={() => {
                        if (props.onClose) {
                            props.onClose();
                        }
                    }}>
                    <div style={{ alignItems: "center", display: "flex", justifyContent: "center" }}>
                        <IconContext.Provider value={{ size: awesomeSize }}>
                            <GrClose />
                        </IconContext.Provider>
                    </div>
                </button>
            </div>
            <div style={{ display: "flex", width: "100%", height: "100%", flexDirection: "column", position: "relative" }}>
                <div style={{ display: "flex", width: "100%", height: "100%" }}>
                    <div style={{ display: "flex", width: "100%", flexFlow: "column", flex: 1 }}>
                        <div style={{ display: "flex", height: "30px", margin: "5px 3px", textAlign: "left" }}>
                            <input style={{ display: "flex", flex: 1, width: "100%", borderColor: "lightgray", textAlign: "center" }}
                                type="text" id="config_creation_feature"
                                placeholder="Enter ussd code sequence, ex: *111#$51" value={ussdSend}
                                autoComplete="off"
                                onChange={(e) => {
                                    setUssdSend(e.target.value)
                                }} />
                            <button
                                disabled={busy}
                                style={{ margin: "0px 0px 0px 5px" }}
                                onClick={() => {
                                    const assetId = props?.selectedEntry?.assignedAssetId;
                                    const data = {
                                        code: ussdSend,
                                        assetId: assetId
                                    };

                                    setBusy(true)
                                    forwardUssdRequest(data, (e) => {
                                        setBusy(false)
                                        if (e.error) {
                                            return;
                                        }

                                        setUssdSend("");
                                    })
                                }}>Send Code</button>
                        </div>
                        <div style={{ display: "flex", margin: "5px 0px", textAlign: "left", width: "100%", position: "relative", overflowY: "auto", flex: 1 }}>
                            <Listbox
                                style={{ position: "absolute" }}
                                dataKey="id"
                                data={ussdList}
                                onChange={(item) => {
                                }}
                                renderItem={({ item }) => {

                                    const time = localTimeFormat(toLocalTime(item.added));
                                    const recent = (new Date() - toLocalTime(item.added)) < (3600 * 1000);
                                    const backgroundColor = recent ? `${hexToRgbaWithAlpha("red", 0.1)}` : undefined;
                                    const lastSeen = recent < 3600 ? ` (${convertTimeSent(item.added)})` : "";
                                    return (
                                        <div style={{ width: "100%", padding: "0px 0px 3px 0px", backgroundColor: backgroundColor }}>
                                            <div style={{ width: "100%", color: "limegreen" }}>{time}{lastSeen}</div>
                                            <span style={{ width: "100%" }}>{item.body}</span>
                                        </div>);
                                }} />
                        </div>
                        <div style={{ display: "flex", height: "30px", margin: "5px 3px", textAlign: "left" }}>
                            <button>
                                Saved codes
                            </button>
                            <div style={{ display: "flex", textAlign: "left", margin: "0px 15px" }}>
                                <span style={{ margin: "0px 5px" }}>Interval</span>
                                <Combobox
                                    style={{ width: "160px" }}
                                    defaultValue={ranges[0]}
                                    data={ranges}
                                    value={range}
                                    onChange={(e) => {
                                        setRange(e)
                                        setUssdList([])
                                    }}
                                    dropUp
                                />
                            </div>
                        </div>
                    </div>
                </div>
                {shieldError &&
                    <div
                        style={{
                            color: "orangered",
                            alignItems: "center", justifyContent: "center",
                            display: "flex", width: "100%", height: "100%", position: "absolute",
                            backgroundColor: hexToRgbaWithAlpha("black", 0.25)
                        }}>
                        {shieldError}
                    </div>}
            </div>
        </dialog>);
};

function UssdKnownCodes(props) {
    const [knownCodes, setKnownCodes] = React.useState([]);
    const [codeAdditionMode, setCodeAdditionMode] = React.useState(false);
    const [codeAdditionData, setCodeAdditionData] = React.useState({});
    const [selectedItem, setSelectedItem] = React.useState(null);
    const [deleteConfirmData, setDeleteConfirmData] = React.useState(null);
    const [editItem, setEditItem] = React.useState(null);
    const [editData, setEditData] = React.useState(null);
    const wrapperRef = React.useRef(null);

    const updateCodes = () => {
        fetchUssdStoredCodes((e) => {
            if (e.error) {
                console.log(e.error);
                return;
            }

            const prev = selectedItem;
            setKnownCodes(e.data)
            if (prev) {
                setSelectedItem(e.data.find(x => x.id === prev.id))
            }
        });
    }

    const clearEditData = () => {
        setEditData(null)
        setEditItem(null)
    }

    const clearAdditionData = () => {
        setCodeAdditionData({});
        setCodeAdditionMode(false)
    }

    React.useEffect(() => {
        updateCodes();
        return () => {
            clearAdditionData()
            clearEditData();
        }
    }, []);

    React.useEffect(() => {
        const handleClickOutside = event => {
            if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
                setCodeAdditionData({});
                setCodeAdditionMode(false)
            }
        };

        document.addEventListener("mouseup", handleClickOutside, false);
        return () => {
            document.removeEventListener("mouseup", handleClickOutside, false);
        };
    }, []);

    const awesomeSize = isMobile ? 10 : 12;
    return (
        <div style={{ display: "flex", width: "100%", height: "100%", flexDirection: "column" }}>
            <div style={{ display: "flex", width: "100%", flexFlow: "column", flex: 1 }}>
                <div style={{ display: "flex", margin: "5px 0px", textAlign: "left", width: "100%", position: "relative", overflowY: "auto", flex: 1 }}>
                    <Listbox
                        style={{ position: "absolute" }}
                        dataKey="id"
                        data={knownCodes}
                        value={selectedItem}
                        onChange={(item) => {
                            setSelectedItem(item)
                        }}
                        renderItem={({ item }) => {
                            const showActions = (selectedItem && selectedItem.id === item.id);
                            const isEdit = (editItem && editItem.id === item.id);
                            return (
                                <span style={{ display: "flex", width: "100%" }}>
                                    {isEdit ?
                                        <input type="text" style={{ width: "30%", margin: "0px 2px" }} value={editData?.description}
                                            onChange={(e) => {
                                                const data = { ...editData }
                                                data.description = e.target.value;
                                                setEditData(data)
                                            }} /> :
                                        <strong style={{ width: "30%" }}>{item?.description}</strong>}
                                    {isEdit ?
                                        <input type="text" style={{ width: "30%", margin: "0px 2px" }} value={editData?.code}
                                            onChange={(e) => {
                                                const data = { ...editData }
                                                data.code = e.target.value;
                                                setEditData(data)
                                            }} /> :
                                        <span style={{ width: "30%"}}>{item.code}</span>}
                                    {isEdit ?
                                        <input type="text" style={{ width: "30%", margin: "0px 2px" }} value={editData?.comment}
                                            onChange={(e) => {
                                                const data = { ...editData }
                                                data.comment = e.target.value;
                                                setEditData(data)
                                            }}/> :
                                        <span style={{ width: "30%"}}>{item.comment}</span>}
                                    {<span style={{ display: "flex", right: "1px", alignItems: "start", backgroundColor: "red" }}>
                                        {showActions && !isEdit &&
                                            <button style={{ margin: "0px 2px 0px 2px" }} onClick={() => {
                                                setEditData({
                                                    code: item.code,
                                                    description: item.description,
                                                    comment: item.comment
                                                });

                                                setEditItem(item)
                                            }}>
                                                <div style={{ alignItems: "center", display: "flex", justifyContent: "center", height: "100%", width: "100%" }}>
                                                    <IconContext.Provider value={{ size: awesomeSize }}>
                                                        <RiEditLine />
                                                    </IconContext.Provider></div>
                                            </button>}
                                        {showActions && !isEdit &&
                                            <button onClick={() => {
                                                setDeleteConfirmData({
                                                    open: true,
                                                    message: `Vous etes sur le point de supprimer le code ${item.description}`,
                                                    title: "Suppression USSD code",
                                                    contact: item
                                                })
                                            }}>
                                                <div style={{ alignItems: "center", display: "flex", justifyContent: "center", height: "100%", width: "100%" }}>
                                                    <IconContext.Provider value={{ size: awesomeSize }}>
                                                        <RiDeleteBin6Line />
                                                    </IconContext.Provider></div>
                                            </button>}
                                        {showActions && isEdit &&
                                            <button style={{ margin: "0px 2px 0px 2px" }} onClick={() => {
                                                clearEditData();
                                            }}>
                                                <div style={{ alignItems: "center", display: "flex", justifyContent: "center", height: "100%", width: "100%" }}>
                                                    <IconContext.Provider value={{ size: awesomeSize }}>
                                                        <GiCancel />
                                                    </IconContext.Provider></div>
                                            </button>}
                                        {showActions && isEdit &&
                                            <button onClick={() => {
                                                const data = { ...editItem };
                                                data.comment = editData.comment;
                                                data.code = editData.code;
                                                data.description = editData.description;
                                                if (!data.id) {
                                                    return;
                                                }

                                                if (checkIfEmptyOrWhiteSpace(data.code) || checkIfEmptyOrWhiteSpace(data.description)) {
                                                    return;
                                                }

                                                fetchUpdateUssdStoredCode(data, (e) => {
                                                    if (e.error) {
                                                        console.log(e.error);
                                                        return;
                                                    }

                                                    ///setCodeAdditionData({});
                                                    ///setCodeAdditionMode(false);
                                                    clearEditData()
                                                    updateCodes();

                                                });
                                            }}>
                                                <div style={{ alignItems: "center", display: "flex", justifyContent: "center", height: "100%", width:"100%" }}>
                                                    <IconContext.Provider value={{ size: awesomeSize }}>
                                                        <GiConfirmed />
                                                    </IconContext.Provider></div>
                                            </button>}
                                    </span>}
                                </span>);
                        }} />
                </div>
            </div>
            {codeAdditionMode ?
                <div style={{
                    display: "flex", height: "30px", margin: "5px 3px", textAlign: "left", backgroundColor: "red", padding: "8px"
                }}
                    ref={wrapperRef}
                >
                    <input type="text" style={{ margin: "0px 2px" }}
                        placeholder="Description"
                        value={codeAdditionData.description}
                        onChange={(e) => {
                            const data = { ...codeAdditionData }
                            data.description = e.target.value;
                            setCodeAdditionData(data)
                        }}></input>
                    <input type="text"
                        style={{ margin: "0px 2px", display: "flex" }}
                        placeholder="Code"
                        value={codeAdditionData.code}
                        onChange={(e) => {
                            const data = { ...codeAdditionData }
                            data.code = e.target.value;
                            setCodeAdditionData(data)
                        }}></input>
                    <input type="text"
                        style={{ margin: "0px 2px", display: "flex", flex: 1 }}
                        placeholder="Comment"
                        value={codeAdditionData.comment}
                        onChange={(e) => {
                            const data = { ...codeAdditionData }
                            data.comment = e.target.value;
                            setCodeAdditionData(data)
                        }}></input>
                    <button
                        onClick={() => {
                            const data = { ...codeAdditionData };
                            if (!data.description || !data.code) {
                                return;
                            }

                            fetchUAddUssdStoredCode(data, (e) => {
                                if (e.error) {
                                    console.log(e.error);
                                    return;
                                }

                                setCodeAdditionData({});
                                setCodeAdditionMode(false);
                                updateCodes();
                            });

                        }}
                    >Confirmer</button>
                </div> : null}
            <div style={{ display: "flex", height: "30px", margin: "5px 3px", textAlign: "left" }}>
                <button
                    onClick={() => {
                        setCodeAdditionMode(true)
                    }}>
                    Ajouter Code
                </button>
                <span style={{ flex: 1 }}></span>
                {selectedItem && <button
                    onClick={() => {
                        if (props.onUseCode) {
                            props.onUseCode(selectedItem)
                        }
                    }}>
                    Utiliser Code
                </button>}
            </div>
            <ConfirmBox
                onCancel={() => {
                    setDeleteConfirmData(null)
                }}
                onConfirm={(item) => {
                    fetchDeleteUssdStoredCode(item.id, (e) => {
                        if (e.error) {
                            console.log(e.error);
                            return;
                        }

                        setDeleteConfirmData(null)
                        updateCodes();

                    })
                }}
                title={deleteConfirmData?.title}
                data={deleteConfirmData?.contact}
                open={deleteConfirmData?.open}
                message={deleteConfirmData?.message} />
        </div>);
};

export default SimcardOperator;