import React from 'react'
import { showLoading } from 'react-global-loading';
import { isMobile } from 'react-device-detect';
import { useNavigate } from "react-router-dom";
import { isLogin } from '../calls/fetchLogin';
import TopBarComponenent from './TopBarComponenent';
import { AppContext } from "../AppContext";
function PageSelector(props) {
    const navigate = useNavigate();
    const [ready, setReady] = React.useState(false);
    const { appThemeColor } = React.useContext(AppContext);
    const mobile = isMobile;
    const barHeight = mobile ? "22px" : "35px";

    const checkIfReady = () => {
        showLoading(true)
        isLogin((e) => {
            showLoading(false)
            if (e.error) {
                navigate("/home", { replace: true });
                return;
            }

            setReady(true);
        })
    }

    const onBackButtonEvent = (e) => {
        e.preventDefault();
        checkIfReady(false);
    }

    React.useEffect(() => {
        checkIfReady();
        window.addEventListener('popstate', onBackButtonEvent);
        return () => {
            window.removeEventListener('popstate', onBackButtonEvent);
        };
    }, []);

    if (!props.ready || !ready) {
        return null;
    }

    return (
        <div style={{ display: "flex", height: "100%", width: "100%", flexDirection: "column" }}>
            <div style={{ width: "100%", height: barHeight, display: "flex", backgroundColor: appThemeColor }}>
                <TopBarComponenent showInfo={true} showScreenControl={true} showNoFullScreen={true} />
            </div>
            <div style={{ height: "100%", display: "flex" }}>
                <div style={{
                    display: "flex", flexDirection: "row", textAlign: "center", height: "400px", width: "400px"
                    , borderWidth: "1px", borderStyle: "solid", padding: "10px", borderRadius: "5px",
                    alignSelf: "center", marginLeft: "auto", marginRight: "auto"
                }}>
                    <button style={{ width: "100%", margin: "5px", fontWeight: "bold", fontSize:"16px" }} onClick={() => {
                        navigate("/administration");
                    } }>
                        Administration
                    </button>
                    <button style={{ width: "100%", margin: "5px", fontWeight: "bold", fontSize: "16px" }} onClick={() => {
                        navigate("/dashboard");
                    } }>
                        Utilisateur
                    </button>
                </div>
            </div>
            <div style={{ height: barHeight }}></div>
        </div>);
}

export default PageSelector;