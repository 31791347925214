import React from 'react'
import { DropdownList } from 'react-widgets';
import fetchRequestUnitUpdate from '../../calls/fetchRequestUnitUpdate';

const formats = [
    { name: "None", value: 0 },
    { name: "Normal", value: 1 },
    { name: "Forced", value: 2 },
    { name: "Restore", value: 3 },
    { name: "UpdateRestart", value: 5 },
    { name: "BreakAndUpdate", value: 6 },
    { name: "DeepBreak", value: 7 },
    { name: "Usb", value: 50 },
];

function UpdaterDialog(props) {
    const [advanced, setAdvanced] = React.useState(false);
    const [filename, setFilename] = React.useState("");
    const [format, setFormat] = React.useState(formats[0]);
    const wrapperRef = React.useRef(null);

    React.useEffect(() => {
        const handleClickOutside = event => {
            if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
                if (props.onClickOutside)
                    props.onClickOutside()
            }
        };

        document.addEventListener("mouseup", handleClickOutside, false);
        return () => {
            document.removeEventListener("mouseup", handleClickOutside, false);
        };
    }, []);

    return <dialog open={props.open}
        focusout={() => {
        console.log("");
    }} style={{ position: "fixed", right: 0, left: 0, top: 0, bottom: 0, padding: "5px", minWidth:"280px", minHeight:"80px" }}>
        <div ref={wrapperRef} style={{ display: "flex", flexDirection: "column", width: "100%" }}>
            <span>{props.asset.name} updater</span>
            <span style={{ margin: "2px 0px" }} />
            <div style={{ backgroundColor: 'lightgray', height: '2px', }} />
            <span style={{ margin: "2px 0px" }} />
            {advanced && <div style={{ display: "flex", flexDirection: "column" }}>
                <div style={{ display: "flex", flexDirection: "row" }}>
                    <span style={{ minWidth: "70px", textAlign: "left" }}>File</span>
                    <input type="text" value={filename} onChange={(e) => { setFilename(e.target.value) }} />
                </div>
                <span style={{ margin: "2px 0px" }} />
                <div style={{ display: "flex", flexDirection: "row" }}>
                    <span style={{ minWidth: "70px", textAlign: "left" }}>Format</span>
                    <DropdownList
                        readOnly={false}
                        //dropUp
                        textField="name"
                        defaultValue={formats[0]}
                        data={formats}
                        value={format}
                        filter={false}
                        onChange={(e) => {
                            setFormat(e)
                        }}
                        autoFocus={false}
                    />
                </div>
                <span style={{ margin: "2px 0px" }} />
                <button onClick={() => {
                    const updateData = {
                        assetId: props?.asset?.id,
                        filename: filename,
                        formatMode: format.value
                    };

                    fetchRequestUnitUpdate((e) => {
                        if (e.error) return;
                    }, updateData)
                }}>
                    update
                </button>
                <span style={{ margin: "2px 0px" }} />
                <div style={{ backgroundColor: 'lightgray', height: '2px', }} />
                <span style={{ margin: "2px 0px" }} />

                <button onClick={() => {
                    setAdvanced(false)
                }}>
                    less options
                </button>
            </div>}
            {!advanced && <div style={{ display: "flex", flexDirection: "column" }}>
                <button onClick={() => {
                    const updateData = {
                        assetId: props?.asset?.id
                    };

                    fetchRequestUnitUpdate((e) => {
                        if (e.error) return;
                    }, updateData)
                }}>
                    update to next version
                </button>
                <span style={{ margin: "2px 0px" }} />
                <button onClick={() => {
                    setAdvanced(true)
                }}>
                    avanced options
                </button>
            </div>}
        </div>
    </dialog>;
}

export default UpdaterDialog;