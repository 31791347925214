import React from 'react'
import { checkIfEmptyOrWhiteSpace } from "../../utilities/stringHelper";
import createUnit from "../../calls/administration/createUnit";

function UnitCreation(props) {
    const [serial, setSerial] = React.useState("");
    const [hwEdition, setHwEdition] = React.useState("");
    const [error, setError] = React.useState("");

    React.useEffect(() => {
        setError("")
        setSerial("")
    }, []);

    return <dialog open={props.open}
        style={{
            top: "50%",
            bottom: "50%",
            marginRight: "auto",
            marginLeft: "auto",
            padding: "8px", borderWidth: "1px", borderRadius: "5px", minWidth: "450px", height: "200px"
        }}>
        <div>
            <div style={{textAlign:"right"}}>
                Unit creation
            </div>
            <hr/>
            <div style={{ display: "flex", margin: "5px 0px",textAlign:"left" }}>
                <label style={{ display: "block", width: "140px" }} htmlFor="unit_creation_serial">Numero serie</label>
                <input style={{ display: "block", width: "100%" }} type="text" id="unit_creation_serial" placeholder="Numero serie" value={serial}
                    autoComplete="off"
                    onChange={(e) => {
                        setSerial(e.target.value)
                    }} />
            </div>
            <div style={{ display: "flex", margin: "5px 0px", textAlign: "left" }}>
                <label style={{ display: "block", width: "140px" }} htmlFor="unit_creation_hw_edition">Hardware Edition</label>
                <input style={{ display: "block", width: "100%" }} type="text" id="unit_creation_hw_edition" placeholder="Hardware Edition" value={hwEdition}
                    autoComplete="off"
                    onChange={(e) => {
                        setHwEdition(e.target.value)
                    }} />
            </div>

            {checkIfEmptyOrWhiteSpace(error) ? null : (<div style={{ display: "flex", color: "red", fontWeight: "bold", fontSize: "8" }}>
                {error}
            </div>)}
            <div style={{ textAlign: "left", display: "flex", flexDirection: "row", margin: "10px 0px 0px 0px" }}>
                <button style={{
                    width: "100px", backgroundColor: "whitesmoke", color: "black", borderRadius: "2px", height: "100%",
                    borderWidth: "2px", margin: "5px 0px 1px 0px", borderColor: 'lightgray', cursor: "pointer"
                }} onClick={() => {
                    if (checkIfEmptyOrWhiteSpace(serial)) {
                        setError("Username cannot be empty")
                        return;
                    }

                    const data = {
                        serial: serial,
                        hwEdition: hwEdition,
                        comment:"New life"
                    };

                    createUnit(data, (e) => {
                        if (e.error) {
                            setError(e.error)
                            return;
                        }

                        if (!props?.onUnitCreated) {
                            return;
                        }

                        props?.onUnitCreated(e.data)
                    });
                }}>confirmer</button>
                <span style={{ flexGrow: 1, display: "flex" }}></span>
                <button style={{
                    width: "100px", backgroundColor: "whitesmoke", color: "black", height: "100%", borderRadius: "2px",
                    borderWidth: "2px", margin: "5px 0px 1px 0px", borderColor: 'lightgray', cursor: "pointer"
                }} onClick={() => {
                    if (!props?.onCancel) {
                        return;
                    }
                    props.onCancel();
                }}>Annuler</button>
            </div>
        </div>
    </dialog>
}

export default UnitCreation;