import React from 'react'
import { AppContext } from "../AppContext";
import { showLoading } from 'react-global-loading';
import logo from '../images/logo.svg';
import flag from '../images/flag.svg';
import { IconContext } from "react-icons";
import { isMobile } from 'react-device-detect';
import { FcRefresh } from "react-icons/fc";
import { FcInfo, FcHome, FcMultipleInputs } from "react-icons/fc";
import { FaUser } from "react-icons/fa";
import { useNavigate, Link } from "react-router-dom";


import { RxEnterFullScreen, RxExitFullScreen } from "react-icons/rx";

function TopBarComponenent(props) {
    const { busy, setBusy } = React.useContext(AppContext);
    const { portrait } = React.useContext(AppContext);
    const { appThemeColor } = React.useContext(AppContext);
    const { fullScreenHandle } = React.useContext(AppContext);
    const { isFullScreen } = React.useContext(AppContext);
    const { loggedIn } = React.useContext(AppContext);
    const { setShowAbout } = React.useContext(AppContext);

    const [userMenuOpen, setUserMenuOpen] = React.useState(false);
    const [mouseOverUserMenuButton, setMouseOverUserMenuButton] = React.useState(false);

    const copyright = "Powered by Chapresoft ©2023";

    const mobile = isMobile;
    const appTitle = mobile ? `Webtrace Mobile CD` : `Webtrace CD`;
    const flagSize = mobile ? (portrait ? "10px" : "12px") : "18px";
    const textFontSize = mobile ? (portrait ? "8px" : "12px") : "14px";
    //const versionFontSize = mobile ? (portrait ? "8px" : "12px") : "14px";
    const awesomeSize = mobile ? 12 : 16;


    const refresh = () => {
        setBusy(true);
        showLoading(true);
        window.location.reload(false);
    }

    React.useEffect(() => {
        return () => {
            setShowAbout(false)
        }
    }, [])

    const renderFullScreenButtons = () => {
        if (isFullScreen) {
            return (<button style={{ backgroundColor: "transparent", height: "100%" }} onClick={fullScreenHandle.exit}>
                <div style={{ alignItems: "center", display: "flex", justifyContent: "center" }}>
                    <IconContext.Provider value={{ size: awesomeSize, color:'whitesmoke'}}>
                        <RxExitFullScreen />
                    </IconContext.Provider>
                </div>
            </button>);
        }
        else {
            if (props.showNoFullScreen) {
                return null;
            }

            return (
                <button style={{ backgroundColor: "transparent", height: "100%" }} onClick={fullScreenHandle.enter}>
                    <div style={{ alignItems: "center", display: "flex", justifyContent: "center" }}>
                        <IconContext.Provider value={{ size: awesomeSize, color: 'whitesmoke' }}>
                            <RxEnterFullScreen />
                        </IconContext.Provider>
                    </div>
                </button>);
        }
    }

    const styler = loggedIn ? (<style>
        {`svg path {stroke: green}`}
    </style>) : null;
    return (
        <div style={{ backgroundColor: appThemeColor, display: "flex", width: "100%", height: "100%", justifyContent: "space-evenly" }}>
            <div style={{ alignItems: "center", display: "flex", justifyContent: "left", width: "100%" }} >
                <img src={logo} alt="logo" style={{ width: flagSize, height: flagSize, margin: "5px 5px" }} />
                <span style={{ color: "#e1e1dd", textAlign: "center", fontSize: textFontSize, alignSelf: "center" }}>{appTitle}</span>
            </div>
            <div style={{ alignItems: "center", display: "flex", width: "100%", justifyContent: "center" }}>
                <span style={{ color: "#e1e1dd", textAlign: "center", fontSize: textFontSize }}>{copyright}</span>
            </div>
            <div style={{ alignItems: "center", display: "flex", justifyContent: "right", width: "100%" }}>
                {
                    props.userMenuControl ?
                        (<div style={{ marginRight: "50px" }}>
                            <button
                                onMouseLeave={() => {
                                    setMouseOverUserMenuButton(false);
                                }}
                                onMouseEnter={() => {
                                    setMouseOverUserMenuButton(true);
                                }}
                                onClick={() => {
                                    setUserMenuOpen(!userMenuOpen)
                                }}
                                style={{ borderRadius: "3px", backgroundColor: "transparent" }}>
                                <span style={{ alignItems: "center", display: "flex", justifyContent: "center" }}>
                                    {styler}
                                    <IconContext.Provider value={{ size: awesomeSize, title: "Settings" }}>
                                        <FaUser />
                                    </IconContext.Provider>
                                    <strong style={{ margin: "0px,5px" }}>{props.user?.username}</strong>
                                </span>
                            </button>
                            {
                                userMenuOpen?
                                    (<UserDropdownMenu
                                        onMouseClickOutside={() => {
                                            setUserMenuOpen(false)
                                        }}

                                        showUserMenuAdministration={props.showUserMenuAdministration}
                                        showUserMenuDashboard={props.showUserMenuDashboard}
                                        mouseOverButon={mouseOverUserMenuButton}
                                    />)
                                    : null
                            }
                        </div>) :
                        null
                }
                {
                    props.showHome ?
                        (<button style={{
                            backgroundColor: "transparent", height: "100%", borderRadius: "3px"
                        }} onClick={() => {
                            if (props.onShowHome) {
                                props.onShowHome()
                            }
                        }}>
                            <div style={{ alignItems: "center", display: "flex", justifyContent: "center" }}>
                                <IconContext.Provider value={{ size: awesomeSize }}>
                                    <FcHome />
                                </IconContext.Provider>
                            </div>
                        </button>) :
                        null
                }
                {
                    props.showScreenControl ?
                        renderFullScreenButtons() :
                        null
                }
                {
                    props.showInfo ?
                        (<button style={{
                            backgroundColor: "transparent", height: "100%", borderRadius: "3px"
                        }} onClick={() => {
                            setShowAbout(true)
                        }}>
                            <div style={{ alignItems: "center", display: "flex", justifyContent: "center" }}>
                                <IconContext.Provider value={{ size: awesomeSize }}>
                                    <FcInfo />
                                </IconContext.Provider>
                            </div>
                        </button>) :
                        null
                }
                {
                    props.showRefresh ?
                        (<button style={{
                            backgroundColor: "transparent", height: "100%", borderRadius: "3px"
                        }} onClick={refresh}>
                            <div style={{ alignItems: "center", display: "flex", justifyContent: "center" }}>
                                <IconContext.Provider value={{ size: awesomeSize  }}>
                                    <FcRefresh />
                                </IconContext.Provider>
                            </div>
                        </button>) :
                        null
                }
                {
                    props.showFullQuery ?
                        (<button style={{
                            backgroundColor: "transparent", height: "100%", borderRadius: "3px"
                        }} onClick={() => {
                            if (props.onShowFullQuery) {
                                props.onShowFullQuery()
                            }
                        }}>
                            <div style={{ alignItems: "center", display: "flex", justifyContent: "center" }}>
                                <IconContext.Provider value={{ size: awesomeSize }}>
                                    <FcMultipleInputs />
                                </IconContext.Provider>
                            </div>
                        </button>) :
                        null
                }
                <img src={flag} alt="logo" style={{ width: flagSize, height: flagSize, alignSelf: "center", margin: "5px 5px" }} />
            </div>
        </div>);
}

function UserDropdownMenu(props) {
    const navigate = useNavigate();

    const { activeRoles } = React.useContext(AppContext);
    const { authorisationToken } = React.useContext(AppContext);
    const { appThemeColor } = React.useContext(AppContext);
    const admin = activeRoles.includes("root") || activeRoles.includes("admin") || activeRoles.includes("support");
    const mouseIn = React.useRef(false);
    const parentMouseIn = React.useRef(false);
    React.useEffect(() => {
        parentMouseIn.current = props.mouseOverButon;
    }, [props.mouseOverButon]);

    React.useEffect(() => {
        const onMouseDown = (e) => {
            if (!mouseIn.current && !parentMouseIn.current) {
                if (props.onMouseClickOutside)
                    props.onMouseClickOutside()
            }
        }

        window.addEventListener('mousedown', onMouseDown);
        return () => {
            window.removeEventListener('mousedown', onMouseDown);
        }
    }, []);

    return (<ul
        onMouseLeave={() => {
            mouseIn.current = false;
        }}
        onMouseEnter={() => {
            mouseIn.current = true;
        }}
        style={{
            position: "absolute", top: "10px", borderRadius: "4px", borderWidth: "2px", borderStyle: "solid",
            borderColor: appThemeColor, backgroundColor: "white", right: "172px",
            listStyleType: "none", padding: "10px 10px", width: "150px", zIndex: 1
        }}>
        {
            admin && props.showUserMenuDashboard ?
                (<li>

                    <button style={{ width: "100%" }} onClick={() => {
                        navigate("/dashboard")
                    }}>Dashboard</button>
                </li>) :
                null
        }
        {
            admin && props.showUserMenuAdministration ?
                (<li>

                    <button style={{ width: "100%" }} onClick={() => {
                        navigate("/administration")
                    }}>Administration</button>
                </li>) : null
        }
        <hr />
        {
            authorisationToken ?
                (<li>

                    <button style={{ width: "100%" }} onClick={() => {
                        navigate("/logout", { replace: true })
                    }}>Deconnexion</button>
                </li>) : null
        }
    </ul>);
}

export default TopBarComponenent;