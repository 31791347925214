import React from 'react'
import { useContext } from 'react';
import { AppContext } from "../AppContext";
import { showLoading } from 'react-global-loading';
import { isMobile } from 'react-device-detect';
import { login, isLogin } from '../calls/fetchLogin';
import { checkIfEmptyOrWhiteSpace } from '../utilities/stringHelper'; 
import { useNavigate } from "react-router-dom";
import { setConfigProperty } from "../configurations/appConfig"
import TopBarComponenent from './TopBarComponenent';
import { Link } from 'react-router-dom';

function Home(props) {
    const navigate = useNavigate();
    const { busy, setBusy } = useContext(AppContext);
    const { portrait } = React.useContext(AppContext);
    const { appThemeColor } = React.useContext(AppContext);
    const { setAuthorisationToken } = React.useContext(AppContext);
    const { setActiveUser } = React.useContext(AppContext);
    const { setActiveRoles } = React.useContext(AppContext);

    const [username, setUsername] = React.useState("");
    const [password, setPassword] = React.useState("");
    const [error, setError] = React.useState("");
    const mobile = isMobile;
    const title = "Identification";
    const titleFontSize = mobile ? "12px" : "16px";
    const barHeight = mobile ? "22px" : "35px";

    const loginUser = (username, password) => {
        const loginData = {
            name: username,
            password: password,
            remember: true,
            tag: "yoyo"
        }

        showLoading(true);
        setBusy(true)
        setError(null);
        login(loginData, (e) => {
            showLoading(false);
            setBusy(false)
            if (e.error) {
                setError(e.error.toString());
                return;
            }

            if (e.data.token) {
                //setAuthorisationToken(e.data.token);
                setAuthorisationToken(e.data.token);
                setConfigProperty("authorisationToken", e.data.token);
                setActiveUser(e.data.user);
                var roles = e.data.roles

                if (roles) {
                    const normalisedRoles = roles.map(element => {
                        return element.toLowerCase();
                    });

                    setActiveRoles(normalisedRoles);
                    if (normalisedRoles.includes("root") ||
                        normalisedRoles.includes("admin") ||
                        normalisedRoles.includes("support")) {
                        navigate("/selector", { replace: true });
                        return;
                    }
                }

                navigate("/dashboard", { replace: true });
            }
        })
    }

    React.useEffect(() => {
        if (props.ready) {
            setBusy(false);
            showLoading(false);
        }
    }, []);

    React.useEffect(() => {
        if (props.ready) {
            setBusy(false);
            showLoading(false);
        }
    }, [props.ready]);

    if (!props.ready)
        return null;

    const noError = checkIfEmptyOrWhiteSpace(error);
    const canConfirm = !checkIfEmptyOrWhiteSpace(username) && !checkIfEmptyOrWhiteSpace(password);
    return (
        <div style={{ display: "flex", height: "100%", width: "100%", flexDirection: "column" }}>
            <div style={{ width: "100%", height: barHeight, display: "flex", backgroundColor: appThemeColor }}>
                <TopBarComponenent showInfo={true} showScreenControl={true} showNoFullScreen={true } />
            </div>
            <div style={{height: "100%", display:"flex" }}>
                <div style={{
                    display: "flex", flexDirection: "column", textAlign: "left", height: "auto", width: "400px"
                    , borderWidth: "1px", borderStyle: "solid", padding: "15px", borderRadius: "5px",
                    alignSelf: "center", marginLeft: "auto", marginRight: "auto",
                    borderColor:"lightgray"
                }}>
                    <div style={{ margin: "0px 0px 10px 0px", padding:"3px", color:"darkgray", height: "15px", textAlign:"right", fontWeight: "bold", fontSize: titleFontSize }}>{title}</div>
                    <hr/>
                    <div style={{ display: "flex", margin: "5px 0px" }}>
                        <label style={{ display: "block", width: "140px" }} htmlFor="login_username">Utilisateur</label>
                        <input id="login_username" type="text" value={username} placeholder="Nom d'utilisateur" style={{ width: "100%" }}
                            onKeyUp={(e) => {
                                if (e.key === 'Enter') {
                                    if (!busy && username && password) {
                                        loginUser(username, password);
                                    }
                                }
                            }}
                            onChange={(e) => {
                                setError(null)
                                setUsername(e.target.value)
                            }} />
                    </div>
                    <div style={{ display: "flex", margin: "5px 0px" }}>
                        <label style={{ display: "block", width: "140px" }} htmlFor="login_password">Password</label>
                        <input type="password" id="login_password" placeholder="Mot de passe" value={password} style={{ width: "100%" }}
                            onKeyUp={(e) => {
                                if (e.key === 'Enter') {
                                    if (!busy && username && password) {
                                        loginUser(username, password);
                                    }
                                }
                            }}
                            onChange={(e) => {
                                setError(null)
                                setPassword(e.target.value)
                            }} />
                    </div>{noError ? null : (<div style={{ display: "flex", color: "red", fontWeight:"bold", fontSize:"8" }}>
                        {error}
                    </div>)}

                    <div style={{ textAlign: "left", display: "flex", flexDirection: "row", margin: "10px 0px 0px 0px" }}>
                        <button
                            disabled={!canConfirm }
                            style={{
                                width: "100%",
                                backgroundColor: "white",
                                color: "black", borderRadius: "3px",
                                height: "100%", margin: "5px 0px 1px 0px",
                                borderColor: 'lightgray', cursor: "pointer",
                                padding: "5px 3px"
                            
                        }} onClick={() => {
                            loginUser(username, password);
                        }}>Connexion</button>
                    </div>
                </div>
            </div>
            <div  style={{display: "flex", textAlign: "center", height: "auto", width: "200px",
                alignSelf: "end", marginLeft: "auto", marginRight: "auto",
            }}>
                <Link
                    to='/demo'
                    style={{
                        width: "100%",  height: "auto",
                        margin: "0px 0px 10px 0px"

                    }} onClick={() => {
                        navigate("/demo");
                    }}>Voir la demo</Link>
            </div>
            <div style={{ height: barHeight }}></div>
        </div>);
}

export default Home;