import React from 'react'

function TimeDisplay(props) {
    const [dateTime, setDateTime] = React.useState(new Date());

    const onTimeInterval = () => {
        console.log("Interval Tick ...")
        setDateTime(new Date())
    }

    React.useEffect(() => {
        //var language = window.navigator.userLanguage || window.navigator.language;
        //alert(language); 
        const interval = setInterval(onTimeInterval, 1000);
        return () => {
            clearInterval(interval);
            console.log("Clear Interval")
        }
    }, []);

    const date = dateTime.toLocaleString("fr", { dateStyle: "long" });
    const time = dateTime.toLocaleString("fr", { timeStyle: "short" });
    return (
        <div style={{ width: "100%", height:"100%", position: "absolute",alignItems:"center", justifyContent: "center", display:"flex" }}>
            <span style={{ color: "whitesmoke", fontSize: 14, margin: "0px 5px" }}>
                {date}
            </span>
            <span style={{ color: "whitesmoke", fontSize: 14, margin: "0px 5px" }}>
                {time}
            </span>
        </div>);
};

export default TimeDisplay;