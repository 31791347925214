import React from 'react'
import { IconContext } from "react-icons";

import { FcUp, FcDown } from "react-icons/fc";
import { ImArrowUp, ImArrowDown } from "react-icons/im";
import { AppContext } from "../../AppContext";
import { isMobile } from 'react-device-detect';

function GridBar() {
    const { gridBarData, setGridBarData } = React.useContext(AppContext);
    const { appThemeColor } = React.useContext(AppContext);
    const mobile = isMobile;
    const awesomeSize = mobile ? 16 : 14;

    const expanded = gridBarData?.expanded;
    const renderExpandButton = () => {
        if (!mobile) {
            return null;
        }

        return (
            <button style={{
                backgroundColor: "transparent",
                borderWidth: "0px",
                height: "100%",
                padding: "5px"
            }}
                onClick={() => {
                    const copy = { ...gridBarData };
                    copy.expanded = !copy.expanded;
                    setGridBarData(copy);
                }}>
                <div style={{ alignItems: "center", display: "flex", justifyContent: "center" }}>
                    <IconContext.Provider value={{ size: awesomeSize , color: appThemeColor }}>
                        {expanded ? <ImArrowDown /> : <ImArrowUp />}
                    </IconContext.Provider>
                </div>
            </button>);
    }

    return (
        <div style={{ Height: "20px", width: "100%", justifyItems: "left", display: "flex", backgroundColor:"whitesmoke" }}>
            {renderExpandButton()}
        </div>
    );
}

export default GridBar;