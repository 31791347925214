import { localTimeFormat } from '../../utilities/timeHelper'
import React from 'react'
import DataGrid, { Row } from 'react-data-grid';
import fetchTwilioRequests from "../../calls/administration/fetchTwilioRequests";
import { showLoading } from 'react-global-loading';
import { truncate } from '../../utilities/stringHelper'


const getColumns = () => {
    return [
        {
            key: 'to',
            name: "to"
        },
        {
            key: 'body',
            name: "body",
            renderCell: ({row }) => {
                return truncate(row.body , 50)
            }
        },
        {
            key: 'status',
            name: "status"
        },
        {
            key: 'dateSent',
            name: "dateSent",
            renderCell: ({ row }) => {
                return localTimeFormat(row.dateSent)
            }

        }]
};

function rowKeyGetter(row) { return row.id; }

function TwilioListView(props) {
    const [selectedUnit, setSelectedUnit] = React.useState(null);
    const [columns] = React.useState(getColumns);
    const [selectedGridRows, setSelectedGridRows] = React.useState(new Set([]));
    const [rows, setRows] = React.useState([]);
    const [busy, setBusy] = React.useState(false);

    const updateTwilioRequests = (onComplete, silent = true) => {
        if (!silent) {
            setBusy(true);
            showLoading(true);
        }

        let maxDate = new Date();
        let minDate = new Date(0);
        fetchTwilioRequests((e) => {

            if (!silent) {
                setBusy(false);
                showLoading(false);
            }
            if (e.error) {
                if (onComplete) onComplete(false);
                return;
            }
            //{id: 1, direction: 2, from: '+15044147554', to: '+243821232175', messageSid: 'SMe537133025ee47cdb8ca3090f986f4fa', �}
            const result = [];
            if (e.data) {

                for (var request of e.data.requests) {
                    result.push({
                        id: request.id,
                        body:request.body,
                        //date: localTimeFormat(toLocalTime(activity.date)),
                        direction: request?.direction,
                        from: request?.from,
                        to: request.to,
                        messageSid: request.messageSid,
                        status: request.status,
                        dateSent: request.dateSent,
                        request: request
                    });
                }

                setRows(result)
            }

            if (onComplete) onComplete(true);
        }, minDate, maxDate);
    }

    React.useEffect(() => {
        updateTwilioRequests(null, false);
        return () => {
        };
    }, []);

    const RowRenderer = (key, props) => <Row {...props} selectedCellIdx={undefined} />
    if (busy) {
        return null;
    }

    return (
        <div style={{
            width: "100%", height: "100%", display: "flex", overflow: "auto", flexDirection: "column"
        }} >

            <div style={{ width: "100%", height: "100%", display: "flex", overflow: "auto", position: "relative", flex: 1 }}>
                <DataGrid
                    defaultColumnOptions={{ sortable: true, resizable: false }}
                    rowHeight={30}
                    columns={columns}
                    rows={rows}
                    rowKeyGetter={rowKeyGetter}
                    className='rdg-light'
                    onSelectedRowsChange={setSelectedGridRows}
                    selectedRows={selectedGridRows}
                    onRowsChange={setRows}
                    enableVirtualization={true}
                    style={{ width: "100%", height: "calc(100% - 5px)", position: "absolute" }}
                    renderers={{ renderRow: RowRenderer }}
                    onCellClick={(args, event) => {
                        const rowId = args.row.id;
                        setSelectedUnit(args.row.request)
                        var set = new Set([rowId]);
                        setSelectedGridRows(set);

                        //const selection = rows.find(x => x.id === args.row.id);
                        //setSelectedEntry(selection)
                    }} />
            </div>
            <div style={{ height: "30px", width: "100%", display: "flex", padding: "10px 0px" }}>
                <button style={{ padding: "2px", width: "80px", margin: "0px 5px" }}
                    disabled={!selectedUnit}
                    onClick={() => {
                        if (selectedUnit && props.onUnitConfigs) {
                            props.onUnitConfigs(selectedUnit)
                        }
                    }}>
                    Configs
                </button>
                <button style={{ padding: "2px", width: "80px", margin: "0px 5px" }}
                    disabled={!selectedUnit}
                    onClick={() => {
                        if (selectedUnit && props.onEditUnit) {
                            props.onEditUnit(selectedUnit)
                        }
                    }}>
                    Edit
                </button>
                <button style={{ padding: "2px", width: "80px", margin: "0px 5px" }}
                    disabled={!selectedUnit}
                    onClick={() => {
                        if (selectedUnit && props.onDeleteEdit) {
                            props.onDeleteEdit(selectedUnit)
                        }
                    }}>
                    Delete
                </button>
            </div>
        </div>);
}

export default TwilioListView;