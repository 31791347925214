
String.prototype.format = function () {
    // Storing arguments into an array
    var args = arguments;

    // Using replace for iterating over the string
    // Select the match and check whether related arguments are present. 
    // If yes, then replace the match with the argument.

    return this.replace(/{([0-9]+)}/g, function (match, index) {
        // checking whether the argument is present
        return typeof args[index] == 'undefined' ? match : args[index];
    });
};
const lang = {}
function loadLanguage(language) {
    import('./lang.json')
        .then(data => {
            lang.language = data[language];
        })
        .catch(error => console.log('Error!'));
}

export { lang, loadLanguage };