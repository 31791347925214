import React from 'react'
import { useEffect, useContext } from 'react';
import dateFormat from "dateformat";

import GoogleMapComponent from './GoogleMapComponent';
import TimeFrameComponent from './TimeFrameComponent';
import EventsGridComponent from './EventsGridComponent';
import UserPanelComponent from './UserPanelComponent';
import TopBarComponenent from './TopBarComponenent';
import ContactsDialog from './dialogs/ContactsDialog';
import LoginDialog from './dialogs/LoginDialog';
import SettingsDialog from './dialogs/SettingsDialog';
import TimeDisplay from './TimeDisplay';
import fetchRequestCurrentPosition from '../calls/fetchRequestCurrentPosition'

import NoInternet from './dialogs/NoInternet';
import AccountBar from './toolbars/AccountBar';
import GridBar from './toolbars/GridBar';

import fetchLastPositions from '../calls/fetchLastPositions';
import fetchPositions from '../calls/fetchPositions';
import { fetchActiveAccountTree } from '../calls/fetchActiveAccount';
import { login, isLogin } from '../calls/fetchLogin';
import fetchTest from '../calls/fetchTest';
import fetchActiveAssets from '../calls/fetchActiveAssets'


import { convertTimeSent, toLocalTime } from '../utilities/timeHelper'
import { getGoogleMapLocationsCenter } from '../utilities/googleMapHelper'
import { AppContext } from "../AppContext";
import { showLoading } from 'react-global-loading';

import { lang } from '../language/lang';

import { isMobile } from 'react-device-detect';
import assetProvider from '../tools/assetTypeProvider';
import { useNavigate } from "react-router-dom";
import UpdaterDialog from './dialogs/UpdaterDialog';
import { useSearchParams } from 'react-router-dom';
//import { localTimeFormat, convertTimeSent, toLocalTime, nowUct } from "../utilities/timeHelper"

function WebtraceDashboard(props) {
    const navigate = useNavigate();
    //const { map } = useContext(AppContext);
    const { busy, setBusy } = useContext(AppContext);
    const { accountBarData } = React.useContext(AppContext);
    const { gridBarData } = React.useContext(AppContext);
    const { setSelectionWarning } = React.useContext(AppContext);
    const { setGridSelectedRowId } = React.useContext(AppContext);

    const { loginOpen, setLoginOpen } = React.useContext(AppContext);
    const { loggedIn, setLoggedIn } = React.useContext(AppContext);
    const { settingActive, setSettingActive } = React.useContext(AppContext);

    const { setAuthorisationToken } = React.useContext(AppContext);
    const { activeUser } = React.useContext(AppContext);
    const { appThemeColor } = useContext(AppContext);

    const [zoom, setZoom] = React.useState(15);

    const [mapData, setMapData] = React.useState({});
    const [eventsGridData, setEventsGridData] = React.useState([]);
    const [selectedAsset, setSelectedAsset] = React.useState(null);
    const [selectedAccount, setSelectedAccount] = React.useState(null);

    const [userActionResizeData, setUserActionResizeData] = React.useState({ width: 250 });
    const [gridResizeData, setGridResizeData] = React.useState({ height: 160 });

    const [activeAccounts, setActiveAccounts] = React.useState([]);
    const [activeAssets, setActiveAssets] = React.useState([]);
    const [ready, setReady] = React.useState(false);
    const { isFullScreen } = React.useContext(AppContext);

    const [setShowAbout] = React.useState(false);
    const [updaterData, setUpdaterData] = React.useState(null);
    const eventGridRef = React.useRef(null);

    const dataGridEventsGridRef = React.useRef(null);
    const googleMapCompRef = React.useRef(null);
    const timeFramePanelRef = React.useRef(null);
    const [searchParams, setSearchParams] = useSearchParams();

    const ensureGoogleLoaded = () => {
        return new Promise(waitForGoogle);
        function waitForGoogle(resolve, reject) {
            if (window.google && window.google.maps) {
                resolve(window.google.maps);
            }
            else {
                setTimeout(waitForGoogle.bind(this, resolve, reject), 100);
            } 
        }
    }

    const retrieveLastPositions = (assetId) => {
        setBusy(true);
        showLoading(true);
        fetchLastPositions(assetId, (e) => {
            showLoading(false);
            setBusy(false);

            if (e.error) {
                return;
            }

            console.log(e.data);
            const userError = e.data.userError;
            if (userError) {
                let m = { ...mapData };
                m.userError = userError;
                m.markers = [];
                m.pathCoordinates = [];
                setMapData(m);
                setEventsGridData([]);
                return;
            }


            const p = e.data.position;
            const ev = e.data.event;
            const asset = e.data.asset;
            var time = toLocalTime(p.timeSent);

            var date = dateFormat(time, "dd mmm yyyy");
            var hour = dateFormat(time, "HH:MM:ss");

            const gridEvent = {
                key: p.id,
                date: date,
                hour: hour,
                asset: asset.name,
                assetType: asset.assetType,
                assetTypeIconColor: asset.assetTypeIconColor,
                event: ev?.description,
                heading: p.heading,
                speed: p.speed,
                location: p.locationText,
                state: p.speed > 0? lang.language.moving : lang.language.stationary,
                timesent: convertTimeSent(p.timeSent),
                timeIndex: time.getTime(),
                idx: 1,
                id: p.id,
                data: e.data,
                assetData: asset
            };

            const marker = {
                key: p.id,
                id: p.id,
                assetData: asset,
                positionData: p,
                eventData: ev,
                position: { lat: e.data.position.latitude, lng: e.data.position.longitude },
                icon: {
                    url: assetProvider.getPreloadedAssetType(asset.id),
                    size: new window.google.maps.Size(25, 25),
                    origin: new window.google.maps.Point(0, 0),
                    scaledSize: new window.google.maps.Size(25, 25)
                },
                title: `${p.locationText}\n${date}, ${hour}\n${p.latitude}, ${p.longitude}`,
                onClick: () => {
                    const map = googleMapCompRef.current.getInternalMap();
                    //setMakerSelectedId(p.id);
                    googleMapCompRef.current.setMarkerSelection({
                        id: p.id
                    });
                    setGridSelectedRowId(p.id);
                    dataGridEventsGridRef.current.scrollToRow(gridEvent?.idx ?? 0);
                    //setGridScrollIndex(gridEvent.idx);
                    if (map) {
                        map.panTo(marker.position);
                    }
                }
            };

            const coordinate = { lat: p.latitude, lng: p.longitude, id: p.id };
            const positionCenter = { lat: e.data.position.latitude, lng: e.data.position.longitude };

            setEventsGridData([gridEvent]);
            setMapData({
                center: positionCenter,
                markers: [marker],
                pathCoordinates: [coordinate],
            });
        })
    };

    const retrievePositions = (assetId, range) => {
        setBusy(true);
        showLoading(true);
        fetchPositions(assetId, range.start, range.end, (e) => {
            showLoading(false);
            setBusy(false);
            if (e.error) {
                return;
            }

            console.info(e.data);
            const userError = e.data.userError;
            if (userError) {
                const m = { ...mapData };
                m.userError = userError;
                m.markers = [];
                m.pathCoordinates = [];
                setEventsGridData([]);
                setMapData(m);
                return;
            }

            const positions = e.data.positions;
            const assets = e.data.assets;
            const fuelLevels = e.data.fuelLevels;
            const events = e.data.events;
            const asset = assets.length > 0 ? assets[0] : null;
            if (!asset) {
                return;
            }

            const gridEvents = [];
            const markers = [];
            const pathCoordinates = [];
            for (let p of positions) {
                var ev = events.find(x => x.id === p.eventId);
                var time = toLocalTime(p.timeSent);
                var date = dateFormat(time, "dd mmm yyyy");
                var hour = dateFormat(time, "HH:MM:ss");
                var state = p.speed > 0 ? lang.language.moving : lang.language.stationary;
                const fuel = fuelLevels?.find(x => x.positionId === p.id);
                const timeIndex = time.getTime();
                const gridEvent = {
                    key: p.id,
                    date: date,
                    hour: hour,
                    asset: asset.name,
                    event: fuel ? `${ev?.description} (${fuel.level}L)` : ev?.description,
                    heading: p.heading,
                    speed: p.speed,
                    location: p.locationText,
                    state: state,
                    assetType: asset.assetType,
                    assetTypeIconColor: asset.assetTypeIconColor,
                    timesent: convertTimeSent(p.timeSent),
                    timeIndex: timeIndex,
                    isSelected: false,
                    id: p.id,
                    assetData: asset
                };

                const marker = {
                    key: p.id,
                    id: p.id,
                    assetData: asset,
                    positionData: p,
                    eventData: ev,
                    position: { lat: p.latitude, lng: p.longitude },
                    icon: {
                        url: assetProvider.getPreloadedAssetType(asset.id),
                        size: new window.google.maps.Size(25, 25),
                        origin: new window.google.maps.Point(0, 0),
                        scaledSize: new window.google.maps.Size(25, 25)
                    },
                    title: `${p.locationText}\n${date}, ${hour}\n${p.latitude}, ${p.longitude}`,
                    onClick: () => {
                        const map = googleMapCompRef.current.getInternalMap();
                        //setMakerSelectedId(p.id);
                        googleMapCompRef.current.setMarkerSelection({
                            id: p.id
                        });
                        setGridSelectedRowId(p.id);
                        dataGridEventsGridRef.current.scrollToRow(gridEvent?.idx ?? 0);
                        //setGridScrollIndex(gridEvent?.idx ?? 0);
                        if (map) {
                            map.panTo(marker.position);
                        }
                    }
                };

                const coordinate = { lat: p.latitude, lng: p.longitude, id: p.id, timeIndex };
                gridEvents.push(gridEvent);
                markers.push(marker);
                pathCoordinates.push(coordinate);
            }

            const positionCenter = getGoogleMapLocationsCenter(markers.map(x => x.position));
            gridEvents.sort((b, a) => (a.timeIndex > b.timeIndex) ? 1 : ((b.timeIndex > a.timeIndex) ? -1 : 0))
            pathCoordinates.sort((a, b) => (a.timeIndex > b.timeIndex) ? 1 : ((b.timeIndex > a.timeIndex) ? -1 : 0))
            for (var i = 0; i < gridEvents.length; i++) {
                gridEvents[i].idx = i;
            }

            if (markers.length <= 0) {
                const m = { ...mapData };
                m.userError = `No position found for specified range\n(${range.description})`;
                m.markers = [];
                m.pathCoordinates = [];
                setEventsGridData([]);
                setMapData(m);
                return;
            }

            setEventsGridData(gridEvents);

            const filteredPathCoordinates = [];
            for (var i = 0; i < pathCoordinates.length; i++) {
                if (pathCoordinates[i].inTrip) {
                    filteredPathCoordinates.push(pathCoordinates[i]);
                }
            }

            setMapData({
                center: positionCenter,
                markers: markers,
                pathCoordinates: filteredPathCoordinates,
            });

            fitMapBounds(pathCoordinates);
        })
    }

    const updateActiveAccount = (data) => {
        var assetsList = [];
        const assets = data.assets;
        const units = data.units;

        for (let asset of assets) {
            if (!asset.active) continue;
            var unit = units.find(x => x.assetId === asset.id);
            var fullName = unit ? `${asset.name} (${unit.hwSerial})` : asset.name;
            assetsList.push({
                id: asset.id,
                name: fullName,
                assetData: asset,
                unitData: unit
            });
        }

        //setAccountData({
        //    assets: assetsList,
        //    account: { name: accounts[0].name }
        //});

        var lastPositions = data?.lastPositions;
        if (!lastPositions) {
            return;
        }

        var gridEvents = [];
        var markers = [];
        var pathCoordinates = [];
        for (const p of lastPositions) {
            if (p.userError || !p.position || !p.asset) {
                continue;
            }

            var ev = p.event;
            var time = toLocalTime(p.position.timeSent);
            var date = dateFormat(time, "dd mmm yyyy");
            var hour = dateFormat(time, "HH:MM:ss");
            var state = p.position.speed > 0 ? lang.language.moving : lang.language.stationary;


            const gridEvent = {
                key: p.position.id,
                date: date,
                hour: hour,
                asset: p.asset.name,
                assetType: p.asset.assetType,
                assetTypeIconColor: p?.asset?.assetTypeIconColor,
                event: ev?.description,
                heading: p.position.heading,
                speed: p.position.speed,
                location: p.position.locationText,
                state: state,
                timesent: convertTimeSent(p.position.timeSent),
                timeIndex: time.getTime(),
                data: data,
                assetData: p.asset
            };

            const marker = {
                key: p.position.id,
                id: p.position.id,
                assetData: p.asset,
                positionData: p.position,
                eventData: ev,
                position: { lat: p.position.latitude, lng: p.position.longitude },

                icon: {
                    url: assetProvider.getPreloadedAssetType(p?.asset?.id),
                    size: new window.google.maps.Size(25, 25),
                    origin: new window.google.maps.Point(0, 0),
                    scaledSize: new window.google.maps.Size(25, 25)
                },
                title: `${p.position.locationText}\n${date}, ${hour}\n${p.position.latitude}, ${p.position.longitude}`,
                onClick: (e) => {
                    const map = googleMapCompRef.current.getInternalMap();
                    //setMakerSelectedId(p.position.id);
                    googleMapCompRef.current.setMarkerSelection({
                        id: p.position.id
                    });
                    setGridSelectedRowId(p.position.id);
                    dataGridEventsGridRef.current.scrollToRow(gridEvent?.idx ?? 0);
                    //setGridScrollIndex(gridEvent.idx);
                    if (map) { map.panTo(marker.position) }
                }
            };

            const coordinate = { lat: p.position.latitude, lng: p.position.longitude, id: p.position.id };

            gridEvents.push(gridEvent);
            markers.push(marker);
            pathCoordinates.push(coordinate);
        }

        const positionCenter = getGoogleMapLocationsCenter(markers.map(x => x.position));
        gridEvents.sort((b, a) => (a.timeIndex > b.timeIndex) ? 1 : ((b.timeIndex > a.timeIndex) ? -1 : 0));
        for (var i = 0; i < gridEvents.length; i++) {
            gridEvents[i].idx = i;
        }

        const formatCenter = { lat: positionCenter.lat(), lng: positionCenter.lng() };
        setEventsGridData(gridEvents);
        setMapData({
            center: formatCenter,
            markers: markers,
            pathCoordinates: pathCoordinates,
        });
    };

    const onGo = (range) => {
        var assetId = selectedAsset?.id;
        if (!assetId) {
            const assets = activeAssets;
            if (!assets || assets.length < 1) {
                return;
            }

            const firstAsset = assets[0];
            assetId = firstAsset.id;
            setSelectedAsset(firstAsset);
        }

        setSelectionWarning(false);
        if (!range) {
            retrieveLastPositions(assetId);
            return;
        }

        retrievePositions(assetId, range);
    }

    const loginUser = (username, password) => {
        const loginData = {
            name: username,
            password: password,
            remember: true,
            tag: "yoyo"
        }

        showLoading(true);
        login(loginData, (e) => {
            showLoading(false);
            if (e.error) {
                setLoggedIn(false)
                return;
            }

            if (e.data.token) {
                //setAuthorisationToken(e.data.token);
                setAuthorisationToken(e.data.token);
            }

            setLoginOpen(false);
            setLoggedIn(true);
        })
    }

    const getBoundsWidthHeight = (bounds) => {
        var spherical = window.google.maps.geometry.spherical;
        const cor1 = bounds.getNorthEast();
        const cor2 = bounds.getSouthWest();
        const cor3 = new window.google.maps.LatLng(cor2.lat(), cor1.lng());
        const cor4 = new window.google.maps.LatLng(cor1.lat(), cor2.lng());

        const width = spherical.computeDistanceBetween(cor1, cor3);
        const height = spherical.computeDistanceBetween(cor1, cor4);
        return { w: width, h: height };
    }


    const fitMapBounds = (coordinates) => {
        const map = googleMapCompRef.current.getInternalMap();
        if (!map) {
            return;
        }

        if (coordinates.length === 1) {
            map.panTo(coordinates[0]);
            return;
        }

        var containAll = true;
        const mapBounds = map.getBounds();
        const bounds = new window.google.maps.LatLngBounds();
        for (var point of coordinates) {
            const loc = new window.google.maps.LatLng(point.lat, point.lng);
            bounds.extend(loc);
            if (!mapBounds.contains(loc)) {
                containAll = false;
            }
        }

        if (containAll) {
            return;
        }

        const mSize = getBoundsWidthHeight(mapBounds);
        const cSize = getBoundsWidthHeight(bounds);
        if (mSize.w > cSize.w && mSize.h > cSize.h) {
            map.panTo(bounds.getCenter());
            return;
        }

        map.fitBounds(bounds);
    }

    const onAssetSelection = (e) => {
        const assets = activeAssets;
        if (!assets || assets.length < 1) {
            return;
        }

        const assetId = e.id;
        var asset = activeAssets.find(x => x.id === assetId);
        if (!asset) {
            return;
        }

        setSelectionWarning(false);
        setSelectedAsset(asset);
        retrieveLastPositions(assetId);
    }

    const onGridRowSelection = (rowId) => {
        const map = googleMapCompRef.current.getInternalMap();
        if (!map) return;
        const markers = mapData?.markers;
        if (!markers) return;
        const marker = markers.find(x => x.id === rowId);
        const location = marker?.position;
        if (!location) return;
        map.panTo(location);
        googleMapCompRef.current.setMarkerSelection({
            id: rowId,
            activateSelection: true,
            //showInfoWindow: true
        });
    };

    const retrieveActiveAssets = (accountId, onComplete) => {
        fetchActiveAssets(accountId, (e) => {
            if (e.error) { if (onComplete) onComplete(false, null) }

            var assets = [...e.data];
            setActiveAssets(assets);

            if (onComplete) onComplete(true, e.data)
        })
    }

    const retrieveActiveAccounts = (onComplete) => {
        setReady(false)
        fetchActiveAccountTree((e) => {
            if (e.error) {
                if (onComplete) onComplete(false)
                return;
            }

            const activeAccountPackage = e.data;
            const accounts = activeAccountPackage.accountEntries.map(a => {
                return a.account
            });
            setActiveAccounts(accounts)
            if (accounts.length <= 0) {
                if (onComplete) onComplete(false)
                return;
            }

            const firstAccount = accounts[0];
            setSelectedAccount(firstAccount)
            retrieveActiveAssets(firstAccount.id, (state, assets) => {
                if (!assets || assets.length <= 0) {
                    if (onComplete) onComplete(false)
                    return;
                }

                assetProvider.preloadAssetTypes(() => {
                    const firstAsset = assets[0];
                    setSelectedAsset(firstAsset)
                    //setReady(true)
                    ensureGoogleLoaded().then(() => {
                        retrieveLastPositions(firstAsset.id)
                        if (onComplete) onComplete(state)
                    });
                }, assets);
            });
        });
    }

    const checkIfReady = () => {
        showLoading(true)
        setBusy(true)
        isLogin((e) => {
            if (e.error) {
                showLoading(false)
                setBusy(false)
                navigate("/home", { replace: true });
                return;
            }

            retrieveActiveAccounts((e) => {
                setReady(true)
                showLoading(false)
                setBusy(false)
            });
        })
    }

    const onResize = () => {
        const newGridResizeData = { ...gridResizeData };
        newGridResizeData.height = eventGridRef?.current?.clientHeight;
        setGridResizeData(newGridResizeData);
    }

    useEffect(() => {
        checkIfReady()
        window.addEventListener('resize', onResize);
        return () => {
            window.removeEventListener('resize', onResize);
        }
    }, []);

    const mobile = isMobile;
    const barHeight = mobile ? "25px" : "25px";
    const timeFrameHeight = mobile ? "110px" : "150px";

    //const userActionWidth="400px"

    //const gridHeight = mobile ? "80px" : (gridResizeData.height + "px");
    const minGridHeight = mobile ? "110px" : "150px";
    const mapMinWidth = mobile ? "100px" : "200px";
    const mapMinHeight = mobile ? "80px" : "150px";

    const sideBorderWidth = mobile ? "2px" : "3px";

    const userActionWidth = mobile ?
        (accountBarData?.expanded ? "150px" : "25px") :
        (userActionResizeData.width + 'px');
    const accountPanelMinWidth = mobile ? "25px" : "150px";
    const userActionExpended = accountBarData?.expanded;


    const innerHeight = window.innerHeight;
    const gridBarExpanded = gridBarData?.expanded;
    //const mobile = isMobile;
    const gridHeight = mobile ? (gridBarExpanded ?
        `${innerHeight / 2}px` : "80px") :
        (gridResizeData.height + "px");;

    //if (!ready) {
    //    return null;
    //}

    return (
        <div style={{ width: "100%", height: "100%", display: 'flex', flexDirection: 'column' }}>
            <div style={{ width: "100%", height: barHeight }}>
                <TopBarComponenent
                    showUserMenuAdministration={true}
                    showUserMenuDashboard={false }
                    showScreenControl={true}
                    showInfo={true}
                    showRefresh={false}
                    showFullQuery={true}
                    user={activeUser}
                    onAbout={() => {
                        setShowAbout(true)
                    }}
                    onShowFullQuery={() => {
                        const timeConfig = timeFramePanelRef.current.getTimeFrameConfiguration();
                        const assetId = selectedAsset?.id;

                        setSearchParams({
                            uid: assetId,
                            start: timeConfig?.start?.toJSON(),
                            end: timeConfig?.end?.toJSON(),
                            idx: timeConfig?.valueIndex,
                            pr: timeConfig.precision,
                            pop: false,
                            ab: accountBarData?.expanded
                        });

                    }}
                    userMenuControl={true} />
            </div>
            <div style={{ display: 'flex', width: "100%", height: "100%", flex: 1, }}>
                <div style={{ backgroundColor: appThemeColor, display: 'flex', width: sideBorderWidth, height: "100%" }}>
                </div>
                <div style={{ display: 'flex', width: "100%", flexDirection: 'column', flex: 1 }}>
                    <div name="map_division" style={{ width: "100%", height: "100%", display: 'flex', flexDirection: 'column' , flex: 1}}>
                        <div style={{ display: 'flex', width: "100%", height: "100%", position: "relative", minWidth: mapMinWidth, minHeight: mapMinHeight }}>

                            <GoogleMapComponent
                                ref={googleMapCompRef }
                                zoom={zoom}
                                center={mapData?.center}
                                markers={mapData?.markers}
                                pathCoordinates={mapData?.pathCoordinates} />
                            {ready ?
                                null :
                                <div style={{  float: 'left', height: "100%", width: "100%", position: "absolute" }}>
                                </div>}
                        </div>
                    </div>
                    <GridBar />
                    <div ref={eventGridRef} style={{ width: "100%", minHeight: minGridHeight, height: gridHeight }}>
                        <div name="grid_division_resizer"
                            style={{
                                display: mobile ? "none" : undefined,
                                minHeight: "5px", height: "5px", width: "100%", cursor: "row-resize", backgroundColor: "whitesmoke"
                            }}
                            draggable={true}
                            onDragStart={(e) => {
                                const initialHeight = gridResizeData.height;
                                const newGridResizeData = { ...gridResizeData };
                                newGridResizeData.initialPos = e.clientY;
                                newGridResizeData.initialHeight = initialHeight;
                                setGridResizeData(newGridResizeData);
                            }}
                            onDrag={(e) => {
                                if (e.clientY <= 0) { return; }
                                const initialPos = gridResizeData.initialPos;
                                const initialHeight = gridResizeData.initialHeight;
                                var newHeight = parseInt(initialHeight) - parseInt(e.clientY - initialPos);
                                const newGridResizeData = { ...gridResizeData };
                                newGridResizeData.height = newHeight;
                                setGridResizeData(newGridResizeData);
                            }}

                            onDragEnd={(e) => {
                                const initialPos = gridResizeData.initialPos;
                                const initialHeight = gridResizeData.initialHeight;
                                var newHeight = parseInt(initialHeight) - parseInt(e.clientY - initialPos);
                                const newGridResizeData = { ...gridResizeData };
                                newGridResizeData.height = newHeight;
                                setGridResizeData(newGridResizeData);
                            }}>
                        </div>
                        <div style={{ display: "flex", flexDirection: "row", width: "100%", height: "100%", flex: 1 }}>
                            <div style={{ display: "flex", width: "100%", height: "100%" }}>
                                <EventsGridComponent
                                    ref={dataGridEventsGridRef }
                                    rows={eventsGridData}
                                    message={mapData?.userError}
                                    disabled={busy}
                                    gridHeight={gridHeight}
                                    onRowSelection={onGridRowSelection}>
                                    message={mapData?.userError}
                                </EventsGridComponent>
                                {/*<UserGridMessage message={mapData?.userError} />*/}
                            </div>
                        </div>
                    </div>
                </div>
                <div id='user_actions_resizable' name="user_actions_division"
                    style={{
                        //minWidth: "150px",
                        width: userActionWidth,
                        minWidth: accountPanelMinWidth,

                        display: "flex",
                        flexDirection: 'row',
                        height: "100%"
                    }}>
                    <div name="user_actions_division_resizer"
                        style={{
                            display: mobile ? "none" : undefined,
                            backgroundColor: "whitesmoke",
                            minWidth: "5px", width: "5px", height: "100%", cursor: "col-resize"
                        }}
                        draggable={true}
                        onDragStart={(e) => {
                            const initialWidth = userActionResizeData.width;
                            const newUserActionResizeData = { ...userActionResizeData };
                            newUserActionResizeData.initialPos = e.clientX;
                            newUserActionResizeData.initialWidth = initialWidth;
                            setUserActionResizeData(newUserActionResizeData);
                        }}
                        onDrag={(e) => {
                            if (e.clientX <= 0) { return; }
                            const initialPos = userActionResizeData.initialPos;
                            const initialWidth = userActionResizeData.initialWidth;
                            const newWidth = parseInt(initialWidth) - parseInt(e.clientX - initialPos);
                            const newUserActionResizeData = { ...userActionResizeData };
                            newUserActionResizeData.width = newWidth;
                            console.log(`${newWidth},${e.clientX}, ${newWidth}`)
                            setUserActionResizeData(newUserActionResizeData);
                        }}

                        onDragEnd={(e) => {
                            const initialPos = userActionResizeData.initialPos;
                            const initialWidth = userActionResizeData.initialWidth;
                            const newWidth = parseInt(initialWidth) - parseInt(e.clientX - initialPos);
                            const newUserActionResizeData = { ...userActionResizeData };
                            newUserActionResizeData.width = newWidth;
                            setUserActionResizeData(newUserActionResizeData);
                        }}>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'column', width: "100%", overflowY: "hidden", }}>
                        <div style={{ Height: "25px", display: 'flex', width: "100%" }}>
                            <AccountBar />
                        </div>
                        <div style={{ display: 'flex', width: "100%", height: "100%", flex:1 }}>
                            <UserPanelComponent
                                assets={activeAssets}
                                onSelection={onAssetSelection}
                                visible={isMobile ? userActionExpended : true}
                                accounts={activeAccounts}
                                selectedAccount={selectedAccount}
                                onAccountChange={(account) => {
                                    showLoading(true)
                                    setBusy(true)
                                    const m = { ...mapData };
                                    m.userError = "";
                                    m.markers = [];
                                    m.pathCoordinates = [];
                                    setEventsGridData([]);
                                    setMapData(m);
                                    setSelectedAccount(account)
                                    retrieveActiveAssets(account.id, (state, assets) => {
                                        showLoading(false)
                                        setBusy(false)
                                        if (assets.length>0) {
                                            const firstAsset = assets[0];
                                            setSelectedAsset(firstAsset)
                                            retrieveLastPositions(firstAsset.id)
                                        }

                                    });
                                }}
                                onRequestCurrentPosition={(e) => {
                                    const asset = e.item;
                                    if (!asset)
                                        return;
                                    fetchRequestCurrentPosition((e) => {
                                        if (e.error)
                                            return;
                                    }, asset.id);
                                }}
                                onRequestUpdate={(e) => {
                                    setUpdaterData({
                                        open: true,
                                        asset: e.item
                                    });
                                }}
                                selectedAsset={selectedAsset}>
                            </UserPanelComponent>
                        </div>
                        <div style={{ display: "flex", height: eventGridRef?.current?.clientHeight, minHeight: timeFrameHeight }}>
                            <TimeFrameComponent
                                ref={timeFramePanelRef}
                                visible={userActionExpended}
                                onGo={onGo}
                                disabled={busy}>
                            </TimeFrameComponent>
                        </div>
                    </div>

                </div>
                <ContactsDialog
                    allowEdit={loggedIn}
                    allowRemove={loggedIn}
                    allowTest={loggedIn} />
                {updaterData && <UpdaterDialog
                    onClickOutside={() => {
                        setUpdaterData(null)
                    }}
                    open={updaterData.open}
                    asset={updaterData.asset} />}
                <NoInternet />
                <div style={{ backgroundColor: appThemeColor, display: 'flex', width: sideBorderWidth, height: "100%" }}>
                </div>
            </div>
            <div style={{ backgroundColor: appThemeColor, height: barHeight, minHeight: barHeight, display: 'flex', position: "relative" }}>
                {isFullScreen && <TimeDisplay />}
            </div>
            <LoginDialog
                open={loginOpen}
                onCancel={() => {
                    //isUserLoggedIn();
                    setLoginOpen(false);
                }}
                onConfirm={(e) => {
                    loginUser(e.username, e.password)
                }}
            />
            <SettingsDialog
                open={settingActive}
                onCancel={(e) => { setSettingActive(false) }}
                onAccountUpdate={(data) => {
                    updateActiveAccount(data);
                }}
            />

        </div>);
};

export default WebtraceDashboard;