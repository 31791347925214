import { appConfig } from '../configurations/appConfig';
import urlJoin from 'url-join';

const login = ( loginData,onResult = null) => {
    const method = "authentication_jwt_login";
    const server = appConfig.data.server;
    //const authorisationToken = appConfig.authorisationToken;
    const requestOptions = {
        headers: {
            //'Authorization': `Bearer ${token}`,
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
        method: 'POST',
        body: JSON.stringify(loginData)
    };

    fetch(urlJoin(server, method), requestOptions)
        .then(response => {
            if (response.ok) {
                console.info("Operation succeeded");
                response.json().then(data => {
                    console.info(data);
                    if (!onResult) return;
                    onResult({
                        error: null,
                        data: data
                    });
                })
            }
            else {
                response.text().then(text => {
                    if (!onResult) return;
                    onResult({ error: `${response.statusText} (${response.status}): ${text}`, data: null });
                }).catch(err => {
                    console.info(`${err}`);
                    if (!onResult) return;
                    onResult({ error: `${err}`, data: null });
                });
            }
        })
        .catch(err => {
            console.info(`${err}`);
            if (!onResult) return;
            onResult({ error: `${err}`, data: null })
        })
};

const isLogin = (onResult = null) => {
    const server = appConfig.data.server;
    const authorisationToken = appConfig.authorisationToken;
    const method = "authentication_jwt_is_logged";
    const requestOptions = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${authorisationToken}` 
        },
        method: 'POST'
    };

    fetch(urlJoin(server, method), requestOptions)
        .then(response => {
            if (response.ok) {
                console.info("Operation succeeded");
                response.json().then(data => {
                    console.info(data);
                    if (!onResult) return;
                    onResult({
                        error: null,
                        data: data
                    });
                })
            }
            else {
                response.text().then(text => {
                    if (!onResult) return;
                    onResult({ error: `${response.statusText} (${response.status}): ${text}`, data: null });
                }).catch(err => {
                    console.info(`${err}`);
                    if (!onResult) return;
                    onResult({ error: `${err}`, data: null });
                });
            }
        })
        .catch(err => {
            console.info(`${err}`);
            if (!onResult) return;
            onResult({ error: `${err}`, data: null })
        })
};

const logout = (logoutData, onResult = null) => {
    const method = "authentication_jwt_logout";
    const server = appConfig.data.server;
    const authorisationToken = appConfig.authorisationToken;
    const requestOptions = {
        headers: {
            'Authorization': `Bearer ${authorisationToken}`,
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
        method: 'POST',
        body: JSON.stringify(logoutData)
    };

    fetch(urlJoin(server, method), requestOptions)
        .then(response => {
            if (response.ok) {
                console.info("Operation succeeded");
                response.json().then(data => {
                    console.info(data);
                    if (!onResult) return;
                    onResult({
                        error: null,
                        data: data
                    });
                })
            }
            else {
                response.text().then(text => {
                    if (!onResult) return;
                    onResult({ error: `${response.statusText} (${response.status}): ${text}`, data: null });
                }).catch(err => {
                    console.info(`${err}`);
                    if (!onResult) return;
                    onResult({ error: `${err}`, data: null });
                });
            }
        })
        .catch(err => {
            console.info(`${err}`);
            if (!onResult) return;
            onResult({ error: `${err}`, data: null })
        })
};

const hello = (onResult = null) => {
    const server = appConfig.data.server;
    //const authorisationToken = appConfig.authorisationToken;
    const method = "api_visitor_hello";
    const requestOptions = {
        headers: {
            //'Authorization': `Bearer ${token}`,
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
        method: 'Get'
    };

    fetch(urlJoin(server, method), requestOptions)
        .then(response => {
            if (response.ok) {
                console.info("Operation succeeded");
                response.json().then(data => {
                    console.info(data);
                    if (!onResult) return;
                    onResult({
                        error: null,
                        data: data
                    });
                })
            }
            else {
                response.text().then(text => {
                    if (!onResult) return;
                    onResult({ error: `${response.statusText} (${response.status}): ${text}`, data: null });
                }).catch(err => {
                    console.info(`${err}`);
                    if (!onResult) return;
                    onResult({ error: `${err}`, data: null });
                });
            }
        })
        .catch(err => {
            console.info(`${err}`);
            if (!onResult) return;
            onResult({ error: `${err}`, data: null })
        })
};



export { login, isLogin, hello, logout };