import { localTimeFormat } from '../../utilities/timeHelper'
import React from 'react'
import DataGrid, { Row } from 'react-data-grid';
import { showLoading } from 'react-global-loading';

import {
    fetchAdminAccounts,
    fetchAccountContacts,
    retrieveAccountNotificationSettings,
    updateAccountNotificationSettings
} from "../../calls/administration/fetchAccounts";

import ConfirmBox from './ConfirmBox';
import { hexToRgbaWithAlpha } from '../../utilities/convertColors';
import { deleteAccount } from '../../calls/administration/createAccount';
import { IconContext } from "react-icons";
import { GrClose } from "react-icons/gr";
import { isMobile } from 'react-device-detect';



const getColumns = () => {
    return [
        {
            key: 'name', name: "Account"
        },
        {
            key: 'parentName', name: "parent"
        },
        {
            key: 'updated', name: "Updated"
        },
        {
            key: 'added', name: "Added"
        }
    ]
};

function rowKeyGetter(row) {
    return row.id;
}

function AccountListView(props) {
    const [columns] = React.useState(getColumns);
    const [selectedGridRows, setSelectedGridRows] = React.useState(new Set([]));
    const [rows, setRows] = React.useState([]);
    const [selectedAccount, setSelectedAccount] = React.useState(null);
    const datagridRef = React.useRef(null);
    const [busy, setBusy] = React.useState(true);
    const [accountDeleteData, setAccountDeleteData] = React.useState(null);
    const [viewContactsData, setViewContactsData] = React.useState(null);
    const [viewContactNotificationData, setViewContactNotificationData] = React.useState(null);

    const updateAccounts = (silent = true) => {
        if (!silent) {
            setBusy(true);
            showLoading(true);
        }

        fetchAdminAccounts((e) => {

            setBusy(false);
            showLoading(false);

            if (e.error) {
                return;
            }

            const accounts = e?.data?.accounts;
            const parentAccounts = e?.data?.relatedParentAccounts;
            //setUnits(e.data);
            const result = [];
            if (accounts) {
                for (var account of accounts) {
                    const parent = parentAccounts?.find(x => x.id === account.parentAccountId)
                    result.push({
                        id: account.id,
                        parentName: parent ? parent.name : "None",
                        name: account.name,
                        updated: localTimeFormat(account.updated),
                        added: localTimeFormat(account.added),
                        account: account
                    });
                }
            }

            setRows(result)
        });
    }

    React.useEffect(() => {
        updateAccounts(false);
    }, []);

    if (busy) return null;
    const RowRenderer = (key, props) => <Row {...props} selectedCellIdx={undefined} />
    return (
        <div ref={datagridRef} style={{ width: "100%", height: "100%", display: "flex", overflow: "auto", flexDirection: "column" }}>
            <div style={{ width: "100%", height: "100%", display: "flex", overflow: "auto", position: "relative", flex: 1 }}>
                <DataGrid

                    defaultColumnOptions={{ sortable: true, resizable: false }}
                    rowHeight={25}
                    columns={columns}
                    rows={rows}
                    rowKeyGetter={rowKeyGetter}
                    className='rdg-light'
                    onSelectedRowsChange={setSelectedGridRows}
                    selectedRows={selectedGridRows}
                    onRowsChange={setRows}
                    enableVirtualization={true}
                    style={{ width: "100%", height: "calc(100% - 5px)", position: "absolute" }}
                    renderers={{ renderRow: RowRenderer }}
                    onCellClick={(args, event) => {
                        const rowId = args.row.id;
                        setSelectedAccount(args.row.account)
                        var set = new Set([rowId]);
                        setSelectedGridRows(set);
                    }} />
            </div>
            <div style={{ height: "30px", display: "flex", padding: "2px" }}>
                <button style={{ padding: "2px", width: "80px", margin: "0px 5px" }}
                    disabled={!selectedAccount}
                    onClick={() => {
                        if (selectedAccount && props.onEditAccount) {
                            props.onEditAccount(selectedAccount)
                        }
                    }}>
                    Edit
                </button>
                <button style={{ padding: "2px", width: "80px", margin: "0px 5px" }}
                    disabled={!selectedAccount}
                    onClick={() => {
                        setAccountDeleteData({
                            open: true,
                            deleteAccount: selectedAccount
                        })
                    }}
                >
                    Delete
                </button>
                <button style={{ padding: "2px", margin: "0px 5px" }}
                    disabled={!selectedAccount}
                    onClick={() => {
                        setViewContactsData({
                            open: true,
                            account: selectedAccount
                        })
                    }}>
                    View contacts
                </button>
                <button style={{ padding: "2px", margin: "0px 5px" }}
                    disabled={!selectedAccount}
                    onClick={() => {
                        setViewContactNotificationData({
                            open: true,
                            account: selectedAccount
                        })
                    }}>
                    Contacts notifications
                </button>
            </div>
            {accountDeleteData?.open && <div style={{
                width: "100%", height: "100%", display: "flex",
                position: "fixed", backgroundColor: hexToRgbaWithAlpha("#000000", 0.4),
                right: 0, left: 0, top: 0, bottom: 0
            }}>
                <ConfirmBox
                    open={accountDeleteData?.open}
                    title="Account deletion"
                    onCancel={() => {
                        setAccountDeleteData(null)
                    }}
                    onConfirm={() => {
                        const account = accountDeleteData.deleteAccount;
                        setAccountDeleteData(null)
                        showLoading(true);
                        deleteAccount((e) => {
                            showLoading(false);
                            if (e.error) {
                                return
                            }

                            updateAccounts(false)
                        }, { accountId: account.id });
                    }}
                    message={`You are about to delete account ${accountDeleteData?.deleteAccount?.name}`} />
            </div>}
            {
                viewContactsData?.open && <div style={{
                    width: "100%", height: "100%", display: "flex",
                    position: "fixed", backgroundColor: hexToRgbaWithAlpha("#000000", 0.4),
                    right: 0, left: 0, top: 0, bottom: 0
                }}>
                    <ViewAccountContacts account={viewContactsData.account} onClose={() => {
                        setViewContactsData(null)
                    }}></ViewAccountContacts>
                </div>
            }
            {
                viewContactNotificationData?.open && <div style={{
                    width: "100%", height: "100%", display: "flex",
                    position: "fixed", backgroundColor: hexToRgbaWithAlpha("#000000", 0.4),
                    right: 0, left: 0, top: 0, bottom: 0
                }}>
                    <ViewContactsNotifications account={viewContactNotificationData?.account} onClose={() => {
                        setViewContactNotificationData(null)
                    }}></ViewContactsNotifications>
                </div>
            }
        </div>);
}


const getViewAccountContactsColumns = () => {
    return [
        {
            key: 'name', name: "Name"
        },
        {
            key: 'email', name: "Email"
        },
        {
            key: 'number', name: "Number"
        }
    ]
};
function ViewAccountContacts(props) {
    const [columns] = React.useState(getViewAccountContactsColumns);
    const [selectedGridRows, setSelectedGridRows] = React.useState(new Set([]));
    const [rows, setRows] = React.useState([]);
    const [selectedAccount, setSelectedAccount] = React.useState(null);
    const datagridRef = React.useRef(null);
    const [busy, setBusy] = React.useState(true);
    const refrehAccountCotancts = () => {
        setBusy(true);
        showLoading(true);

        fetchAccountContacts((e) => {
            setBusy(false);
            showLoading(false);

            if (e.error) {
                return;
            }

            const contacts = e?.data?.contacts;
            const result = [];
            if (contacts) {
                for (var contact of contacts) {
                    result.push({
                        key: contact.id,
                        name: contact.name,
                        email: contact.email,
                        number: contact.number,
                    });
                }
            }

            setRows(result)
        }, props.account.id);
    };

    React.useEffect(() => {
        refrehAccountCotancts();
    }, []);
    if (busy) return null;
    const RowRenderer = (key, props) => <Row {...props} selectedCellIdx={undefined} />
    return <dialog open={true}
        style={{
            //position:"relative",
            top: "50%",
            bottom: "50%",
            marginRight: "auto",
            marginLeft: "auto",
            padding: "4px", borderWidth: "1px", borderRadius: "5px", minWidth: "450px"

        }}>
        <div style={{ width: "100%", height: "100%", display: "flex", flexDirection: "column" }}>
            <div style={{ width: "100%", height: "100%", display: "flex", flexDirection: "row", padding:"5px 0px" }}>
                <div style={{ width: "100%", display: "flex", flex: 1 }}>
                    Contact for {props.account.name}
                </div>
                <button onClick={() => {
                    if (props.onClose) {
                        props.onClose();
                    }
                }}>close</button>
            </div>

            <div style={{ width: "100%", height: "100%", position: "relative", flex:1, backgroundColor:"red"}}>
                <DataGrid
                    defaultColumnOptions={{ sortable: true, resizable: false }}
                    rowHeight={25}
                    columns={columns}
                    rows={rows}
                    rowKeyGetter={rowKeyGetter}
                    className='rdg-light'
                    onSelectedRowsChange={setSelectedGridRows}
                    selectedRows={selectedGridRows}
                    onRowsChange={setRows}
                    enableVirtualization={true}
                    //style={{ width: "100%", height: "calc(100% - 5px)", position: "absolute" }}
                    renderers={{ renderRow: RowRenderer }}
                    onCellClick={(args, event) => {
                        const rowId = args.row.id;
                        setSelectedAccount(args.row.account)
                        var set = new Set([rowId]);
                        setSelectedGridRows(set);
                    }} />
            </div>
        </div>


    </dialog>
}


const AccountNotificationContext = React.createContext({});
function FuelNotificationFormatter({ row, onRowChange }) {
    const { fuelNotification } = React.useContext(AccountNotificationContext);
    return (
        <input
            style={{ display: "block", width: "100%", flex: 1 }}
            type="checkbox"
            checked={row.allowFuelNotification}
            disabled={!fuelNotification}
            onChange={(e) => {
                const state = e.target.checked;
                onRowChange({ ...row, allowFuelNotification: state, updated: true }, true)
            }} />);
}

function AccidentNotificationFormatter({ row, onRowChange }) {
    const { accidentNotification } = React.useContext(AccountNotificationContext);
    return (
        <input
            style={{ display: "block", width: "100%", flex: 1 }}
            type="checkbox"
            checked={row.allowAccidentNotification}
            disabled={!accidentNotification}
            onChange={(e) => {
                const state = e.target.checked;
                onRowChange({ ...row, allowAccidentNotification: state, updated: true }, true)
            }} />);
}

function InputNotificationFormatter({ row, onRowChange }) {
    const { inputOutputNotification } = React.useContext(AccountNotificationContext);
    return (
        <input
            style={{ display: "block", width: "100%", flex: 1 }}
            type="checkbox"
            checked={row.allowInputOutputNotification}
            disabled={!inputOutputNotification}
            onChange={(e) => {
                const state = e.target.checked;
                onRowChange({ ...row, allowInputOutputNotification: state, updated:true }, true)
            }} />);
}

const getViewContactNotificationColumns = () => {
    return [
        {
            key: 'name', name: "Name", 
        },
        {
            key: 'allowFuelNotification', name: "Fuel",
            renderCell: FuelNotificationFormatter,
        },
        {
            key: 'allowAccidentNotification', name: "Accident",
            renderCell: AccidentNotificationFormatter
        },
        {
            key: 'allowInputOutputNotification', name: "Input",
            renderCell: InputNotificationFormatter
        }
    ]
};

function ViewContactsNotifications(props) {
    const [account, setAccount] = React.useState(null);
    const [fuelNotification, setFuelNotification] = React.useState(false);
    const [accidentNotification, setAccidentNotification] = React.useState(false);
    const [inputOutputNotification, setInputOutputNotification] = React.useState(false);

    const [columns] = React.useState(getViewContactNotificationColumns);
    const [selectedGridRows, setSelectedGridRows] = React.useState(new Set([]));
    const [rows, setRows] = React.useState([]);
    const [selectedAccount, setSelectedAccount] = React.useState(null);
    //const datagridRef = React.useRef(null);
    const [busy, setBusy] = React.useState(true);

    const refreshAccountNotificationSettings = () => {
        setBusy(true);
        showLoading(true);

        retrieveAccountNotificationSettings((e) => {
            setBusy(false);
            showLoading(false);

            if (e.error) {
                return;
            }

            const ac = e?.data?.account;
            const contacts = e?.data?.contacts;

            setAccount(ac)
            const result = [];
            if (contacts) {
                for (var contact of contacts) {
                    result.push({
                        key: contact.id,
                        name: contact.name,
                        allowFuelNotification: contact.allowFuelNotification,
                        allowAccidentNotification: contact.allowAccidentNotification,
                        allowInputOutputNotification: contact.allowInputOutputNotification,
                        contact: contact,
                        updated: false
                    });
                }
            }

            setRows(result)
            setAccidentNotification(ac?.allowAccidentNotification)
            setFuelNotification(ac?.allowFuelNotification)
            setInputOutputNotification(ac?.allowInputOutputNotification)
        }, props.account.id);
    }

    const ApplyAccountNotificationSettings = (notificationsData) => {
        setBusy(true);
        showLoading(true);

        updateAccountNotificationSettings((e) => {
            setBusy(false);
            showLoading(false);

            if (e.error) {
                return;
            }
        }, notificationsData);
    }

    React.useEffect(() => {
        refreshAccountNotificationSettings();
    }, []);

    if (busy) return null;
    const awesomeSize = isMobile ? 18 : 14;
    const RowRenderer = (key, props) => <Row {...props} selectedCellIdx={undefined} />
    return <AccountNotificationContext.Provider
        value={{
            fuelNotification: fuelNotification,
            accidentNotification: accidentNotification,
            inputOutputNotification: inputOutputNotification
        }} >

        <dialog open={true}
            style={{
                top: "50%", bottom: "50%", marginRight: "auto", marginLeft: "auto",
                padding: "4px", borderWidth: "1px", borderRadius: "5px", minWidth: "450px"
            }}>

            <div style={{ width: "100%", height: "100%", display: "flex", flexDirection: "column" }}>
                <div style={{ width: "100%", height: "100%", display: "flex", flexDirection: "row", padding: "5px 0px" }}>
                    <div style={{ width: "100%", display: "flex", flex: 1 }}>
                        Account Notification Settings for {props.account.name}
                    </div>
                    <button style={{ backgroundColor: "transparent", borderWidth: "0px", height: "100%" }}
                        onClick={() => {
                            if (props.onClose) {
                                props.onClose();
                            }
                        }}>
                        <div style={{ alignItems: "center", display: "flex", justifyContent: "center" }}>
                            <IconContext.Provider value={{ size: awesomeSize }}>
                                <GrClose />
                            </IconContext.Provider>
                        </div>
                    </button>
                </div>
                <hr style={{ height: "1px", width: "100%",  padding: "0px", margin: "5px 0px 15px 0px" }}></hr>
                <div style={{ width: "100%", height: "100%", display: "flex", flexDirection: "column" }}>
                    <div style={{ textAlign: "right" }}>
                        Account
                    </div>
                    <div style={{ display: "flex", margin: "5px 0px" }}>
                        <label style={{ display: "block", width: "250px", textAlign: "left" }} htmlFor="fuel_notification_label">
                            Allow fuel notification
                        </label>
                        <input
                            style={{ display: "block", width: "100%", flex: 1 }}
                            type="checkbox" id="fuel_notification_label"
                            checked={fuelNotification}
                            onChange={(e) => {
                                setFuelNotification(e.target.checked)
                            }} />
                    </div>
                    <div style={{ display: "flex", margin: "5px 0px" }}>
                        <label style={{ display: "block", width: "250px", textAlign: "left" }} htmlFor="accident_notification_label">
                            Allow accident notification
                        </label>
                        <input
                            style={{ display: "block", width: "100%", flex: 1 }}
                            type="checkbox" id="accident_notification_label"
                            checked={accidentNotification}
                            onChange={(e) => {
                                setAccidentNotification(e.target.checked)
                            }} />
                    </div>
                    <div style={{ display: "flex", margin: "5px 0px" }}>
                        <label style={{ display: "block", width: "250px", textAlign: "left" }} htmlFor="io_notification_label">
                            Allow I/O notification
                        </label>
                        <input
                            style={{ display: "block", width: "100%", flex: 1 }}
                            type="checkbox" id="io_notification_label"
                            checked={inputOutputNotification}
                            onChange={(e) => {
                                setInputOutputNotification(e.target.checked)
                            }} />
                    </div>
                </div>
                <hr style={{ height: "1px", width: "100%",  padding: "0px", margin: "5px 0px 15px 0px" }}></hr>
                <div style={{ width: "100%", height: "100%", position: "relative", flex: 1 }}>
                    <div style={{ textAlign: "right" }}>
                        Contacts
                    </div>
                    <DataGrid
                        defaultColumnOptions={{ sortable: true, resizable: false }}
                        rowHeight={25}
                        columns={columns}
                        rows={rows}
                        rowKeyGetter={rowKeyGetter}
                        className='rdg-light'
                        onSelectedRowsChange={setSelectedGridRows}
                        selectedRows={selectedGridRows}
                        onRowsChange={setRows}
                        enableVirtualization={true}
                        //style={{ width: "100%", height: "calc(100% - 5px)", position: "absolute" }}
                        renderers={{ renderRow: RowRenderer }}
                        onCellClick={(args, event) => {
                            const rowId = args.row.id;
                            setSelectedAccount(args.row.account)
                            var set = new Set([rowId]);
                            setSelectedGridRows(set);
                        }} />
                </div>
                <div style={{ width: "100%", height: "100%", display: "flex", flexDirection: "row", padding: "5px 0px" }}>
                    <div style={{ width: "100%", display: "flex", flex: 1 }}>

                    </div>
                    <button onClick={() => {
                        var result = [];
                        for (var row of rows) {
                            if (row.updated) {
                                result.push({
                                    contactId: row.contact.id,
                                    allowFuelNotification: row.allowFuelNotification ?? false,
                                    allowAccidentNotification: row.allowAccidentNotification ?? false,
                                    allowInputOutputNotification: row.allowInputOutputNotification ?? false,
                                })
                            }
                        }

                        ApplyAccountNotificationSettings({
                            accountId: account?.id,
                            allowFuelNotification: fuelNotification,
                            allowAccidentNotification: accidentNotification,
                            allowInputOutputNotification: inputOutputNotification,
                            contacts: result
                        });

                    }}>Apply</button>
                </div>
            </div>
        </dialog>
    </AccountNotificationContext.Provider>
}

export default AccountListView;