import React from 'react'
import { isMobile } from 'react-device-detect';
import { hexToRgbaWithAlpha } from '../../utilities/convertColors';
function LoginDialog(props) {
    const [username, setUsername] = React.useState("");
    const [password, setPassword] = React.useState("");
    const mobile = isMobile;
    const title = "Connexion";
    const titleFontSize = mobile ? "12px" : "14px";
    const messageFontSize = mobile ? "10px" : "12px";
    const color = hexToRgbaWithAlpha("#000000", 0.75);
    return (
        <div style={{ position: "absolute", width: "100%", height: "100%", backgroundColor: color, display: props.open ? "block" : "none" }}>
            <div style={{ position: "absolute", left: "50%", top: "50%" }}>
                <dialog open={props.open} style={{ padding: '3px', color: "black", position: "relative",borderWidth: "0.3px", zIndex: 25, transform: `translate(-50%, -50%)` }}>
                    <div style={{ display: "flex", flexDirection: "column", textAlign: "left" }}>
                        <div style={{ margin: "0px 0px 10px 0px", height: "15px", fontSize: titleFontSize }}>{title}</div>
                        <div style={{ display: "flex", margin: "5px 0px" }}>
                            <label style={{ display: "block", width: "140px" }} htmlFor="login_username">Nom d'utilisateur</label>
                            <input id="login_username" type="text" value={username} placeholder="Nom d'utilisateur"
                                onChange={(e) => {
                                    setUsername(e.target.value)
                                }} />
                        </div>
                        <div style={{ display: "flex", margin: "5px 0px" }}>
                            <label style={{ display: "block", width: "140px" }} htmlFor="login_password">Mot de passe</label>
                            <input type="password" id="login_password" placeholder="Mot de passe" value={password}
                                onChange={(e) => {
                                    setPassword(e.target.value)
                                }} />
                        </div>
                        <div style={{ textAlign: "left", display: "flex", flexDirection: "row", margin: "10px 0px 0px 0px" }}>
                            <button style={{
                                width: "100px", backgroundColor: "whitesmoke", color: "black", borderRadius: "2px", height: "100%",
                                borderWidth: "2px", margin: "5px 0px 1px 0px", borderColor: 'lightgray', cursor: "pointer"
                            }} onClick={() => {
                                if (props?.onConfirm) {
                                    props.onConfirm({ username: username, password: password });
                                }
                            }}>confirmer</button>
                            <span style={{ flexGrow: 1, display: "flex" }}></span>
                            <button style={{
                                width: "100px", backgroundColor: "whitesmoke", color: "black", height: "100%", borderRadius: "2px",
                                borderWidth: "2px", margin: "5px 0px 1px 0px", borderColor: 'lightgray', cursor: "pointer"
                            }} onClick={() => {
                                if (!props?.onCancel) {
                                    return;
                                }
                                props.onCancel();
                            }}>Annuler</button>
                        </div>
                    </div>
                </dialog>
            </div>
        </div>);
};

export default LoginDialog;