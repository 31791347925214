import React from 'react'
import { checkIfEmptyOrWhiteSpace } from "../../utilities/stringHelper";
import { createAccount, updateAccount } from "../../calls/administration/createAccount";
import DropdownList from "react-widgets/DropdownList";
import { showLoading } from 'react-global-loading';
import { AdministrationContext } from "./AdministrationContext"
import { fetchAccounts } from "../../calls/administration/fetchAccounts";

function AccountCreation(props) {
    const [name, setName] = React.useState("");
    const [error, setError] = React.useState("");

    const [parentAccounts, setParentAccounts] = React.useState([]);
    const [parentAccountSelected, setParentAccountSelected] = React.useState(null);
    const [parentAccountSearchTerm, setParentAccountSearchTerm] = React.useState('');

    const [fuelNotification, setFuelNotification] = React.useState(false);
    const [accidentNotification, setAccidentNotification] = React.useState(false);
    const [ready, setReady] = React.useState(false);

    const editAccount = props.options?.editAccount;
    const open = props.options?.open;

    const updateAccounts = (onResult, filter) => {
        showLoading(true)
        fetchAccounts((e) => {
            showLoading(false)
            if (e.error) {
                if (onResult) onResult(false);
                return;
            }

            setParentAccounts(e.data.accounts);
            if (onResult) onResult(true, e.data.accounts);
        }, filter);
    }

    React.useEffect(() => {
        const filter = {
            count: 50,
            additionalIds: editAccount ? [editAccount.id] : null
        };

        updateAccounts((status, accounts) => {
            setReady(true)
            if (editAccount) {
                setName(editAccount.name)
                setAccidentNotification(editAccount.allowAccidentNotification)
                setFuelNotification(editAccount.allowFuelNotification)
                if (accounts) {
                    const selected = accounts.find(x => x.id === editAccount.parentAccountId);
                    setParentAccountSelected( selected );
                }

            }
        }, filter);


        return () => {
            setError("")
            setName("")
            setAccidentNotification(null)
            setFuelNotification(null)
        }
    }, []);

    if (!ready) {
        return null;
    }
    return <dialog open={open}
        style={{
            //position:"relative",
            top: "50%",
            bottom: "50%",
            marginRight: "auto",
            marginLeft: "auto",
            padding: "8px", borderWidth: "1px", borderRadius: "5px", minWidth: "450px"
        }}>
        <div>
            <div style={{ textAlign: "right" }}>
                {editAccount ? `Account edition (${editAccount.id})` : "Account creation"}
            </div>
            <div style={{ display: "flex", margin: "5px 0px" }}>
                <label style={{ display: "block", width: "150px", textAlign:"left" }} htmlFor="account_creation_name">Nom de compte</label>
                <input
                    style={{ display: "block", width: "100%", flex: 1 }}
                    autoComplete="none"
                    onFocus={(e) => { e.target.setAttribute("autoComplete", "none") }}
                    type="text" id="account_creation_name" placeholder="Nom du compte" value={name}
                    onChange={(e) => {
                        setName(e.target.value)
                    }} />
            </div>
            <div style={{ display: "flex", margin: "5px 0px", alignItems:"baseline" }}>
                <label style={{ display: "block", width: "150px", textAlign: "left" }} htmlFor="asset_creation_account">Parent Account</label>
                <span style={{ display: "flex", width: "100%", alignItems: "baseline", flex: 1 } }>
                    <DropdownList
                        id="asset_creation_account"
                        style={{ display: "block", width: "100%" }}
                        data={parentAccounts}
                        dataKey='id'
                        textField='name'
                        value={parentAccountSelected}
                        readOnly={editAccount}
                        searchTerm={parentAccountSearchTerm }
                        onSearch={(e) => {
                            setParentAccountSearchTerm(e);

                            const filter = {
                                count: 50,
                                searchTerm: e,
                                additionalIds: editAccount ? [editAccount.id] : null
                            };

                            updateAccounts(() => {
                            }, filter);
                        }}
                        onChange={(value) => {
                            setParentAccountSelected( value);
                        }}/>
                    {!editAccount && parentAccountSelected && <button onClick={() => {
                        setParentAccountSelected(null);
                    }} style={{ display: "block", height: "20px", marginLeft: "5px", borderColor: "lightgray" }}>X</button>}
                </span>
            </div>

            <div style={{ display: "flex", margin: "5px 0px" }}>
                <label style={{ display: "block", width: "180px", textAlign: "left" }} htmlFor="account_creation_fuel_notification">Fuel notification</label>
                <input
                    style={{ display: "block", width: "100%", flex: 1 }}
                    type="checkbox" id="account_creation_fuel_notification"
                    checked={fuelNotification}
                    onChange={(e) => {
                        setFuelNotification(!fuelNotification)
                    }} />
            </div>

            <div style={{ display: "flex", margin: "5px 0px" }}>
                <label style={{ display: "block", width: "180px", textAlign: "left" }} htmlFor="account_creation_accident_notification">Accident notification</label>
                <input
                    style={{ display: "block", width: "100%",flex:1 }}
                    type="checkbox" id="account_creation_accident_notification"
                    checked={accidentNotification}
                    onChange={(e) => {
                        setAccidentNotification(!accidentNotification)
                    }} />
            </div>

            {checkIfEmptyOrWhiteSpace(error) ? null : (<div style={{ display: "flex", color: "red", fontWeight: "bold", fontSize: "8" }}>
                {error}
            </div>)}
            <div style={{ textAlign: "left", display: "flex", flexDirection: "row", margin: "10px 0px 0px 0px" }}>
                <button style={{
                    width: "100px", backgroundColor: "whitesmoke", color: "black", borderRadius: "2px", height: "100%",
                    borderWidth: "2px", margin: "5px 0px 1px 0px", borderColor: 'lightgray', cursor: "pointer"
                }} onClick={() => {
                    if (checkIfEmptyOrWhiteSpace(name)) {
                        setError("Username cannot be empty")
                        return;
                    }
                    if (editAccount) {
                        const data = {
                            name: name,
                            accountId: editAccount.id,
                            fuelNotification: fuelNotification,
                            accidentNotification: accidentNotification
                        };

                        showLoading(true)
                        updateAccount(data, (e) => {
                            showLoading(false)
                            if (e.error) {
                                setError(e.error)
                                return;
                            }

                            if (!props?.onAccountEdited) {
                                return;
                            }

                            props?.onAccountEdited(e.data)
                        });
                    }
                    else {
                        const data = {
                            name: name,
                            parentAccountId: parentAccountSelected ? parentAccountSelected.id : null,
                            fuelNotification: fuelNotification,
                            accidentNotification:accidentNotification
                        };

                        showLoading(true)
                        createAccount(data, (e) => {
                            showLoading(false)
                            if (e.error) {
                                setError(e.error)
                                return;
                            }

                            if (!props?.onAccountCreated) {
                                return;
                            }

                            props?.onAccountCreated(e.data)
                        });
                    }
                }}>confirmer</button>
                <span style={{ flexGrow: 1, display: "flex" }}></span>
                <button style={{
                    width: "100px", backgroundColor: "whitesmoke", color: "black", height: "100%", borderRadius: "2px",
                    borderWidth: "2px", margin: "5px 0px 1px 0px", borderColor: 'lightgray', cursor: "pointer"
                }} onClick={() => {
                    if (!props?.onCancel) {
                        return;
                    }
                    props.onCancel();
                }}>Annuler</button>
            </div>
        </div>
    </dialog>
}

//public string Name { get; set; }
//public string OwnerId { get; set; }
//public int ? ParentAccountId { get; set; }

export default AccountCreation;