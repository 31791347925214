function AdministrationResultDialog(props) {
    return (
        <dialog
            style={{
                //position:"relative",
                top: "50%",
                bottom: "50%",
                marginRight: "auto",
                marginLeft: "auto",
                padding: "8px", borderWidth: "1px", borderRadius: "5px", minWidth: "450px"
            }}
            open={props.open}>
            <div>
                {props.message}
                <hr />
                <button onClick={() => {
                    if (!props.onClose) return;
                    props.onClose();
                } }>OK</button>
            </div>
        </dialog>);
}

export default AdministrationResultDialog;