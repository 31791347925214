import { appConfig } from '../../configurations/appConfig';
import urlJoin from 'url-join';

const fetchAdminAccounts = (onResult = null, { searchTerm, count, additionalIds } = {}) => {
    const searchParams = new URLSearchParams();
    if (searchTerm) searchParams.append("searchTerm", searchTerm);
    if (count) searchParams.append("count", count);
    if (additionalIds) searchParams.append("additionalIds", additionalIds);

    const method = "admin_retrieve_accounts?" + searchParams;

    const server = appConfig.data.server;
    const requestOptions = {
        headers: {
            //'Authorization': `Bearer ${token}`,
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
        method: 'GET',
    };

    fetch(urlJoin(server, method), requestOptions)
        .then(response => {
            if (response.ok) {
                console.info("Operation succeeded");
                response.json().then(data => {
                    console.info(data);
                    if (!onResult) return;
                    onResult({
                        error: null,
                        data: data
                    });
                })
            }
            else {
                response.text().then(text => {
                    if (!onResult) return;
                    onResult({ error: `${response.statusText} (${response.status}): ${text}`, data: null });
                }).catch(err => {
                    console.info(`${err}`);
                    if (!onResult) return;
                    onResult({ error: `${err}`, data: null });
                });
            }
        })
        .catch(err => {
            console.info(`${err}`);
            if (!onResult) return;
            onResult({ error: `${err}`, data: null })
        })
};

const fetchAccounts = (onResult = null, { searchTerm, count, additionalIds } = {}) => {
    const searchParams = new URLSearchParams();
    if (searchTerm) searchParams.append("searchTerm", searchTerm);
    if (count) searchParams.append("count", count);
    if (additionalIds) searchParams.append("additionalIds", additionalIds);

    const method = "retrieve_accounts?" + searchParams;

    const server = appConfig.data.server;
    const requestOptions = {
        headers: {
            //'Authorization': `Bearer ${token}`,
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
        method: 'GET',
    };

    fetch(urlJoin(server, method), requestOptions)
        .then(response => {
            if (response.ok) {
                console.info("Operation succeeded");
                response.json().then(data => {
                    console.info(data);
                    if (!onResult) return;
                    onResult({
                        error: null,
                        data: data
                    });
                })
            }
            else {
                response.text().then(text => {
                    if (!onResult) return;
                    onResult({ error: `${response.statusText} (${response.status}): ${text}`, data: null });
                }).catch(err => {
                    console.info(`${err}`);
                    if (!onResult) return;
                    onResult({ error: `${err}`, data: null });
                });
            }
        })
        .catch(err => {
            console.info(`${err}`);
            if (!onResult) return;
            onResult({ error: `${err}`, data: null })
        })
};

const fetchAccountContacts = (onResult = null, accountId) => {
    const searchParams = new URLSearchParams();
    if (accountId) searchParams.append("accountId", accountId);

    const method = "retrieve_account_contacts?" + searchParams;

    const server = appConfig.data.server;
    const requestOptions = {
        headers: {
            //'Authorization': `Bearer ${token}`,
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
        method: 'GET',
    };

    fetch(urlJoin(server, method), requestOptions)
        .then(response => {
            if (response.ok) {
                console.info("Operation succeeded");
                response.json().then(data => {
                    console.info(data);
                    if (!onResult) return;
                    onResult({
                        error: null,
                        data: data
                    });
                })
            }
            else {
                response.text().then(text => {
                    if (!onResult) return;
                    onResult({ error: `${response.statusText} (${response.status}): ${text}`, data: null });
                }).catch(err => {
                    console.info(`${err}`);
                    if (!onResult) return;
                    onResult({ error: `${err}`, data: null });
                });
            }
        })
        .catch(err => {
            console.info(`${err}`);
            if (!onResult) return;
            onResult({ error: `${err}`, data: null })
        })
};

const retrieveAccountNotificationSettings = (onResult = null, accountId) => {
    const searchParams = new URLSearchParams();
    if (accountId) searchParams.append("accountId", accountId);

    const method = "retrieve_account_notification_settings?" + searchParams;

    const server = appConfig.data.server;
    const requestOptions = {
        headers: {
            //'Authorization': `Bearer ${token}`,
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
        method: 'GET',
    };

    fetch(urlJoin(server, method), requestOptions)
        .then(response => {
            if (response.ok) {
                console.info("Operation succeeded");
                response.json().then(data => {
                    console.info(data);
                    if (!onResult) return;
                    onResult({
                        error: null,
                        data: data
                    });
                })
            }
            else {
                response.text().then(text => {
                    if (!onResult) return;
                    onResult({ error: `${response.statusText} (${response.status}): ${text}`, data: null });
                }).catch(err => {
                    console.info(`${err}`);
                    if (!onResult) return;
                    onResult({ error: `${err}`, data: null });
                });
            }
        })
        .catch(err => {
            console.info(`${err}`);
            if (!onResult) return;
            onResult({ error: `${err}`, data: null })
        })
};

const updateAccountNotificationSettings = (onResult = null, notificationsData ) => {
    const method = "update_account_notification_settings";
    const server = appConfig.data.server;
    const authorisationToken = appConfig.authorisationToken;
    const requestOptions = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${authorisationToken}`
        },
        method: 'POST',
        body: JSON.stringify(notificationsData)
    };

    fetch(urlJoin(server, method), requestOptions)
        .then(response => {
            if (response.ok) {
                console.info("Operation succeeded");
                response.json().then(data => {
                    console.info(data);
                    if (!onResult) return;
                    onResult({
                        error: null,
                        data: data
                    });
                })
            }
            else {
                response.text().then(text => {
                    if (!onResult) return;
                    onResult({ error: `${response.statusText} (${response.status}): ${text}`, data: null });
                }).catch(err => {
                    console.info(`${err}`);
                    if (!onResult) return;
                    onResult({ error: `${err}`, data: null });
                });
            }
        })
        .catch(err => {
            console.info(`${err}`);
            if (!onResult) return;
            onResult({ error: `${err}`, data: null })
        })
};


export {
    fetchAccounts,
    fetchAdminAccounts,
    fetchAccountContacts,
    retrieveAccountNotificationSettings,
    updateAccountNotificationSettings
}