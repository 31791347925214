import Listbox from "react-widgets/Listbox";
import { localTimeFormat } from '../../utilities/timeHelper'
import React from 'react'
import { fetchEvents, updateEvent, deleteEvent, createEvent } from "../../calls/administration/fetchEvents";
import { isMobile } from 'react-device-detect';
import ConfirmBox from './ConfirmBox'
import { showLoading } from 'react-global-loading';
import { checkIfEmptyOrWhiteSpace } from "../../utilities/stringHelper";
import DataGrid, { Row } from 'react-data-grid';
import { hexToRgbaWithAlpha } from '../../utilities/convertColors'

const getColumns = () => {
    return [
        {
            key: 'code', name: "Code"
        },
        {
            key: 'description', name: "Description"
        },
        {
            key: 'comment', name: "Comment"
        }
    ]
};

function rowKeyGetter(row) {
    return row.id;
}

function EventsListView(props) {
    const [eventDeleteData, setEventDeleteData] = React.useState(null);
    const [columns] = React.useState(getColumns);
    const [selectedGridRows, setSelectedGridRows] = React.useState(new Set([]));
    const [rows, setRows] = React.useState([]);
    const [selectedEvent, setSelectedEvent] = React.useState(null);
    //const [editEvent, setEditEvent] = React.useState(null);
    const [filterText, setFilterText] = React.useState("");
    const [busy, setBusy] = React.useState(false);
    const [eventCreation, setEventCreation] = React.useState(null);

    const updateEventsList = (onResult) => {
        setBusy(true)
        showLoading(true)
        fetchEvents((e) => {
            setBusy(false)
            showLoading(false)
            if (e.error) {
                if (onResult) onResult(false)
                return;
            }

            var result = [];
            for (var item of e.data) {
                result.push({
                    id: item.id,
                    code: item.code,
                    description: item.description,
                    comment: item.comment,
                    searchField: `${item.description} ${item.code} ${item.comment}`.toLowerCase()
                });
            }

            //result = Array(20).fill(result[0]);
            setRows(result)
            if (onResult) onResult(true)
        });
    }

    function filterLastName(event) {
        let search = filterText.toLowerCase();
        return event.searchField.indexOf(search) >= 0;
    }

    const RowRenderer = (key, props) => <Row {...props} selectedCellIdx={undefined} />


    React.useEffect(() => {
        updateEventsList();
        return () => {
        }
    }, []);

    const mobile = isMobile;
    if (busy) {
        return null;
    }

    return <div style={{ display: "flex", flexDirection: "column", height: "100%", width: "100%" }}
        onClick={(e) => {
            console.log("Empty click ...")
        }}
    >

        <div style={{ display: "flex", flexDirection: "column", height: "100%", width: "100%", padding: "5px" }}>
            <div style={{ height: "30px", width: "100%", display: "flex", padding: "10px 0px" }}>
                <button style={{ width: "120px" }} onClick={() => {
                    setEventCreation({
                        open: true
                    })
                }}>
                    Add Event
                </button>

                <span style={{ margin: "0px 5px", display: "flex" }}>
                    <input type="text" value={filterText} onChange={(e) => {
                        setFilterText(e.target.value)
                    }}>
                    </input>
                    <button>filter</button>
                </span>
                <button style={{ padding: "2px", width: "120px", margin: "0px 5px" }}
                    disabled={!selectedEvent}
                    onClick={() => {
                        if (selectedEvent) {
                            //setEditEvent(selectedEvent)
                            setEventCreation({
                                open: true,
                                editEvent: selectedEvent
                            })
                        }
                    }}>
                    Edit
                </button>
                <button style={{ padding: "2px", width: "120px", margin: "0px 5px" }}
                    disabled={!selectedEvent}
                    onClick={() => {
                        setEventDeleteData({
                            open: true,
                            title: "Confirm Delete Action",
                            message: `You are about to delete event: code ${selectedEvent.code}, desc ${selectedEvent.description}`,
                            event: selectedEvent
                        });
                    }}
                >
                    Delete
                </button>
            </div>
            <div style={{ overflow: "auto", flex: 1, borderStyle: "none" }}>
                <div style={{ width: "100%", height: "100%", display: "flex", overflow: "auto", flexDirection: "column", position: "relative" }}>
                    <DataGrid
                        //ref={gridRef}
                        defaultColumnOptions={{
                            sortable: true,
                            resizable: false
                        }}

                        rowHeight={30}
                        columns={columns}
                        rows={rows.filter(filterLastName)}
                        rowKeyGetter={rowKeyGetter}
                        className='rdg-light'
                        onSelectedRowsChange={setSelectedGridRows}
                        selectedRows={selectedGridRows}
                        onRowsChange={setRows}
                        enableVirtualization={true}
                        style={{ width: "100%", height: "calc(100% - 5px)", position: "absolute" }}
                        renderers={{ renderRow: RowRenderer }}
                        onCellClick={(args, event) => {
                            const rowId = args.row.id;
                            var set = new Set([rowId]);
                            setSelectedGridRows(set);
                            const selection = rows.find(x => x.id === args.row.id);
                            setSelectedEvent(selection)
                        }} />
                </div>
            </div>
        </div>
        {eventCreation?.open && <div style={{
            width: "100%", height: "100%", display: "flex",
            position: "fixed", backgroundColor: hexToRgbaWithAlpha("#000000", 0.4),
            right: 0, left: 0, top: 0, bottom: 0
        }}>
            <EventCreation
                open={eventCreation?.open}
                onCancel={() => {
                    setEventCreation(null)
                }}
                onEventCreated={() => {
                    setEventCreation(null)
                    updateEventsList();
                } }
                editEvent={eventCreation?.editEvent}
            />
        </div>}
        {eventDeleteData?.open && <div style={{
            width: "100%", height: "100%", display: "flex",
            position: "fixed", backgroundColor: hexToRgbaWithAlpha("#000000", 0.4),
            right: 0, left: 0, top: 0, bottom: 0
        }}>
            <ConfirmBox
                onCancel={() => {
                    setEventDeleteData(null)
                }}
                onConfirm={(event) => {
                    const data = {
                        eventId: event.id,
                    };

                    showLoading(true)
                    deleteEvent(data, (e) => {
                        showLoading(false)
                        if (e.error) {
                            return;
                        }

                        setEventDeleteData(null)
                    });
                }}
                title={eventDeleteData?.title}
                data={eventDeleteData?.event}
                open={eventDeleteData?.open}
                message={eventDeleteData?.message} />
        </div>}
    </div>;
}

function EventCreation(props) {
    const [code, setCode] = React.useState("");
    const [description, setDescription] = React.useState("");
    const [error, setError] = React.useState("");

    React.useEffect(() => {
        if (!props.open) return;
        setError("")
        if (props.editEvent) {
            setCode(props.editEvent.code?.toString() ?? "");
            setDescription(props.editEvent.description.toString() ?? "")
        }
        else {
            setCode("")
            setDescription("")
        }

    }, [props.open]);

    return <dialog open={props.open}
        style={{
            top: "50%",
            bottom: "50%",
            marginRight: "auto",
            marginLeft: "auto",
            padding: "8px", borderWidth: "1px", borderRadius: "5px", minWidth: "450px"
        }}>
        <div>
            <div style={{ textAlign: "right" }}>
                Unit creation
            </div>
            <hr />
            <div style={{ display: "flex", margin: "5px 0px", textAlign: "left" }}>
                <label style={{ display: "block", width: "140px" }} htmlFor="event_creation_code">Code</label>
                <input style={{ display: "block", width: "100%" }} type="number" id="event_creation_code" placeholder="Event code" value={code}
                    autoComplete="off"
                    disabled={props.editEvent }
                    onChange={(e) => {
                        setCode(e.target.value)
                    }} />
            </div>
            <div style={{ display: "flex", margin: "5px 0px", textAlign: "left" }}>
                <label style={{ display: "block", width: "140px" }} htmlFor="event_creation_description">Description</label>
                <input style={{ display: "block", width: "100%" }} type="text" id="event_creation_description" placeholder="Event description" value={description}
                    autoComplete="off"
                    onChange={(e) => {
                        setDescription(e.target.value)
                    }} />
            </div>

            {checkIfEmptyOrWhiteSpace(error) ? null : (<div style={{ display: "flex", color: "red", fontWeight: "bold", fontSize: "8" }}>
                {error}
            </div>)}
            <div style={{ textAlign: "left", display: "flex", flexDirection: "row", margin: "10px 0px 0px 0px" }}>
                <button style={{
                    width: "100px", backgroundColor: "whitesmoke", color: "black", borderRadius: "2px", height: "100%",
                    borderWidth: "2px", margin: "5px 0px 1px 0px", borderColor: 'lightgray', cursor: "pointer"
                }} onClick={() => {
                    if (checkIfEmptyOrWhiteSpace(code)) {
                        setError("Username cannot be empty")
                        return;
                    }

                    showLoading(true)
                    if (props.editEvent) {
                        const data = {
                            code: code,
                            description: description,
                            comment: "New life",
                            eventId: props.editEvent.id
                        };

                        updateEvent(data, (e) => {
                            showLoading(false)
                            if (e.error) {
                                setError(e.error)
                                return;
                            }

                            if (props.onEventCreated)
                                props.onEventCreated(e.data)
                        });
                    }
                    else {
                        const data = {
                            code: code,
                            description: description,
                            comment: "New life"
                        };

                        createEvent(data, (e) => {
                            showLoading(false)
                            if (e.error) {
                                setError(e.error)
                                return;
                            }

                            if (props.onEventCreated)
                                props.onEventCreated(e.data)
                        });
                    }
                }}>confirmer</button>
                <span style={{ flexGrow: 1, display: "flex" }}></span>
                <button style={{
                    width: "100px", backgroundColor: "whitesmoke", color: "black", height: "100%", borderRadius: "2px",
                    borderWidth: "2px", margin: "5px 0px 1px 0px", borderColor: 'lightgray', cursor: "pointer"
                }} onClick={() => {
                    if (!props?.onCancel) return;
                    props.onCancel();
                }}>Annuler</button>
            </div>
        </div>
    </dialog>
}

export { EventsListView, EventCreation };

