import React from 'react'
import { IconContext } from "react-icons";

import { FcRight, FcLeft } from "react-icons/fc";
import { FcExpand, FcCollapse } from "react-icons/fc";
import { ImArrowRight, ImArrowLeft } from "react-icons/im";
import { AppContext } from "../../AppContext";
import { isMobile } from 'react-device-detect';

function AccountBar() {
    const { accountBarData, setAccountBarData } = React.useContext(AppContext);
    const { appThemeColor } = React.useContext(AppContext);
    const mobile = isMobile;
    const awesomeSize = mobile ? 16 : 14;

    const expanded = accountBarData?.expanded;
    const renderExpandButton = () => {
        if (!mobile) {
            return null;
        }

        return (
            <button style={{
                backgroundColor: "transparent",
                borderWidth: "0px", height: "100%",
                padding:"5px"
            }}
                onClick={() => {
                    const copy = { ...accountBarData };
                    copy.expanded = !copy.expanded;
                    setAccountBarData(copy);
                }}>
                <div style={{ alignItems: "center", display: "flex", justifyContent: "center" }}>
                    <IconContext.Provider value={{ size: awesomeSize, color: appThemeColor }}>
                        {expanded ? <ImArrowRight /> : <ImArrowLeft />}
                    </IconContext.Provider>
                </div>
            </button>);
    }

    return (
        <div style={{ backgroundColor: "whitesmoke", Height: "100%", width: "100%", opacity: 0.65, justifyItems: "left", display: "flex" }}>
            {renderExpandButton()}
        </div>
    );
}

export default AccountBar;