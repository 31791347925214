import React from 'react'
import { localTimeFormat } from '../../utilities/timeHelper'
import fetchUsers from "../../calls/administration/fetchUsers";
import { isMobile } from 'react-device-detect';
import DataGrid, { Row } from 'react-data-grid';
import { showLoading } from 'react-global-loading';
import { forwardRef, useImperativeHandle } from 'react';
import ConfirmBox from './ConfirmBox';
import { hexToRgbaWithAlpha } from '../../utilities/convertColors';
import { deleteUser } from '../../calls/administration/createUser';


const getColumns = () => {
    return [
        {
            key: 'userName',
            name: "userName"
        },
        {
            key: 'email',
            name: "email"
        },
        {
            key: 'phoneNumber',
            name: "phoneNumber"
        },
        {
            key: 'updated',
            name: "updated"
        },
        {
            key: 'added',
            name: "added"
        }
    ]
};

function rowKeyGetter(row) {
    return row.id;
}

function UsersListView(props, ref) {
    const [columns] = React.useState(getColumns);
    const [selectedGridRows, setSelectedGridRows] = React.useState(new Set([]));
    const [rows, setRows] = React.useState([]);
    const [selectedUser, setSelectedUser] = React.useState(null);

    const [busy, setBusy] = React.useState(true);
    const [userDeleteData, setUserDeleteData] = React.useState(null);

    const updateUsers = (silent = true) => {
        if (!silent) {
            setBusy(true);
            showLoading(true);
        }

        fetchUsers((e) => {
            if (!silent) {
                setBusy(false);
                showLoading(false);
            }

            if (e.error)
                return;

            //setUsers(e.data);
            const users = e?.data?.users;
            const result = [];
            if (users) {
                for (var user of users) {
                    result.push({
                        id: user.id,
                        email: user.email,
                        userName: user.userName,
                        phoneNumber: user.phoneNumber,
                        updated: localTimeFormat(user.updated),
                        added: localTimeFormat(user.added),
                        user: user,
                        active:user.active
                    });
                }
            }

            setRows(result)
        });
    }

    React.useEffect(() => {
        updateUsers(false);
    }, []);

    useImperativeHandle(ref, () => ({
        updateUsers: updateUsers,
    }));

    if (busy) {
        return null;
    }

    const RowRenderer = (key, props) => {
        return <Row {...props} selectedCellIdx={undefined} />
    }

    const filteredRows = (rows) => {

    }

    return (<div style={{ width: "100%", height: "100%", display: "flex", overflow: "auto", flexDirection: "column" }}>
        <div style={{ width: "100%", height: "100%", display: "flex", overflow: "auto", position: "relative", flex: 1 }}>
            <DataGrid
                defaultColumnOptions={{ sortable: true, resizable: false }}
                rowHeight={24}
                columns={columns}
                rows={rows}
                rowKeyGetter={rowKeyGetter}
                className='rdg-light'
                onSelectedRowsChange={setSelectedGridRows}
                selectedRows={selectedGridRows}
                onRowsChange={setRows}
                enableVirtualization={true}
                style={{ width: "100%", height: "calc(100% - 5px)", position: "absolute" }}
                renderers={{ renderRow: RowRenderer }}
                onCellClick={(args, event) => {
                    const rowId = args.row.id;
                    setSelectedUser(args.row.user)
                    var set = new Set([rowId]);
                    setSelectedGridRows(set);
                }} />
        </div>
        <div style={{ height: "30px", display: "flex", padding: "2px" }}>
            <button style={{ padding: "2px", width: "80px", margin: "0px 5px" }}
                disabled={!selectedUser}
                onClick={() => {
                    if (selectedUser && props.onEditUser) {
                        props.onEditUser(selectedUser)
                    }
                }}>
                Edit
            </button>
            <button style={{ padding: "2px", width: "80px", margin: "0px 5px" }}
                disabled={!selectedUser}
                onClick={() => {
                    setUserDeleteData({
                        open: true,
                        deleteUser: selectedUser
                    });
                }}
            >
                Delete
            </button>
        </div>

        {userDeleteData?.open && <div style={{
            width: "100%", height: "100%", display: "flex",
            position: "fixed", backgroundColor: hexToRgbaWithAlpha("#000000", 0.4),
            right: 0, left: 0, top: 0, bottom: 0
        }}>
            <ConfirmBox
                open={userDeleteData?.open}
                title="User deletion"
                onCancel={() => {
                    setUserDeleteData(null)
                }}
                onConfirm={() => {
                    const user = userDeleteData.deleteUser;
                    setUserDeleteData(null)
                    deleteUser((e) => {
                        if (e.error) {
                            return
                        }

                        updateUsers(false)
                    }, { userId:user.id });
                }}
                message={`You are about to delete user ${userDeleteData?.deleteUser?.userName}`} />
        </div>}

    </div>)
}

export default forwardRef(UsersListView);