import React, {  useRef } from 'react'
import Listbox from "react-widgets/Listbox";
import { isMobile } from 'react-device-detect';
import assetProvider from '../tools/assetTypeProvider';
import DropdownList from "react-widgets/DropdownList";

import { IconContext } from "react-icons";
import { AiOutlineFilter, AiFillFilter } from "react-icons/ai";
import ContextMenu from './menus/ContextMenu';
import { forwardRef, useImperativeHandle } from 'react';

function UserPanelComponent(props, ref) {
    //const parentRef = useRef(null);
    //const headerRef = useRef(null);
    //const bodyRef = useRef(null);
    const [filterVisible, setFilterVisible] = React.useState(false);
    const [filterOn, setFilterOn] = React.useState(false);
    const [contextMenuData, setContextMenuData] = React.useState(null);

    const configureUserPanel = (config) => {

    }


    const getUserPanelConfiguration = () => {
        return {
        };
    }

    useImperativeHandle(ref, () => ({
        configureUserPanel: configureUserPanel,
        getUserPanelConfiguration: getUserPanelConfiguration,
    }));


    const onChange = (value) => {
        if (!props.onSelection) return;
        props.onSelection(value);
    }

    const mobile = isMobile;
    const titleFontSize = mobile ? 10 : undefined;
    const panelPadding = mobile ? "0px 4px" : "4px 8px";

    //var maxHeight = undefined;
    //if (parentRef.current && headerRef.current) {
    //    maxHeight = (parseInt(parentRef.current.clientHeight) - parseInt(headerRef.current.clientHeight)) + "px";
    //    bodyRef.current.style.maxHeight = maxHeight;
    //    console.log(`${parentRef.current.clientHeight}`);
    //}

    React.useEffect(() => {
    }, []);

    if (!props.visible) {
        return null;
    }

    const contextMenuActor = contextMenuData?.actor;
    return (
        <div style={{ padding: panelPadding, display: "flex", flexFlow: "column", width: "100%", height: "100%" }}>
            <div style={{ margin: "2px 0px 2px 3px" }}>
                <div style={{ textAlign: "right", fontWeight: "bold", fontSize: titleFontSize, margin: "0px 0px 2px 0px" }}>Compte</div>
                <div style={{ textAlign: "center", fontSize: titleFontSize }}>
                    {
                        props.accounts && props.accounts.length <= 1 ?
                            <div style={{ borderStyle: "solid", borderWidth: "1px", borderRadius: "3px", borderColor: "lightgray", padding: "2px 2px" }}>
                                {props?.selectedAccount?.name}
                            </div> :
                            <DropdownList
                                onBlur={() => {
                                    setFilterOn(false)
                                    setFilterVisible(false)
                                }}
                                autoFocus={false}
                                filter={filterOn}
                                style={{ display: "block", width: "100%" }}
                                data={props.accounts}
                                dataKey='id'
                                onToggle={(e) => {
                                    if (e) {
                                        setFilterVisible(true)
                                    }
                                }}
                                textField='name'
                                value={props.selectedAccount}
                                renderValue={({ item }) => {
                                    return (
                                        <span style={{ display: "flex" }}>
                                            <span style={{ width: "100%" }}>{item?.name}</span>
                                            {filterVisible ? (
                                                <button style={{ backgroundColor: "transparent", height: "100%", borderWidth: "0px", alignSelf: "center" }}
                                                    onClick={() => {
                                                        setFilterOn(!filterOn)
                                                    }}>
                                                    <div style={{ alignItems: "center", display: "flex", justifyContent: "center" }}>
                                                        <IconContext.Provider value={{ size: 15 }}>
                                                            {
                                                                filterOn ?
                                                                    <AiFillFilter /> :
                                                                    <AiOutlineFilter />
                                                            }
                                                        </IconContext.Provider>
                                                    </div>
                                                </button>) : null}
                                        </span>)
                                }}
                                onChange={(value) => {
                                    setFilterOn(false)
                                    setFilterVisible(false)
                                    if (props.onAccountChange)
                                        props.onAccountChange(value);
                                }}
                            />
                    }
                </div>
            </div>
            <hr />
            <div style={{ height: "100%", display: "flex", width: "100%", flexFlow: "column", }}>
                <div style={{ textAlign: "right", fontWeight: "bold", fontSize: titleFontSize, margin: "0px 0px 5px 0px" }}>
                    Balises
                </div>
                <div style={{ height: "100%", overflow: "auto", display: "flex", width: "100%", flex: 1, position: "relative", bottom: "5px" }}>
                    <Listbox
                        dataKey="id"
                        data={props.assets}
                        value={props.selectedAsset}
                        style={{ width: "100%", position: "absolute" }}
                        onChange={onChange}
                        renderItem={(item) => {
                            const asset = item?.item;
                            const src = assetProvider.getPreloadedAssetType(asset.id);
                            return (
                                <div
                                    onContextMenu={(e) => {
                                        e.preventDefault(); // prevent the default behaviour when right clicked
                                        console.log("Right Click");
                                        setContextMenuData({
                                            x: e.pageX,
                                            y: e.pageY,
                                            actor: item,
                                            id: item.id
                                        });
                                    }}
                                    style={{
                                        alignItems: "center",
                                        width: "100%",
                                        height: "100%",
                                        justifyContent: "left",
                                        display: "flex",
                                        flexFlow: "row"
                                    }}>
                                    {src ? <img style={{ width: "15px" }} src={src} alt="Logo" /> : null}
                                    <span style={{ width: "10px" }}></span>
                                    <span style={{ width: "100%" }}>{asset.name}</span>

                                </div>
                            )
                        }} />

                </div>
                {contextMenuData && (
                    <ContextMenu top={contextMenuData.y} left={contextMenuData.x} onClickOutside={() => {
                        setContextMenuData(null)
                    }}>
                        <div style={{
                            backgroundColor: "white",
                            borderRadius: "2px",
                            padding: "5px",
                            borderWidth: "2px",
                            borderStyle:"solid",fontSize:"14px",
                            borderColor: "lightgray", display: "flex", flexDirection: "column"
                        }}>
                            <span>
                                {contextMenuActor?.item?.name}
                            </span>
                            <div style={{ height: "1px", width: "100%", margin:"5px 0px", backgroundColor:"lightgray"} }></div>
                            <button
                                onClick={() => {
                                    if (props.onRequestCurrentPosition) {
                                        props.onRequestCurrentPosition(contextMenuActor)
                                    }
                                }}
                                style={{ margin: "2px 0px", borderColor: "lightgray", }}>
                                Request current position
                            </button>
                            <button
                                onClick={() => {
                                    if (props.onRequestUpdate) {
                                        props.onRequestUpdate(contextMenuActor)
                                    }
                                }}
                                style={{ margin: "2px 0px", borderColor: "lightgray",  }}>
                                Update
                            </button>
                        </div>
                    </ContextMenu>
                )}
            </div>
        </div>
    );
}

export default forwardRef(UserPanelComponent);