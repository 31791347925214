const polylinePath = 'M 66.6522,8.78553L -6.04392e-006,124.548L 51.8425,124.548L 66.6534,98.2388L 81.6599,124.548L 133.304,124.548L 66.6522,8.78553 Z';
const truckPath = `M 122.222,55.5555L 94.4444,55.5555L 94.4444,27.7778L 115.556,27.7778L 121.111,38.8889C 121.667,40 122.222,43.3333 122.222,44.4444M 124.444,21.6667C 123.333,18.8889 119.444,16.6667 116.667,16.6667L 88.8889,16.6667C 85.5555,16.6667 83.3333,19.4444 83.3333,22.2222L 83.3333,72.2222L 5.55556,72.2222C 2.22222,72.2222 0,74.4444 0,77.7778L 0,94.4444C 0,97.7778 2.22222,100 5.55556,100L 16.6667,100C 16.6667,90.5555 23.8889,83.3333 33.3333,83.3333C 42.7778,83.3333 50,90.5555 50,100L 88.8889,100C 88.8889,90.5555 96.1111,83.3333 105.556,83.3333C 115,83.3333 122.222,90.5555 122.222,100L 127.778,100C 131.111,100 133.333,97.7778 133.333,94.4444L 133.333,77.7778L 133.333,44.4444C 133.333,41.1111 132.222,36.6667 131.111,33.8889L 124.444,21.6667 Z
M 0, 61.1111C 0, 64.4444 2.22222, 66.6667 5.55556, 66.6667L 77.7778, 66.6667L 77.7778, 5.55555C 77.7778, 2.22221 75.5556, 0 72.2222, 0L 5.55556, 0C 2.22222, 0 0, 2.22221 0, 5.55555L 0, 61.1111 Z
M 33.3333, 88.8889C 39.4722, 88.8889 44.4444, 93.8611 44.4444, 100C 44.4444, 106.139 39.4722, 111.111 33.3333, 111.111C 27.1944, 111.111 22.2222, 106.139 22.2222, 100C 22.2222, 93.8611 27.1944, 88.8889 33.3333, 88.8889 Z
M 105.556, 88.8889C 111.694, 88.8889 116.667, 93.8611 116.667, 100C 116.667, 106.139 111.694, 111.111 105.556, 111.111C 99.4167, 111.111 94.4444, 106.139 94.4444, 100C 94.4444, 93.8611 99.4167, 88.8889 105.556, 88.8889 Z`;

const provider = {
}

const getPolylineIcon = (color = 'red') => {
    if (provider.polylineIcon) {
        return provider.polylineIcon;
    }

    var icon = {
        path: polylinePath,
        fillColor: color,
        fillOpacity: 1,
        scale: 0.05,
        strokeColor: 'gray',
        strokeWeight: 0.4,
        //offset: '50%',
        anchor: new window.google.maps.Point(70, 0)
    };

    provider.polylineIcon = icon;
    return icon;
}

const getMakerIcon = () => {
    if (provider.markerIcon) {
        return provider.markerIcon;
    }

    var icon = {
        path: truckPath,
        fillColor: 'black',
        fillOpacity: 1,
        scale: 0.2,
        strokeColor: 'gray',
        strokeWeight: 0.4,
        //offset: '50%',
        //anchor: new window.google.maps.Point(70, 0)
    };

    provider.markerIcon = icon;
    return icon;
}


export  { getPolylineIcon, getMakerIcon }