import React from 'react'
import { localTimeFormat } from '../../utilities/timeHelper'
import { verifyContact, deleteContact } from '../../calls/administration/createContact';
import { showLoading } from 'react-global-loading';
import ConfirmBox from "./ConfirmBox"
import fetchContacts from "../../calls/administration/fetchContacts";

import { isMobile } from 'react-device-detect';
import DataGrid, { Row } from 'react-data-grid';
import { hexToRgbaWithAlpha } from '../../utilities/convertColors'


const getColumns = () => {
    return [
        {
            key: 'name',
            name: "name"
        },
        {
            key: 'email',
            name: "email"
        },
        {
            key: 'number',
            name: "number"
        },
        {
            key: 'account',
            name: "account"
        },
        {
            key: 'updated',
            name: "updated"
        }
    ]
};

function rowKeyGetter(row) { return row.id; }

function ContactsListView(props) {
    const [selectedContact, setSelectedContact] = React.useState(null);
    const [smsVerificationData, setSmsVerificationData] = React.useState(null);
    const [contactDeleteData, setContactDeleteData] = React.useState(null);
    const [columns] = React.useState(getColumns);
    const [selectedGridRows, setSelectedGridRows] = React.useState(new Set([]));
    const [rows, setRows] = React.useState([]);
    const [busy, setBusy] = React.useState(true);

    const updateContacts = ( silent = true) => {
        if (!silent) {
            setBusy(true);
            showLoading(true);
        }

        fetchContacts((e) => {
            if (!silent) {
                setBusy(false);
                showLoading(false);
            }
            if (e.error) {
                return;
            }

            const contacts = e?.data?.contacts;
            const relatedAccounts = e?.data?.relatedAccounts;

            const result = [];
            for (var contact of contacts) {
                const account = relatedAccounts?.find(x => contact.accountId && x.id === contact.accountId);
                result.push({
                    id: contact.id,
                    name: contact.name,
                    email: contact.email,
                    number: contact.number,
                    account: account?.name,
                    updated: localTimeFormat(contact.updated),
                    contact: contact
                });
            }

            setRows(result)
        });
    }

    React.useEffect(() => {
        updateContacts(false);
    }, []);

    if (busy) {
        return null;
    }

    const RowRenderer = (key, props) => <Row {...props} selectedCellIdx={undefined} />
    return <div style={{ width: "100%", height: "100%", display: "flex", overflow: "auto", flexDirection: "column" }}>
        <div style={{ width: "100%", height: "100%", display: "flex", overflow: "auto", position: "relative", flex: 1 }}>
            <DataGrid
                defaultColumnOptions={{ sortable: true, resizable: false }}
                rowHeight={30}
                columns={columns}
                rows={rows}
                rowKeyGetter={rowKeyGetter}
                className='rdg-light'
                onSelectedRowsChange={setSelectedGridRows}
                selectedRows={selectedGridRows}
                onRowsChange={setRows}
                enableVirtualization={true}
                style={{ width: "100%", height: "calc(100% - 5px)", position: "absolute" }}
                renderers={{ renderRow: RowRenderer }}
                onCellClick={(args, event) => {
                    const rowId = args.row.id;
                    setSelectedContact(args.row.contact)
                    var set = new Set([rowId]);
                    setSelectedGridRows(set);
                }} />
        </div>
        <div style={{ height: "30px", display: "flex", padding: "2px" }}>
            <button style={{ padding: "2px", width: "80px", margin: "0px 5px" }}
                disabled={!selectedContact}
                onClick={() => {
                    if (selectedContact && props.onEditContact) {
                        props.onEditContact(selectedContact)
                    }
                }}>
                Edit
            </button>
            <button style={{ padding: "2px", width: "80px", margin: "0px 5px" }}
                disabled={!selectedContact}
                onClick={() => {
                    if (selectedContact) {
                        setSmsVerificationData({
                            open: true,
                            message: `Vous etes sur le point de verifier ${selectedContact.name} par SMS`,
                            title: "verfication SMS",
                            contact: selectedContact
                        }); 
                    }
                }}>
                Verify
            </button>
            <button style={{ padding: "2px", width: "80px", margin: "0px 5px" }}
                disabled={!selectedContact}
                onClick={() => {
                    if (selectedContact) {
                        setContactDeleteData({
                            open: true,
                            message: `Vous etes sur le point de supprimer le contact ${selectedContact.name}`,
                            title: "Suppression contact",
                            contact: selectedContact
                        });
                    }
                }}>
                Delete
            </button>
        </div>
        {
            smsVerificationData?.open && <div style={{
                width: "100%", height: "100%", display: "flex",
                position: "fixed", backgroundColor: hexToRgbaWithAlpha("#000000", 0.4),
                right: 0, left: 0, top: 0, bottom: 0
            }}>
                <ConfirmBox
                    onCancel={() => {
                        setSmsVerificationData(null)
                    }}
                    onConfirm={(contact) => {
                        const data = {
                            contactId: contact.id,
                            number: contact.number,
                        };

                        showLoading(true)
                        verifyContact(data, () => {
                            setSmsVerificationData(null)
                            showLoading(false)
                        });
                    }}
                    title={smsVerificationData?.title}
                    data={smsVerificationData?.contact}
                    open={smsVerificationData?.open}
                    message={smsVerificationData?.message} />
            </div>
        }
        {
            contactDeleteData?.open && <div style={{
                width: "100%", height: "100%", display: "flex",
                position: "fixed", backgroundColor: hexToRgbaWithAlpha("#000000", 0.4),
                right: 0, left: 0, top: 0, bottom: 0
            }}>
                <ConfirmBox
                    onCancel={() => {
                        setContactDeleteData(null)
                    }}
                    onConfirm={(contact) => {
                        const data = {
                            contactId: contact.id,
                            number: contact.number,
                        };

                        showLoading(true)
                        deleteContact(data, () => {
                            const data = props.data;
                            setContactDeleteData(null)
                            showLoading(false)
                            if (props.onContactDeleted) {
                                props.onContactDeleted(data)
                            }

                        });
                    }}
                    title={contactDeleteData?.title}
                    data={contactDeleteData?.contact}
                    open={contactDeleteData?.open}
                    message={contactDeleteData?.message} />
            </div>
        }
    </div>;
}

export default ContactsListView;