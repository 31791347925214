import { localTimeFormat } from '../../utilities/timeHelper'
import React from 'react'
import { isMobile } from 'react-device-detect';
import DataGrid, { Row } from 'react-data-grid';
import { showLoading } from 'react-global-loading';
import fetchAssets from "../../calls/administration/fetchAssets";
import assetProvider from '../../tools/assetTypeProvider';
import { forwardRef, useImperativeHandle } from 'react';


function AssetFormatter({ row }) {
    const mobile = isMobile;
    const imgSize = mobile ? "10px" : "15px";
    const asset = row?.asset;
    const source = assetProvider.getPreloadedAssetType(asset?.id);

    return <span style={{
        alignItems: "center", width: "100%", height: "100%", justifyContent: "left", display: "flex"
    }}  >
        <img style={{ margin: "0px 5px", width: imgSize, height: imgSize }} src={source} alt="Logo" />
        {row.type}
    </span>
}

const getColumns = () => {
    return [
        {
            key: 'name', name: "name"
        },
        {
            key: 'type', name: "type",
            renderCell: AssetFormatter,
        },
        {
            key: 'account', name: "account"
        },
        {
            key: 'updated', name: "Updated"
        },
        {
            key: 'added', name: "Added"
        }
    ]
};



function rowKeyGetter(row) {
    return row.id;
}

function AssetsListView(props, ref) {
    const [columns] = React.useState(getColumns);
    const [selectedGridRows, setSelectedGridRows] = React.useState(new Set([]));
    const [rows, setRows] = React.useState([]);
    const [selectedAsset, setSelectedAsset] = React.useState(null);
    const [busy, setBusy] = React.useState(false);

    const updateAssets = (silent = true) => {
        if (!silent) {
            setBusy(true);
            showLoading(true);
        }

        fetchAssets((e) => {
            if (!silent) {
                setBusy(false);
                showLoading(false);
            }

            if (e.error)
                return;

            const assets = e?.data?.assets;
            const relatedAssignedAccounts = e?.data?.relatedAssignedAccounts;
            const relatedAssetTypes = e?.data?.relatedAssetTypes;
            const result = [];
            if (assets) {
                assetProvider.preloadAssetTypes(() => {
                    for (var asset of assets) {
                        const account = relatedAssignedAccounts?.find(x => x.id === asset.assignedAccountId);
                        const assetType = relatedAssetTypes?.find(x => x.typeIndex === asset.assetType);
                        result.push({
                            id: asset.id,
                            name: asset.name,
                            type: assetType?.name,
                            account: account?.name,
                            updated: localTimeFormat(asset.updated),
                            added: localTimeFormat(asset.added),
                            asset: asset
                        });
                    }

                    setRows(result)
                }, assets);
            }
        });
    }

    useImperativeHandle(ref, () => ({
        updateAssets: updateAssets,
    }));

    React.useEffect(() => {
        updateAssets(false)
    }, [])

    React.useEffect(() => {
        if (props.updateFlag) {
            updateAssets(false)
            if (props.onUpdateFlagHandled)
                props.onUpdateFlagHandled()
        }

    }, [props.updateFlag])

    if (busy) return null;
    const RowRenderer = (key, props) => <Row {...props} selectedCellIdx={undefined} />
    return (
        <div style={{ width: "100%", height: "100%", display: "flex", overflow: "auto", flexDirection: "column" }}>
            <div style={{ width: "100%", height: "100%", display: "flex", overflow: "auto", position: "relative", flex: 1 }}>
                <DataGrid
                    defaultColumnOptions={{ sortable: true, resizable: false }}
                    rowHeight={24}
                    columns={columns}
                    rows={rows}
                    rowKeyGetter={rowKeyGetter}
                    className='rdg-light'
                    onSelectedRowsChange={setSelectedGridRows}
                    selectedRows={selectedGridRows}
                    onRowsChange={setRows}
                    enableVirtualization={true}
                    style={{ width: "100%", height: "calc(100% - 5px)", position: "absolute" }}
                    renderers={{ renderRow: RowRenderer }}
                    onCellClick={(args, event) => {
                        const rowId = args.row.id;
                        setSelectedAsset(args.row.asset)
                        var set = new Set([rowId]);
                        setSelectedGridRows(set);
                    }} />
            </div>
            <div style={{ height: "30px", display: "flex", padding: "2px" }}>
                <button style={{ padding: "2px", width: "80px", margin: "0px 5px" }}
                    disabled={!selectedAsset}
                    onClick={() => {
                        if (selectedAsset && props.onEditAsset) {
                            props.onEditAsset(selectedAsset)
                        }
                    }}>
                    Edit
                </button>
                <button style={{ padding: "2px", width: "80px", margin: "0px 5px" }}
                    disabled={!selectedAsset}
                    onClick={() => {
                        if (selectedAsset && props.onDeleteAsset) {
                            props.onDeleteAsset(selectedAsset)
                        }
                    }}
                >
                    Delete
                </button>
            </div>
        </div>);
}

export default forwardRef(AssetsListView);