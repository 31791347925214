import React from 'react'
import { checkIfEmptyOrWhiteSpace } from "../../utilities/stringHelper";
import Listbox from "react-widgets/Listbox";
import { localTimeFormat } from '../../utilities/timeHelper'
import fetchConfigs from "../../calls/administration/fetchConfigs";
import { createConfig, deleteConfig, updateConfig, pushConfig } from "../../calls/administration/createConfig";
import { hexToRgbaWithAlpha } from '../../utilities/convertColors'
import { AdministrationContext } from "./AdministrationContext"
import { Combobox } from 'react-widgets';
import { showLoading } from 'react-global-loading';
import ConfirmBox from './ConfirmBox';

const types = ["system", "user"];

function UnitConfigsTable(props) {
    const [error, setError] = React.useState("");
    const { configsUnit, setConfigsUnit } = React.useContext(AdministrationContext);
    const [configs, setConfigs] = React.useState([]);
    const [configCreationOpen, setConfigCreationOpen] = React.useState(false);
    const [selectedConfig, setSelectedConfig] = React.useState(null);
    const [configDeleteData, setConfigDeleteData] = React.useState(null);
    const [configEditData, setConfigEditData] = React.useState(null);
    const [configPushData, setConfigPushData] = React.useState(null);

    const updateTable = (onResult) => {
        showLoading(true)
        fetchConfigs(configsUnit.id, (e) => {
            showLoading(false)
            if (e.error) {
                if (onResult) onResult(false);
                return;
            }

            setConfigs(e.data.configs)
            if (onResult) onResult(true);
        });
    }

    React.useEffect(() => {
        if (props.open) {
            if (configsUnit) {
                updateTable();
            }
        }
        return () => {
            setConfigEditData(null)
            setConfigsUnit(null)
        }
    }, [props.open]);
    const title = `Unit Configuration Table (${configsUnit?.hwSerial})`;
    const editableConfig = selectedConfig?.active;
    return <>
        <dialog open={props.open}
            style={{
                top: "50%",
                bottom: "50%",
                marginRight: "auto",
                marginLeft: "auto",
                padding: "8px", borderWidth: "1px", borderRadius: "5px", minWidth: "650px", height: "450px"
            }}>
            <div style={{ display: "flex", height: "100%", width: "100%", flexDirection: "column" }} >
                <div style={{ textAlign: "left", display: "flex" }}>
                    <span style={{ width: "100%" }}>{title}</span>
                    <button onClick={() => {
                        if (!props?.onClose) return;
                        props?.onClose()
                    }}>x</button>
                </div>
                <hr />
                <div  style={{ height: "100%", width: "100%"}} onClick={() => {
                    setSelectedConfig(null)
                }}>

                    <Listbox
                        dataKey="id"
                        onClick={(e) => {
                            e.stopPropagation();
                        }}
                        //style={{  height: "100%", width: "100%" }}
                        //textField="message"
                        onChange={(cfg) => {
                            setSelectedConfig(cfg)
                        } }
                        value={selectedConfig }
                        data={configs}
                        renderItem={({ item }) => {
                            return (
                                <span style={{ display: "flex", width: "100%" }}>
                                    <span style={{ width: "100%" }}>{types[item.type]}</span>
                                    <span style={{ width: "100%" }}>feature: {item.featureIndex}</span>
                                    <span style={{ width: "100%" }}>config: {item.configIndex}</span>
                                    <span style={{ width: "100%" }}>value: {item.value}</span>
                                </span>
                            );
                        }}
                    />
                </div>
                <hr />
                <div style={{ display: "flex" } } >
                    <button style={{ width: "100px", marginRight:"5px" }} onClick={() => {
                        setConfigCreationOpen(true)
                    }}>Add config</button>
                    <span style={{flex:1} }></span>
                    {
                        //editableConfig &&
                        <button style={{ width: "100px", marginRight: "5px" }} disabled={!editableConfig } onClick={() => {
                            setConfigEditData({
                                config: selectedConfig
                            });
                            setConfigCreationOpen(true)
                        }}>Edit config</button>
                    }
                    <button style={{ width: "100px", marginRight: "5px" }} disabled={!selectedConfig} onClick={() => {
                        const cfg = selectedConfig;
                        if (!cfg) return;
                        setConfigPushData({
                            open: true,
                            title: "Confirm Config Push Action",
                            message: `You are about to push config ${cfg.type}, ${cfg.featureIndex}, ${cfg.featureIndex}, ${cfg.value}`,
                            config: cfg
                        });
                    }}>Push config</button>
                    {
                        //editableConfig &&
                        <button style={{ width: "100px" }} disabled={!editableConfig} onClick={() => {
                            setConfigDeleteData({
                                open: true,
                                title: "Confirm Delete Action",
                                message: `You are aout to delete ${1}`,
                                config: selectedConfig
                            });
                        }}>Delete config</button>
                    }
                </div>
                {checkIfEmptyOrWhiteSpace(error) ? null : (<div style={{ display: "flex", color: "red", fontWeight: "bold", fontSize: "8" }}>
                    {error}
                </div>)}
            </div>
        </dialog>
        {
            configCreationOpen && <div style={{ display: "flex", height: "100%", width: "100%", position: "relative", backgroundColor: hexToRgbaWithAlpha("#000000", 0.4) }}>
                <ConfigCreation
                    ondispose={() => {
                        setConfigEditData(null)
                    }}
                    editData={configEditData }
                    onCancel={() => {
                        setConfigCreationOpen(false)
                    }}
                    onConfigCreated={() => {
                        setConfigCreationOpen(false)
                        updateTable();
                    }}
                    onConfigEdited={() => {
                        setConfigCreationOpen(false)
                        updateTable();
                    }}
                
                    relatedUnit={configsUnit }
                    open={configCreationOpen} />
            </div>
        }
        <ConfirmBox
            onCancel={() => {
                setConfigDeleteData(null)
            }}
            onConfirm={(config) => {
                const data = {
                    configId: config.id,
                };

                showLoading(true)
                deleteConfig(data, () => {
                    setConfigDeleteData(null)
                    showLoading(false)
                    updateTable();
                });
            }}
            title={configDeleteData?.title}
            data={configDeleteData?.config}
            open={configDeleteData?.open}
            message={configDeleteData?.message} />
        <ConfirmBox
            onCancel={() => {
                setConfigPushData(null)
            }}
            onConfirm={(config) => {
                const data = {
                    configId: config.id,
                };

                showLoading(true)
                pushConfig(data, () => {
                    setConfigPushData(null)
                    showLoading(false)
                });
            }}
            title={configPushData?.title}
            data={configPushData?.config}
            open={configPushData?.open}
            message={configPushData?.message} />
    </>
}

function ConfigCreation(props) {

    const [feature, setFeature] = React.useState("");
    const [config, setConfig] = React.useState("");
    const [value, setValue] = React.useState("");
    const [type, setType] = React.useState(types[0]);
    const [error, setError] = React.useState("");

    const isUser = (type === types[1]);
    React.useEffect(() => {
        if (!props.open) {
            setError("")
            setFeature("")
            return;
        }

        const config = props?.editData?.config;
        if (config) {
            setFeature(config.featureIndex)
            setConfig(config.configIndex)
            setValue(config.value)
            setType(types[config.type])
            //setError("")
            //setFeature(config.feature)
        }

        return () => {
            setError("")
            setFeature("")
            if (props.ondispose) {
                props.ondispose()
            }
        }


    }, [props.open]);
    return <dialog open={props.open}
        style={{
            top: "50%",
            bottom: "50%",
            marginRight: "auto",
            marginLeft: "auto",
            padding: "8px", borderWidth: "1px", borderRadius: "5px", minWidth: "400px"
        }}>
        <div>
            <div style={{ textAlign: "right" }}>
                Config creation
            </div>
            <hr />

            <div style={{ display: "flex", margin: "5px 0px", textAlign: "left" }}>
                <label style={{ display: "block", width: "140px" }} htmlFor="config_creation_type">Feature</label>
                <Combobox
                    readOnly={props?.editData?.config }
                    style={{ display: "block", width: "100%" }}
                    id="config_creation_type"
                    defaultValue="user"
                    data={types}
                    onChange={(item) => {
                        setType(item)
                    }}
                    value={type} />
            </div>
            {
                isUser &&
                <div style={{ display: "flex", margin: "5px 0px", textAlign: "left" }}>
                    <label style={{ display: "block", width: "140px" }} htmlFor="config_creation_feature">Feature</label>
                    <input style={{ display: "block", width: "100%" }} type="number" id="config_creation_feature" placeholder="feature index" value={feature}
                            autoComplete="off"
                            readOnly={props?.editData?.config}
                        onChange={(e) => {
                            setFeature(e.target.value)
                        }} />
                </div>
            }

            <div style={{ display: "flex", margin: "5px 0px", textAlign: "left" }}>
                <label style={{ display: "block", width: "140px" }} htmlFor="config_creation_config">Config</label>
                <input style={{ display: "block", width: "100%" }} type="number" id="config_creation_config" placeholder="config index" value={config}
                    autoComplete="off"
                    readOnly={props?.editData?.config}
                    onChange={(e) => {
                        setConfig(e.target.value)
                    }} />
            </div>
            <div style={{ display: "flex", margin: "5px 0px", textAlign: "left" }}>
                <label style={{ display: "block", width: "140px" }} htmlFor="config_creation_value">Value</label>
                <textarea style={{ display: "block", width: "100%", resize: "none" }} maxLength={ 1000} type="t" id="config_creation_value" placeholder="config index" value={value}
                    autoComplete="off"
                    onChange={(e) => {
                        setValue(e.target.value)
                    }} />
            </div>

            {checkIfEmptyOrWhiteSpace(error) ? null : (<div style={{ display: "flex", color: "red", fontWeight: "bold", fontSize: "8" }}>
                {error}
            </div>)}
            <div style={{ textAlign: "left", display: "flex", flexDirection: "row", margin: "10px 0px 0px 0px" }}>
                <button style={{
                    width: "100px", backgroundColor: "whitesmoke", color: "black", borderRadius: "2px", height: "100%",
                    borderWidth: "2px", margin: "5px 0px 1px 0px", borderColor: 'lightgray', cursor: "pointer"
                }} onClick={() => {


                    const validConfig = parseInt(config);
                    let validFeature = parseInt(feature);

                    if (type === types[0]) {
                        validFeature = 0;
                    }

                    const edit = props?.editData?.config;
                    const data = {
                        type: type,
                        feature: validFeature,
                        config: validConfig,
                        value: value,
                        unitId: props?.relatedUnit?.id
                    };

                    if (edit) {
                        data.configId = edit.id;
                        showLoading(true)
                        updateConfig(data, (e) => {
                            showLoading(false)
                            if (e.error) {
                                setError(e.error)
                                return;
                            }

                            if (!props?.onConfigEdited) {
                                return;
                            }

                            props?.onConfigEdited(e.data)
                        });
                    }
                    else {
                        showLoading(true)
                        createConfig(data, (e) => {
                            showLoading(false)
                            if (e.error) {
                                setError(e.error)
                                return;
                            }

                            if (!props?.onConfigCreated) {
                                return;
                            }

                            props?.onConfigCreated(e.data)
                        });
                    }
                }}>confirmer</button>
                <span style={{ flexGrow: 1, display: "flex" }}></span>
                <button style={{
                    width: "100px", backgroundColor: "whitesmoke", color: "black", height: "100%", borderRadius: "2px",
                    borderWidth: "2px", margin: "5px 0px 1px 0px", borderColor: 'lightgray', cursor: "pointer"
                }} onClick={() => {
                    if (!props?.onCancel) {
                        return;
                    }
                    props.onCancel();
                }}>Annuler</button>
            </div>
        </div>
    </dialog>
}

export default UnitConfigsTable;